import { environment } from '../../environments/environment';
import { Hotspot, IHotspot } from './hotspot';
import { MiniMapSettings } from './minimap-settings';

export interface IMiniMap {
  Hotspots: Array<IHotspot>;
  MiniMapSettings: MiniMapSettings;
}

export class MiniMap implements IMiniMap {
  MiniMapId = 0;
  TourId = 0;
  Name = '';
  FileName = '';
  Hotspots = new Array<Hotspot>();
  MiniMapSettings = new MiniMapSettings();

  constructor(dto?: IMiniMap) {
    if (dto) {
      Object.assign(this, dto);

      if (this.FileName) {
        this.FileName = `${environment.panoResourcesBaseUrl}/${this.FileName.replace(
          '/panoresources/assets',
          ''
        ).replace('/panoresources', '')}`;

        this.FileName = this.FileName.replace(/([^:])\/\//g, '$1/');
      }
      this.MiniMapSettings = new MiniMapSettings(dto.MiniMapSettings);
      this.Hotspots = dto.Hotspots?.map(hs => new Hotspot(hs)) || new Array<Hotspot>();
    }
  }
}
