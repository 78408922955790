// The casing matches krpano
// There are many more properties on their object, only adding those needed in our app for now
export class KrPanoHotspot {
  name: string;
  width: number;
  height: number;
  ath: number;
  atv: number;
  rx: number;
  ry: number;
  rz: number;
  sprite: HTMLElement;
  url: string;
  edge: string;
  distorted: boolean;
  zorder: number;

  actiontype: string;
  videourl: string;
  time: number;
  ispaused: boolean;
  play: any;
  pause: any;
  volume: any;

  parent: string;
  align: string;
  onclick: string;
  bgcapture: boolean;
  scale: string;
  pixelheight: number;
  renderer: string;
}

export class KrPanoViewPosition {
  Horizontal: number;
  Vertical: number;
  FOV: number;

  constructor(horizontal: number = 0, vertical: number = 0, fov: number = 1) {
    this.Horizontal = horizontal;
    this.Vertical = vertical;
    this.FOV = fov;
  }
}
