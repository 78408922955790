import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BrowserService } from '../../../entities/browser.service';
import { Category } from '../../../entities/category';
import { Option } from '../../../entities/option';
import { Pano } from '../../../entities/pano';
import { PanoService } from '../../../entities/pano.service';
import { Tour } from '../../../entities/tour';
import { View } from '../../../entities/view';
import { SettingsService } from '../../../services/settings.service';
import { GoogleTagManagerService } from '../../../utility/helpers/google-tag-manager';
import { AnimationState, heightExpandCollapseAnimation } from '../../animations';
import { ResetConfirmDialogComponent } from '../../reset-confirm-dialog/reset-confirm-dialog.component';
import { MenuAccordionItemVM, MenuAccordionVM } from './menu-accordion/menu-accordion-vm';

import { SettingsCategoryTitle, sortBySortOrder } from '@ml/common';

@Component({
  selector: 'basic-menu',
  templateUrl: './basic-menu.component.html',
  styleUrls: ['./basic-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(500)]
})
export class BasicMenuComponent implements OnInit, OnChanges {
  @Input() pano: Pano;
  @Input() tour: Tour;
  @Input() view: View;
  @Input() isControlsMenuOpen = true;
  @Output() toggleOpen = new EventEmitter<boolean>();
  @Output() viewSelect = new EventEmitter<number>();
  disableResetButton = false;
  isHamburgerMenuOpen = false;
  isCompactScreen = false;
  hasGyroscope = false;
  showOptionsCounter = true;
  hideHamburgerMenu = false;
  viewGroupAccordions: MenuAccordionVM[] = [];
  showCarrotMessage = false;
  hasMoreThanOneOption = true;

  constructor(
    private panoService: PanoService,
    private matDialog: MatDialog,
    private browserService: BrowserService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.browserService.compactScreen$.subscribe(this.handleCompactScreenChange);

    this.showOptionsCounter = this.settingsService.get(
      'ShowOptionsCounter',
      SettingsCategoryTitle.PanoViewerTheme
    );

    this.hideHamburgerMenu =
      this.route.snapshot.queryParamMap.get('embedded')?.toLowerCase() === 'true';

    for (const category of this.view.Categories) {
      if (category instanceof Category) {
        this.setAnimationState(category);
      }
    }

    this.showCarrotMessage = !this.isControlsMenuOpen;
    this.hasMoreThanOneOption = this.viewHasMoreThanOneOption(this.view);
    if (!this.hasMoreThanOneOption) {
      this.toggleOpen.emit(false);
    }
  }

  ngOnChanges() {
    this.viewGroupAccordions = this.view.ViewGroupsAsSource.map(vg =>
      MenuAccordionVM.CreateFromViewGroup(vg, this.panoService.getAllViews())
    ).sort(sortBySortOrder);

    this.view.Categories = this.view.Categories.map(cat => {
      cat.Options = cat.Options.map(x => Object.assign(new Option(), x));
      return cat;
    });

    this.hasMoreThanOneOption = this.viewHasMoreThanOneOption(this.view);
    if (!this.hasMoreThanOneOption) {
      this.toggleOpen.emit(false);
    }
  }

  viewHasMoreThanOneOption(view: View): boolean {
    return view.Categories.some(c => c.Options.length > 1);
  }

  handleCompactScreenChange = (isCompact: boolean) => {
    // if transitioning down to to compact then hide side menu
    if (isCompact !== this.isCompactScreen && isCompact) this.isControlsMenuOpen = false;
    this.isCompactScreen = isCompact;
    this.cdr.markForCheck();
  };

  toggleHamburgerMenu(isOpen: boolean) {
    this.isHamburgerMenuOpen = isOpen;

    if (this.isCompactScreen && this.isHamburgerMenuOpen) {
      this.toggleControlMenu(false);
    }
  }

  toggleControlMenu(forceValue?: boolean) {
    this.isControlsMenuOpen = forceValue !== undefined ? forceValue : !this.isControlsMenuOpen;

    if (this.isCompactScreen && this.isControlsMenuOpen) {
      this.isHamburgerMenuOpen = false;
    }

    this.showCarrotMessage = !this.isControlsMenuOpen;
    this.toggleOpen.emit(this.isControlsMenuOpen);
  }

  closeCarrotMessage() {
    this.showCarrotMessage = false;
  }

  toggleCategoryMenu(category: Category | MenuAccordionVM) {
    category.IsOpen = !category.IsOpen;
    if (category instanceof Category) {
      this.setAnimationState(category);
    }
  }

  private setAnimationState(category: Category) {
    if (category.IsOpen) {
      category.AnimationState = AnimationState.ExpandedHeight;
    } else {
      category.AnimationState = AnimationState.CollapsedHeight;
    }
  }

  selectOption(option: Option, category: Category) {
    if (option.IsSelected) {
      return;
    }

    if (category.Options) {
      category.Options = category.Options.map(
        opt => new Option(opt, opt.OptionId === option.OptionId)
      );

      this.gtmService.addEvent('OptionOn', 'Option', option.OptionId.toString(), this.view.ViewId);
      this.panoService.updateSceneByView(this.view);
      this.disableResetButton = this.isDefault();
    }
  }

  private isDefault(): boolean {
    return this.view.isInDefaultState();
  }

  onResetClicked() {
    const dialogRef = this.matDialog.open(ResetConfirmDialogComponent);

    const obs = dialogRef.afterClosed().subscribe(resetConfirmed => {
      if (resetConfirmed) {
        this.resetToDefault();
      }
      obs.unsubscribe();
    });
  }

  resetToDefault() {
    this.view.resetToDefault();
    for (const category of this.view.Categories) {
      category.Options = category.Options.map(opt => new Option(opt, opt.IsSelected));
    }

    this.panoService.updateSceneByView(this.view);

    this.disableResetButton = true;
    this.cdr.markForCheck();
  }

  handleViewSelect(item: MenuAccordionItemVM) {
    this.viewSelect.emit(item.Id);
  }
}
