import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { Pano } from '../entities/pano';
import { PanoService } from '../entities/pano.service';
import { ErrorReasons } from '../error/error-reasons.enum';
import { SettingsService } from './settings.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class PanoResolver implements Resolve<Pano> {
  constructor(
    private panoService: PanoService,
    private settings: SettingsService,
    private theme: ThemeService,
    private router: Router
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Pano> {
    const panoRouteParam = route.paramMap.get('panoParam');
    const dynamicPanoId = +route.paramMap.get('dynamicPanoId');
    const tourId = +route.paramMap.get('tourId') || 0;
    const isDynamicPano = !!dynamicPanoId;

    let panoParam: number | string | string[];
    panoParam = panoRouteParam && isNaN(+panoRouteParam) ? panoRouteParam : +panoRouteParam;

    if (!panoParam && !dynamicPanoId && route.url.length) {
      panoParam = route.url.map(x => x.path);
    }

    if (isDynamicPano) {
      const panoAsync = this.panoService.getPanoData(dynamicPanoId, isDynamicPano, tourId);
      // const settingsAsync = this.settings.loadSettingsByDynamicPanoId(dynamicPanoId);
      // const [pano] = await Promise.all([panoAsync, settingsAsync]);
      // this.theme.initializeFromSettings();

      // Making the assumption that Punchmark will never use Theme settings. This call will help save
      // about 1s in load performance.  8/2/2022. Load times are 6s in Italy.

      const pano = await panoAsync;
      return pano;
    } else {
      const pano = await this.panoService.getPanoData(
        panoParam || dynamicPanoId,
        isDynamicPano,
        tourId
      );

      const enabled = await this.settings.isEnabledByPanoId(pano.ClientId);
      if (!enabled) {
        this.router.navigate([`/error`], {
          queryParams: { reason: ErrorReasons.ProductNotEnabled }
        });
        return;
      }

      await this.settings.loadSettingsByPanoId(pano.PanoId);
      await this.theme.initializeFromSettings();

      return pano;
    }
  }
}
