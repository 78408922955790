import { environment } from '../../../environments/environment';
import { ActionType, Hotspot } from '../../entities/hotspot';
import { Media } from '../../entities/media';

import { PanoHotspotModalPosition, PanoHotspotModalSize } from '@ml/common';

export class ModalMediaItem {
  FullUrl: string;
  IsImage: boolean;
  IsVideo: boolean;

  constructor(fullUrl?: string, media?: Media) {
    this.FullUrl = fullUrl ? fullUrl.replace(/([^:])\/\//g, '$1/') : '';
    this.IsImage = media?.IsImage;
    this.IsVideo = media?.IsVideo;
  }
}

export class PopupModalContent {
  IsHalfWidth = false;
  DisplayOnRightSide = false;
  IframeUrl: string;
  Media: ModalMediaItem[] = [];
  Orientations = new Array<string>();
  Titles = new Array<string>();
  Content = new Array<string>();
  Name: string;
  IsVRModal = false;

  Position = '';
  Size = '';
  VWSize = '';
  Height = '';
  ImageWidthSize = '';

  constructor() {}

  static CreateFromHotspot(
    hotspot: Hotspot,
    panoPath?: string,
    isVRModalAndEnabled = false
  ): PopupModalContent {
    const pm = new PopupModalContent();
    pm.Size = pm.getSize(hotspot);
    pm.Name = hotspot.ModalSettings.HideName ? '' : hotspot.Name;
    pm.Position = pm.getPosition(hotspot);
    pm.VWSize = pm.getVWSize(hotspot);
    pm.Height = pm.GetImageHeightSize(hotspot);
    if (!pm.Height) {
      pm.Height = pm.GetMinHeightSize(hotspot);
    }

    pm.ImageWidthSize = pm.GetImageWidthSize(hotspot);

    switch (hotspot.ActionType) {
      case ActionType.Url:
        pm.IframeUrl = hotspot.Url;
        break;
      case ActionType.MEDIA:
        pm.Media = hotspot.Media.map(x => this.getModalMediaItem(x, panoPath));
        pm.Orientations = hotspot.Media.map(x =>
          x.Orientation ? x.Orientation.toLowerCase() : 'portrait'
        );
        pm.Content = hotspot.Media.map(x => x.Content);
        pm.Titles = hotspot.Media.map(x => x.Title);
        pm.IsVRModal = isVRModalAndEnabled;
        break;
    }

    return pm;
  }

  private static getModalMediaItem(media: Media, panoPath: string): ModalMediaItem {
    if (media.Filename.indexOf('http') > -1) {
      return new ModalMediaItem(media.Filename, media);
    } else if (media.Filename.indexOf('panoresources') > -1) {
      return new ModalMediaItem(
        `${environment.panoResourcesBaseUrl}${media.Filename.replace('/panoresources/assets', '')}`,
        media
      );
    } else {
      return new ModalMediaItem(`${panoPath}/hotspotmedia/${media.Filename}`, media);
    }
  }

  getPosition(hotspot: Hotspot): string {
    if (hotspot.ModalSize === PanoHotspotModalSize.HalfWidth) {
      switch (hotspot.ModalPosition) {
        case PanoHotspotModalPosition.Left:
          return 'left-side';
        case PanoHotspotModalPosition.Right:
          return 'right-side';
        case PanoHotspotModalPosition.Center:
          return 'center';
      }
    } else {
      return '';
    }
  }

  getSize(hotspot: Hotspot): string {
    switch (hotspot.ModalSize) {
      case PanoHotspotModalSize.FullWidth:
        return 'full-width';
      case PanoHotspotModalSize.HalfWidth:
        return 'half-width';
      case PanoHotspotModalSize.FitWidth:
        return 'fit';
    }
  }

  getVWSize(hotspot: Hotspot): string {
    const modalSize = parseInt(hotspot.ModalSettings?.ModalSizeRatio, 10);
    const width = isNaN(modalSize) ? 0 : Math.floor(modalSize);

    if (
      hotspot.ModalSize === PanoHotspotModalSize.FitWidth &&
      (hotspot.ModalSettings.Width > 0 || hotspot.ModalSettings.Height > 0)
    ) {
      return `width-${hotspot.ModalSettings.Width}`;
    }

    return hotspot.ModalSize === PanoHotspotModalSize.FitWidth ? `width-vw-${width}` : '';
  }

  GetMinHeightSize(hotspot: Hotspot): string {
    const modalSize = parseInt(hotspot.ModalSettings?.ModalSizeRatio, 10);
    let minHeight = isNaN(modalSize) ? 0 : Math.floor(modalSize * 0.75);

    if (minHeight === 0 && hotspot.ModalSize === PanoHotspotModalSize.FullWidth) {
      minHeight = 75;
    }

    if (hotspot.ModalSize === PanoHotspotModalSize.FitWidth && hotspot.ModalSettings.Width > 0) {
      return ``;
    }

    return hotspot.ModalSize === PanoHotspotModalSize.FitWidth ? `min-height-vh-${minHeight}` : '';
  }

  GetImageHeightSize(hotspot: Hotspot): string {
    if (hotspot.ModalSettings.Height > 0) {
      return `height-${hotspot.ModalSettings.Height}`;
    }

    return '';
  }

  GetImageWidthSize(hotspot: Hotspot): string {
    const modalSize = parseInt(hotspot.ModalSettings?.ModalSizeRatio, 10);
    const width = isNaN(modalSize) ? 0 : Math.floor(modalSize);

    return hotspot.ModalSize === PanoHotspotModalSize.FitWidth ? `width-vw-${width}` : '';
  }
}
