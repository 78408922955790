import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HOST_URL } from '../server/utility/host-url.token';
import { MetadataService } from '../server/utility/metadata.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalEventService } from './entities/global-event.service';
import { HotspotService } from './entities/hotspot.service';
import { PanoService } from './entities/pano.service';
import { PanoRootComponent } from './pano-root/pano-root.component';
import { AutofocusDirective } from './shared/autofocus.directive';
import { BackgroundUrlPipe } from './shared/background-url.pipe';
import { AllViewsMenuComponent } from './shared/category-menu-container/allviews-menu/allviews-menu.component';
import { BasicMenuComponent } from './shared/category-menu-container/basic-menu/basic-menu.component';
import { HamburgerMenuComponent } from './shared/category-menu-container/basic-menu/hamburger-menu/hamburger-menu.component';
import { MenuAccordionComponent } from './shared/category-menu-container/basic-menu/menu-accordion/menu-accordion.component';
import { OptionGridItemComponent } from './shared/category-menu-container/basic-menu/option-grid-item/option-grid-item.component';
import { OptionListItemComponent } from './shared/category-menu-container/basic-menu/option-list-item/option-list-item.component';
import { CategoryMenuContainerComponent } from './shared/category-menu-container/category-menu-container.component';
import { ThumbnailMenuComponent } from './shared/category-menu-container/thumbnail-menu/thumbnail-menu.component';
// eslint-disable-next-line max-len
import { ThumbnailOptionItemComponent } from './shared/category-menu-container/thumbnail-menu/thumbnail-option-item/thumbnail-option-item.component';
import { FavoritesComponent } from './shared/favorites/favorites.component';
import { FullscreenComponent } from './shared/fullscreen/fullscreen.component';
import { GallerySwiperDirective } from './shared/gallery-swipe.directive';
import { IconButtonComponent } from './shared/icon-button/icon-button.component';
import { IconComponent } from './shared/icon/icon.component';
import { MinimapHotspotComponent } from './shared/minimap/minimap-hotspot/minimap-hotspot.component';
import { MinimapComponent } from './shared/minimap/minimap.component';
import { MouseEventsDirective } from './shared/mouse-events.directive';
import { PanoLoadingOverlayComponent } from './shared/pano-loading-overlay/pano-loading-overlay.component';
import { SortByOrderPipe } from './shared/pipes/sort-by-order.pipe';
import { PopupModalComponent } from './shared/popup-modal/popup-modal.component';
import { ResetConfirmDialogComponent } from './shared/reset-confirm-dialog/reset-confirm-dialog.component';
import { ShareDialogComponent } from './shared/share-dialog/share-dialog.component';
import { SpinnerDirective } from './shared/spinner.directive';
import { ToastComponent } from './shared/toast/toast.component';
import { SlideOutComponent } from './slide-out/slide-out.component';
import { AuthInterceptor } from './utility/helpers/auth.interceptor';
import {
  SafeBackgroundImageUrlPipe,
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe
} from './utility/helpers/sanitizers.pipe';
import { HotspotDebugMenuComponent } from './utility/hotspot-debug-menu/hotspot-debug-menu.component';
import { ToolBarComponent } from './shared/tool-bar/tool-bar.component';
import { AlertBannerComponent } from './shared/alert-banner/alert-banner.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    IconComponent,
    IconButtonComponent,
    SlideOutComponent,
    ResetConfirmDialogComponent,
    MinimapComponent,
    BackgroundUrlPipe,
    CategoryMenuContainerComponent,
    BasicMenuComponent,
    ToastComponent,
    AutofocusDirective,
    FavoritesComponent,
    AllViewsMenuComponent,
    OptionListItemComponent,
    OptionGridItemComponent,
    SafeBackgroundImageUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    HamburgerMenuComponent,
    SortByOrderPipe,
    ShareDialogComponent,
    MouseEventsDirective,
    SpinnerDirective,
    PopupModalComponent,
    PanoRootComponent,
    GallerySwiperDirective,
    HotspotDebugMenuComponent,
    MenuAccordionComponent,
    MinimapHotspotComponent,
    PanoLoadingOverlayComponent,
    ThumbnailMenuComponent,
    ThumbnailOptionItemComponent,
    FullscreenComponent,
    ToolBarComponent,
    AlertBannerComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatSliderModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTooltipModule
  ],
  providers: [
    PanoService,
    GlobalEventService,
    HotspotService,
    MetadataService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, backdropClass: 'blocking-overlay-backdrop' }
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HOST_URL, useValue: window.location.origin }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
