import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PanoRootComponent } from './pano-root/pano-root.component';
import { PanoResolver } from './services/pano.resolver';

const routes: Routes = [
  {
    path: 'pano/:panoParam',
    resolve: { pano: PanoResolver },
    children: [
      { path: '', component: PanoRootComponent },
      { path: 'tour/:tourId', component: PanoRootComponent },
      { path: 'tour/:tourId/view/:viewId', component: PanoRootComponent }
    ]
  },
  {
    path: 'dynamicpano/:dynamicPanoId/:tourId',
    resolve: { pano: PanoResolver },
    children: [{ path: '', component: PanoRootComponent }]
  },
  {
    path: 'dynamicpano/:dynamicPanoId',
    resolve: { pano: PanoResolver },
    children: [{ path: '', component: PanoRootComponent }]
  },
  {
    path: '**',
    component: PanoRootComponent,
    resolve: { pano: PanoResolver }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: PreloadAllModules }
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
