import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { MenuAccordionItemVM, MenuAccordionVM } from './menu-accordion-vm';

// TODO: refactor all accordions from basic-menu to use this component

@Component({
  selector: 'menu-accordion',
  templateUrl: './menu-accordion.component.html',
  styleUrls: ['./menu-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuAccordionComponent implements OnInit {
  @Input() model: MenuAccordionVM;
  @Output() toggle = new EventEmitter();
  @Output() itemSelect = new EventEmitter<MenuAccordionItemVM>();

  constructor() {}

  ngOnInit(): void {}

  toggleOpen() {
    this.model.IsOpen = !this.model.IsOpen;
  }
}
