import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    count = 0;
    checkGScope = this.checkGyroscope.bind(this);

    public hasGyroscope = new BehaviorSubject<boolean>(false);

    constructor() {
        window.addEventListener('devicemotion', this.checkGScope, true);
    }

    checkGyroscope(event: DeviceMotionEvent) {
        if (Math.abs(event.acceleration?.x) > 0 && event.rotationRate.alpha) {
            this.hasGyroscope.next(true);
            window.removeEventListener('devicemotion', this.checkGScope, true);
            return;
        }

        if (++this.count > 10000) {
            // Allow the user some time to actually touch something.
            window.removeEventListener('devicemotion', this.checkGScope, true);
        }
    }
}
