import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[mlAutofocus]'
})
export class AutofocusDirective implements OnInit {
  private autoFocus;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    // For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
    if (this.autoFocus || typeof this.autoFocus === 'undefined') this.el.nativeElement.focus();
  }

  @Input() set mlAutofocus(condition: boolean) {
    this.autoFocus = condition !== false;
  }
}
