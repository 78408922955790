export class PanoBlend {
  PanoBlendId: number;
  PanoId: number;
  HotspotId: number;
  Blend: string;
  Time: number;
  TweenType: string;
  Color: string;
  ColorScale: string;
  Angle: number;
  Smooth: number;
  Shape: number;
  Zoom: number;
  CreatedOn: Date;
  ModifiedOn: Date;
}

export class NoBlendTransition extends PanoBlend {
  toString(): string {
    return 'NOBLEND';
  }
}

export class BlendTransition extends PanoBlend {
  toString(): string {
    return `BLEND(${this.Time}, ${this.TweenType})`;
  }
}

export class ColorBlendTransition extends PanoBlend {
  toString(): string {
    return `COLORBLEND(${this.Time}, ${this.Color}, ${this.TweenType})`;
  }
}

export class LightBlendTransition extends PanoBlend {
  toString(): string {
    return `LIGHTBLEND(${this.Time}, ${this.Color}, ${this.ColorScale}, ${this.TweenType})`;
  }
}

export class SlideBlendTransition extends PanoBlend {
  toString(): string {
    return `SLIDEBLEND(${this.Time}, ${this.Angle}, ${this.Smooth}, ${this.TweenType})`;
  }
}

export class OpenBlendTransition extends PanoBlend {
  toString(): string {
    return `OPENBLEND(${this.Time}, ${this.Shape}, ${this.Smooth}, ${this.Zoom}, ${this.TweenType})`;
  }
}

export class ZoomBlendTransition extends PanoBlend {
  toString(): string {
    return `ZOOMBLEND(${this.Time}, ${this.Zoom}, ${this.TweenType})`;
  }
}

export class PunchMarkZoomBlendTransition extends PanoBlend {
  toString(): string {
    return `ZOOMBLEND(${this.Time}, ${this.Zoom}, ${this.TweenType})`;
  }
}
