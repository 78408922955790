import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenComponent implements OnInit {
  isFullScreen = false;
  element: any;

  constructor(@Inject(DOCUMENT) private document: any, private toastService: ToastService) {}

  ngOnInit(): void {
    this.element = document.documentElement;
  }

  async toggleWindowMode() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      await this.enableFullScreen();
    } else {
      this.disabledFullScreen();
    }
  }

  async enableFullScreen() {
    if (this.element.requestFullscreen) {
      await this.element.requestFullscreen();
    } else if (this.element.mozRequestFullScreen) {
      await this.element.mozRequestFullScreen();
    } else if (this.element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      await this.element.webkitRequestFullscreen();
      addEventListener(`webkitfullscreenchange`, this.fullscreenmodes);
    } else {
      this.toastService.showInfo(
        'Unsupported',
        `This device/browser does not support fullscreen mode.`,
        3000
      );
    }
  }

  disabledFullScreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else {
      this.toastService.showInfo(
        'Unsupported',
        `This device/browser does not support fullscreen mode.`,
        3000
      );
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  fullscreenmodes(event) {
    this.isFullScreen = document.fullscreenElement ? true : false;
    removeEventListener(`webkitfullscreenchange`, this.fullscreenmodes);
  }
}
