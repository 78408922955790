import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Pano } from '../../entities/pano';

@Injectable({
  providedIn: 'root'
})
export class Lookup {
  readonly ApiBaseUrl = environment.apiDomain
    ? `${window.location.protocol}//${environment.apiDomain}`
    : '';
  // need to keep this for setting-files for now... potentially move those files under PanoResources
  readonly ApiProductDataEndpoint = `${this.ApiBaseUrl}/api/fs/pd`;
  readonly PanoResourcesBaseUrl = environment.panoResourcesBaseUrl;

  readonly SkipAuthHeader = 'x-ml-skipauth';

  getBasePanoPath(pano: Pano): string {
    if (!this.hasValidClient(pano)) {
      return '';
    }

    if (environment.isOffline) {
      return 'panoresources/';
    }

    return `${this.PanoResourcesBaseUrl}/${pano.ClientName}_${pano.ClientId}/${pano.Name}_${pano.PanoId}/`;
  }

  hasValidClient(pano: Pano): boolean {
    return pano && pano.ClientName && pano.ClientId ? true : false;
  }
}
