import { AnimationState } from '../shared/animations';
import { IOption, Option } from './option';

import { sortByOrder } from '@ml/common';

export interface ICategory {
  CategoryId: number;
  Name: string;
  ViewId: number;
  Order: number;
  Settings: IPanoCategorySettings;

  Options: Array<IOption>;
}

export interface IPanoCategorySettings {
  IconId: number;
  IconSvgContent: string;
}

export class Category implements ICategory {
  CategoryId: number;
  Name: string;
  ViewId: number;
  Order: number;
  Options = new Array<Option>();
  Settings: IPanoCategorySettings;

  IsOpen = false;
  HasOptionThumbnails = false;
  AnimationState: AnimationState;

  // setting default option to zero if not provided
  constructor(iCategory?: ICategory) {
    if (iCategory) {
      Object.assign(this, iCategory);

      if (iCategory.Options) {
        this.Options = iCategory.Options.map((opt: Option) => new Option(opt)).sort(sortByOrder);
        this.HasOptionThumbnails = this.Options.some(o => !!o.PreviewFilename);
      }

      this.AnimationState =
        typeof screen !== 'undefined' && screen?.orientation?.type === 'landscape-primary'
          ? AnimationState.CollapsedWidth
          : AnimationState.CollapsedHeight;
    }
  }

  getSelectedOption(): Option {
    return this.Options.find(o => o.IsSelected);
  }
}
