import { Injectable } from '@angular/core';

import { PanoService } from '../../entities/pano.service';

export interface GTMEventData {
  eventName: string;
  eventType?: string;
  eventValue?: string;
  extraData?: Record<string, any>;
}

export const PushToGoogleTagManager = ({
  eventName,
  eventType,
  eventValue,
  extraData
}: GTMEventData) => {
  const dataLayer = (window as any).dataLayer;
  if (!dataLayer) return;

  dataLayer.push({
    event: 'panoviewer.' + eventName,
    InteractionType: eventType || eventName,
    InteractionValue: eventValue,
    CurrentLocation: window.location.href,
    ...extraData
  });
};

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private clientName: string;
  private clientId: number;
  private panoName: string;
  private panoId: number;

  private apiStart: string;
  private apiEnd: string;

  constructor(private panoService: PanoService) {}

  initialize(clientName: string, clientId: number, panoName: string, panoId: number) {
    this.clientName = clientName;
    this.clientId = clientId;
    this.panoName = panoName;
    this.panoId = panoId;

    window.dataLayer = [];
    window.dataLayer.push({
      ClientName: this.clientName,
      ClientId: this.clientId,
      PanoName: this.panoName,
      PanoId: this.panoId,
      CurrentLocation: window.location.href
    });

    this.loadGTM();
  }

  addEvent(
    eventName: string,
    eventType: string,
    eventValue: string,
    viewId: number,
    extra: any = null
  ) {
    const pano = this.panoService.pano;
    const view = pano.getViewById(viewId);
    const categoryIds = view.Categories.map(cat => cat.CategoryId);
    const optionIds = view.Categories.flatMap(cat => cat.Options)
      .filter(opt => opt.IsSelected)
      .map(opt => opt.OptionId);

    const extraData = {
      ClientName: this.clientName,
      ClientId: this.clientId,
      PanoName: this.panoName,
      PanoId: this.panoId,
      ViewId: viewId,
      ViewName: view.Name,
      Categories: categoryIds,
      Options: optionIds,
      OptionCombinationId: view.findOptionCombinationByToggledOnOptions()?.OptionCombinationId,
      ...extra
    };

    PushToGoogleTagManager({ eventName, eventType, eventValue, extraData });
  }

  sendPageRequestBeforeLoadTimeStamp(viewId: number) {
    this.addEvent(
      'PageRequestBeforeLoad',
      'Load',
      window.initialPageRequestBeforeLoadTimeStamp,
      viewId
    );
  }

  sendApiStartRequestTimestamp(viewId: number) {
    this.startInitialPanoApiRequestTime();
    this.addEvent('ApiStartRequest', 'Load', this.apiStart, viewId);
  }

  sendApiEndRequestTimestamp(viewId: number) {
    this.endInitialPanoApiRequestTime();
    this.addEvent('ApiEndRequest', 'Load', this.apiEnd, viewId);
  }

  sendPageLoad(viewId: number) {
    const date = new Date();
    this.addEvent('PanoLoad', 'Load', this.getDate(date), viewId);
  }

  startInitialPanoApiRequestTime() {
    if (!this.apiStart) {
      this.apiStart = this.getDate(this.panoService.startInitialPanoApiRequestTime);
    }
  }

  endInitialPanoApiRequestTime() {
    if (!this.apiEnd) {
      this.apiEnd = this.getDate(this.panoService.endInitialPanoApiRequestTime);
    }
  }

  private getDate(date: Date): string {
    return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}.${date.getUTCMilliseconds()}`;
  }

  private loadGTM() {
    window.dataLayer.push({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = document.getElementsByTagName('script')[0];
    const j = document.createElement('script');
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5343XC7';
    f.parentNode.insertBefore(j, f);
  }
}
