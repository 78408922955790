<div class="minimap" *ngIf="miniMap && mapFilePath">
  <div *ngIf="showVideoTour" class="minimap-menu-button" (click)="viewVideoTour.emit()">
    <div class="menu-button-background"></div>
    <div class="menu-button">
      <icon class="active" name="interior_video_tour"></icon>
    </div>
  </div>
  <div class="minimap-menu-button" (click)="toggleMiniMap()" *ngIf="miniMap.MiniMapSettings.ShowToggleButton">
    <div *ngIf="!miniMapOpen">
      <div class="menu-button-background"></div>
      <div class="menu-button">
        <icon class="active" name="community_walkthrough_map" *ngIf="tour.IsBaseCommunity"></icon>
        <icon class="active" name="interior_walkthrough_map" *ngIf="!tour.IsBaseCommunity"></icon>
      </div>
    </div>
    <div class="close-btn flex align-center justify-center" *ngIf="miniMapOpen && miniMap.MiniMapSettings.ShowToggleButton">
      <div class="menu-button-background"></div>
      <div class="menu-button">
        <icon class="close" name="close" [class.active]="miniMapOpen"></icon>
      </div>
    </div>
  </div>
  <div class="mini-map-content flex align-center justify-center" [class.shown]="miniMapOpen && mapHasLoaded">
    <div class="minimap-container">
      <img #miniMapImage class="minimap-image" [src]="mapFilePath" (click)="handleMiniMapClick($event)" (load)="handleMiniMapLoad()" />
      <minimap-hotspot *ngFor="let hotspot of miniMap.Hotspots" [hotspot]="hotspot" [currentViewId]="currentView.ViewId"
                       (spotSelect)="onHotspotSelect($event)"></minimap-hotspot>
    </div>
  </div>
</div>