import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private stateSubject: Subject<ToastState> = new Subject();
  private loadingAnimationUrlSubject: Subject<string> = new Subject();

  constructor() {}

  get state$(): Observable<ToastState> {
    return this.stateSubject.asObservable();
  }

  setState(isShowing: boolean, options: ToastOptions = {}) {
    this.stateSubject.next(new ToastState(isShowing, options));
  }

  get loadingAnimationUrlState$(): Observable<string> {
    return this.loadingAnimationUrlSubject.asObservable();
  }

  setLoadingAnimationUrlState(url: string) {
    this.loadingAnimationUrlSubject.next(url);
  }

  showLoading() {
    this.setState(true, { onlyLoadingIndicator: true });
  }

  /** Will only hide toast if currently displaying the loading indicator */
  hideLoading() {
    this.setState(false, { onlyLoadingIndicator: true });
  }

  /** Will hide toast no matter what the current display is */
  hide() {
    this.setState(false);
  }

  showError(title: string, message?: string, duration?: number) {
    this.setState(true, {
      useErrorStyle: true,
      title,
      message,
      duration
    });
  }

  showStickyError(title: string, message?: string) {
    this.setState(true, {
      useErrorStyle: true,
      title,
      message,
      clickToClose: false,
      showCloseButton: true,
      infinite: true
    });
  }

  showSuccess(title: string, message?: string, duration?: number) {
    this.setState(true, {
      useSuccessStyle: true,
      title,
      message,
      duration
    });
  }

  showInfo(title: string, message?: string, duration?: number) {
    this.setState(true, {
      title,
      message,
      duration
    });
  }

  showInfoLowAlign(title: string, message?: string, duration?: number) {
    this.setState(true, {
      title,
      message,
      duration,
      useLowAlignStyle: true
    });
  }

  showMessage(message: string) {
    this.setState(true, {
      message,
      clickToClose: true,
      showCloseButton: true,
      infinite: true
    });
  }

  showConfirm(
    title: string,
    message?: string,
    options?: {
      confirmNegativeButtonText?: string;
      confirmPositiveButtonText?: string;
    }
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.setState(true, {
        title,
        message,
        iconName: 'question',
        clickToClose: false,
        showCloseButton: true,
        infinite: true,
        isConfirmation: true,
        resolveFn: resolve,
        confirmNegativeButtonText: options ? options.confirmNegativeButtonText : '',
        confirmPositiveButtonText: options ? options.confirmPositiveButtonText : ''
      });
    });
  }

  /** Toast default duration is 3 seconds */
  get defaultDuration() {
    return 3000;
  }
}

export class ToastState {
  constructor(public isShowing: boolean = false, public options: ToastOptions = {}) {}
}

export interface ToastOptions {
  /** This will display only the loading spinner. The iconPath is not required and by default will display infinite */
  onlyLoadingIndicator?: boolean;

  /** This will keep the toast visible until setState is called again */
  infinite?: boolean;

  /** Amount of time the toast will be visible in milliseconds. Default is ToastService.defaultDuration (3 seconds) */
  duration?: number;

  /** Allows the user to click the modal to close it instead of waiting on duration */
  clickToClose?: boolean;

  showCloseButton?: boolean;

  useSuccessStyle?: boolean;
  useErrorStyle?: boolean;
  useLowAlignStyle?: boolean;

  title?: string;
  message?: string;
  iconName?: string;

  isConfirmation?: boolean;
  resolveFn?: (value?: boolean | PromiseLike<boolean>) => void;
  confirmNegativeButtonText?: string;
  confirmPositiveButtonText?: string;
}
