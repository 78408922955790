import {
  BlendTransition,
  ColorBlendTransition,
  LightBlendTransition,
  NoBlendTransition,
  OpenBlendTransition,
  PanoBlend,
  PunchMarkZoomBlendTransition,
  SlideBlendTransition,
  ZoomBlendTransition
} from './pano-blend';

export class PanoBlendFactory {
  create(blend: PanoBlend): PanoBlend {
    if (!blend || !blend.Blend) return null;

    switch (blend.Blend.toLowerCase()) {
      case 'noblend':
        return Object.assign(new NoBlendTransition(), blend);
      case 'blend':
        return Object.assign(new BlendTransition(), blend);
      case 'colorblend':
        return Object.assign(new ColorBlendTransition(), blend);
      case 'lightblend':
        return Object.assign(new LightBlendTransition(), blend);
      case 'slideblend':
        return Object.assign(new SlideBlendTransition(), blend);
      case 'openblend':
        return Object.assign(new OpenBlendTransition(), blend);
      case 'zoomblend':
        return Object.assign(new ZoomBlendTransition(), blend);
      case 'punchmarkzoomblend':
        return Object.assign(new PunchMarkZoomBlendTransition(), blend);
      default:
        return null;
    }
  }
}
