<section class="page-container">
    <h1>Error</h1>
    <div class="message" [ngSwitch]="errorReason">
        <h3 *ngSwitchCase="errorReasons.PanoNotFound">Pano not found</h3>
        <h3 *ngSwitchCase="errorReasons.ProductNotEnabled">PanoViewer is not enabled. Please contact your MediaLab
            Representative to gain access.</h3>
        <h3 *ngSwitchCase="errorReasons.RouteNotFound">
            <span *ngIf="attemptedRoute">Sorry, we have no route configured for the following: {{attemptedRoute}}</span>
            <span *ngIf="!attemptedRoute">The URL you tried does not match any of our routes. Please double check it and try again.</span>
        </h3>
        <h3 *ngSwitchDefault>Unexpected error occurred</h3>
    </div>
</section>