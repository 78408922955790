import { Injectable } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';

import { IconLibrary } from '../shared/icon/icon-library';
import { ToastService } from '../shared/toast/toast.service';
import { SettingsService } from './settings.service';

import { ISetting, SettingFieldType, SettingsCategoryTitle } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private customFonts = ['Helvetica Light'];

  constructor(private settingsService: SettingsService, private toaster: ToastService) {}

  initializeFromSettings() {
    this.setAndLoadFonts();
    this.swapInIcons();
    this.applySettingsToCssVariables();
    this.applyLoadingAnimationUrlSetting();
  }

  setAndLoadFonts() {
    const relevantSettings = this.settingsService.getAllForFonts();
    const root = document.documentElement;
    relevantSettings.forEach(setting => {
      const familyName = setting.Value;
      root.style.setProperty(this.getSafeVariableName(setting.UIName), `'${familyName}'`);
      this.addLinkStylesheetToHead(this.getCssLink(familyName, setting));
    });
  }

  private getCssLink(familyName: string, setting: ISetting) {
    if (this.customFonts.includes(familyName)) {
      const linkSafeName = familyName.replace(/ /g, '');
      return `/assets/fonts/${linkSafeName}.css`;
    } else {
      const linkSafeName = familyName.replace(/ /g, '+');
      const weight = ':wght@100;400;700';
      return `https://fonts.googleapis.com/css2?family=${linkSafeName}${weight}&display=swap`;
    }
  }

  private addLinkStylesheetToHead(href: string) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
  }

  swapInIcons() {
    const relevantSettings = this.settingsService.getAllForIcons();
    relevantSettings
      .filter(x => !!x.UIValue)
      .forEach(setting => {
        IconLibrary[setting.UIName] = setting.UIValue;
      });
  }

  applySettingsToCssVariables() {
    const relevantSettings = this.settingsService.getAllForCssVariables();
    const root = document.documentElement;
    relevantSettings.forEach(setting => {
      root.style.setProperty(this.getSafeVariableName(setting.UIName), setting.Value);

      if (setting.FieldType === SettingFieldType.ColorPicker) {
        const color = new TinyColor(setting.Value);
        ColorTransparencyModifiers.forEach(modifier => {
          root.style.setProperty(
            this.getSafeVariableName(setting.UIName + modifier.Suffix),
            color.setAlpha(modifier.Opacity).toRgbString()
          );
        });
        ColorLuminanceModifiers.forEach(modifier => {
          root.style.setProperty(
            this.getSafeVariableName(setting.UIName + modifier.Suffix),
            color.lighten(modifier.PercentLighter).setAlpha(1).toRgbString()
          );
        });
      }
    });
  }

  private getSafeVariableName(name: string): string {
    let varName = name.toLowerCase();
    varName = varName.startsWith('--') ? varName : `--${varName}`;
    return varName;
  }

  updateCssVariable(variableName: string, variableValue: string) {
    const propName = this.getSafeVariableName(variableName);
    document.documentElement.style.setProperty(propName, variableValue);
  }

  applyLoadingAnimationUrlSetting() {
    const loadingAnimationImageUrl = this.settingsService.get(
      'SecondaryLoadingAnimationImage',
      SettingsCategoryTitle.PanoViewerSecondaryLoader
    );
    this.toaster.setLoadingAnimationUrlState(loadingAnimationImageUrl);
  }
}

interface IColorTransparencyModifier {
  Suffix: string;
  Opacity: number;
}

const ColorTransparencyModifiers: IColorTransparencyModifier[] = [
  {
    Suffix: '-alpha-low',
    Opacity: 0.2
  },
  {
    Suffix: '-alpha-medium',
    Opacity: 0.5
  },
  {
    Suffix: '-alpha-high',
    Opacity: 0.9
  }
];

interface IColorLuminanceModifier {
  Suffix: string;
  PercentLighter: number;
}

const ColorLuminanceModifiers: IColorLuminanceModifier[] = [
  {
    Suffix: '-lightest',
    PercentLighter: 25
  },
  {
    Suffix: '-lighter',
    PercentLighter: 10
  },
  {
    Suffix: '-darker',
    PercentLighter: -10
  }
];
