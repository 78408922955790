<div class="basic-category-menu controls-menu-container" *ngIf="pano.UseLeftMenu">
  <div class="menu-collapse-btn" (click)="toggleControlMenu()">
    <icon class="carrot_arrow" name="carrot_arrow" [class.flip]="!controlsMenuOpen"></icon>
  </div>
  <div class="controls-menu" [class.menu-closed]="!controlsMenuOpen">
    <div class="category-container">
      <div *ngFor="let viewVM of tour?.Views | sortByOrder">
        <div *ngFor="let category of viewVM?.Categories | sortByOrder">
          <div class="category-header flex row align-center justify-between" [class.open]="category.IsOpen" (click)="toggleCategoryMenu(category)">
            <div>
              <div>{{viewVM.Name}}</div>
            </div>
            <div class="expander">
              <icon name="line" [class.open]="category.IsOpen"></icon>
              <icon name="line"></icon>
            </div>
          </div>
          <div class="category-body" [class.open]="category?.IsOpen">
            <div class="category-body-option flex row align-center" *ngFor="let option of category?.Options | sortByOrder" (click)="selectOption(option, category, viewVM)"
                 [class.open]="category?.IsOpen" [class.selected]="option?.IsSelected">
              <div class="label-container">
                {{option.Name}}</div>
              <div class="icon-container">
                <icon name="success"></icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>