<hamburger-menu *ngIf="isCompactScreen && hasMoreThanOneOption" [class.hidden]="hideHamburgerMenu" [view]="view" [isOpen]="isHamburgerMenuOpen"
                (toggleOpen)="toggleHamburgerMenu($event)"></hamburger-menu>
<div class="basic-category-menu controls-menu-container" [class.menu-closed]="!isControlsMenuOpen" *ngIf="hasMoreThanOneOption">
  <hamburger-menu *ngIf="!isCompactScreen" [class.hidden]="hideHamburgerMenu" [view]="view" [isOpen]="isHamburgerMenuOpen"
                  (toggleOpen)="toggleHamburgerMenu($event)"></hamburger-menu>
  <div class="menu-collapse-btn">
    <icon class="carrot_arrow" name="carrot_arrow" [class.flip]="!isControlsMenuOpen" (click)="toggleControlMenu()"></icon>
    <div class="carrot-message" [class.show-message]="showCarrotMessage">
      <div class="close-carrot-message" (click)="closeCarrotMessage()">x</div>
      <div (click)="toggleControlMenu()">
        Touch or click here to view additional selections.
      </div>
    </div>
  </div>
  <div class="controls-menu">
    <button mat-button color="primary" class="reset-btn" [disabled]="disableResetButton" (click)="onResetClicked()">
      <icon name="reset_arrow"></icon><span>Reset</span>
    </button>
    <div class="category-container">
      <ng-container *ngIf="view.ViewSettings?.DisplayViewGroupsAboveCategories">
        <menu-accordion *ngFor="let accordion of viewGroupAccordions" [model]="accordion" (itemSelect)="handleViewSelect($event)"></menu-accordion>
      </ng-container>
      <ng-container *ngFor="let category of view?.Categories">
        <div class="category-header flex row align-center justify-between" [class.open]="category.IsOpen" (click)="toggleCategoryMenu(category)"
             *ngIf="pano.ShowSingleOptionCategory || category.Options.length > 1">
          <div>
            <div><span *ngIf="showOptionsCounter" class="options-count">{{category.Options.length}}</span>{{category.Name}}</div>
            <div class="sub">
              <icon name="success"></icon>{{category.getSelectedOption()?.Name}}
            </div>
          </div>
          <div class="expander">
            <icon name="line" [class.open]="category.IsOpen"></icon>
            <icon name="line"></icon>
          </div>
        </div>
        <div class="category-body" [@heightExpandCollapse]="category?.AnimationState">
          <div *ngIf="category.HasOptionThumbnails; else listTemplate" class="category-grid">
            <option-grid-item *ngFor="let option of category?.Options" [option]="option" (optionSelect)="selectOption(option, category)">
            </option-grid-item>
          </div>
          <ng-template #listTemplate>
            <option-list-item *ngFor="let option of category?.Options" [option]="option" (optionSelect)="selectOption(option, category)">
            </option-list-item>
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="!view.ViewSettings?.DisplayViewGroupsAboveCategories">
        <menu-accordion *ngFor="let accordion of viewGroupAccordions" [model]="accordion" (itemSelect)="handleViewSelect($event)"></menu-accordion>
      </ng-container>
    </div>
  </div>
</div>