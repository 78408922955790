import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

import { GlobalEventService } from '../../entities/global-event.service';
import { Hotspot } from '../../entities/hotspot';
import { HotspotService } from '../../entities/hotspot.service';
import { KRPanoService } from '../../entities/krpano.service';
import { KrPanoHotspot } from '../../entities/krpano/krpano-data';
import { PanoService } from '../../entities/pano.service';
import { View } from '../../entities/view';

@Component({
  selector: 'hotspot-debug-menu',
  templateUrl: './hotspot-debug-menu.component.html',
  styleUrls: ['./hotspot-debug-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotspotDebugMenuComponent implements OnInit, OnChanges {
  @Input() view: View;
  hotspot: Hotspot;
  krPanoHotspot: KrPanoHotspot;
  showHotspotDebugMenu = false;
  imageUrl: string;
  allHotspots: Hotspot[] = [];

  constructor(
    private globalEventService: GlobalEventService,
    private hotspotService: HotspotService,
    private cdr: ChangeDetectorRef,
    private krpanoService: KRPanoService,
    private route: ActivatedRoute,
    private panoService: PanoService
  ) {}

  ngOnInit(): void {
    this.showHotspotDebugMenu =
      this.route.snapshot.queryParamMap.get('showHotspotDebugMenu')?.toLowerCase() === 'true';
    if (!this.showHotspotDebugMenu) {
      this.cdr.markForCheck();
      return;
    }

    // NOTE: event is still named "changeView" during xml build but really is used as a general "select" event
    this.globalEventService.subscribe('changeView', (hotspotId: string) => {
      this.onHotspotSelect(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('toggleVideoPause', (hotspotId: string) => {
      this.onHotspotSelect(parseInt(hotspotId, 10));
    });
  }

  ngOnChanges() {
    this.allHotspots = this.view?.Hotspots;
    this.hotspot = null;
    this.krPanoHotspot = null;
  }

  handleSelectChange(evt: MatSelectChange) {
    const hotspot = evt.value as Hotspot;
    this.onHotspotSelect(hotspot.HotspotId);
  }

  onHotspotSelect(hotspotId: number) {
    const tour = this.panoService.pano.Tours.find(x => x.TourId === this.view.TourId);
    this.hotspot = this.hotspotService.getById(hotspotId, tour);
    this.krPanoHotspot = this.krpanoService.getHotspotObject(this.hotspot?.HotspotId);
    console.log(this.krPanoHotspot);
    this.cdr.markForCheck();
  }

  handleImageUrlChange() {
    if (this.krPanoHotspot.sprite) {
      this.panoService.removeChildrenNodes(this.krPanoHotspot.sprite);
      this.krPanoHotspot.sprite.style.background = `url('${this.imageUrl}')`;
    }
  }

  toggleOtherHotspotVisibility() {
    if (this.hotspot) {
      const hotspots = this.view.Hotspots.filter(x => x.HotspotId !== this.hotspot.HotspotId);
      hotspots.forEach(h => {
        const hs = this.krpanoService.getHotspotObject(h.HotspotId);
        if (hs) {
          if (hs.sprite.classList.contains('hide')) hs.sprite.classList.remove('hide');
          else hs.sprite.classList.add('hide');
        }
      });
    }
  }
}
