<div class="accordion-header flex row align-center justify-between" [class.open]="model.IsOpen" (click)="toggleOpen()">
    <div>{{model.Name}}</div>
    <div class="expander">
        <icon name="line" [class.open]="model.IsOpen"></icon>
        <icon name="line"></icon>
    </div>
</div>
<div class="accordion-body" [class.open]="model?.IsOpen">
    <div *ngFor="let item of model?.ChildItems" class="accordion-body-option flex row align-center justify-between" [class.selected]="item.IsSelected"
         (click)="itemSelect.emit(item)">
        <div class="label-container">
            {{item.Name}}</div>
        <div class="icon-container">
            <icon name="success"></icon>
        </div>
    </div>
</div>