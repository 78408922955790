import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { GlobalEventService } from '../../entities/global-event.service';
import { KRPanoService } from '../../entities/krpano.service';
import { MiniMap } from '../../entities/minimap';
import { Pano, PanoZoomDirection } from '../../entities/pano';
import { DisclaimerSettings } from '../../entities/settings/pano-settings';
import { Tour } from '../../entities/tour';
import { View } from '../../entities/view';
import { GoogleTagManagerService } from '../../utility/helpers/google-tag-manager';

@Component({
  selector: 'tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolBarComponent implements OnInit {
  @Input() pano: Pano;
  @Input() tour: Tour;
  @Input() view: View;
  @Output() hotspotSelect = new EventEmitter<number>();

  showingFullScreenButton = false;

  miniMapPosition = 'bottom-left';
  webVRPosition = '';
  zoomButtonsPosition = '';
  panoZoomDirection = PanoZoomDirection;
  disclaimerSettings: DisclaimerSettings;

  controlsMenuOpen = false;

  constructor(
    private krpanoService: KRPanoService,
    private gtmService: GoogleTagManagerService,
    private globalEventService: GlobalEventService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.setDefaultMiniMapPosition();
    this.setZoomButtonPosition();
    this.setDisclaimerSettings();
    this.showingFullScreenButton = this.pano && this.pano.Settings.EnableFullScreenOnPano;

    this.globalEventService.subscribe('onWebVRAvailable', () => {
      this.krpanoService.setWebVRAvailable(true);
      if (this.pano.Settings.WebVRSettings.Enabled) {
        this.setDefaultWebVRButtonPosition();
      }
      this.cdr.markForCheck();
    });

    this.globalEventService.subscribe('onWebVRUnavailable', () => {
      this.krpanoService.setWebVRAvailable(false);
      this.webVRPosition = '';
      this.cdr.markForCheck();
    });
  }

  setDisclaimerSettings() {
    this.disclaimerSettings = this.pano.Settings.DisclaimerSettings;
    this.disclaimerSettings.Position = this.formatPositionValue(this.disclaimerSettings.Position);
    if (!this.disclaimerSettings.Enabled) this.disclaimerSettings.Position = '';
  }

  setDefaultMiniMapPosition() {
    const miniMap = this.tour.MiniMaps.find(mm => mm.MiniMapId === this.view.MiniMapId);

    if (miniMap) {
      this.miniMapPosition = this.formatPositionValue(
        miniMap.MiniMapSettings.MiniMapPosition || 'Bottom Right'
      );
    }
  }

  setDefaultWebVRButtonPosition() {
    if (this.pano.Settings.WebVRSettings.Position) {
      this.webVRPosition = this.formatPositionValue(
        this.pano.Settings.WebVRSettings.Position?.toLowerCase() || 'top'
      );
    }
  }

  setZoomButtonPosition() {
    if (!this.pano.Settings.DisableMouseWheelZoom) {
      return;
    }

    this.zoomButtonsPosition = this.formatPositionValue(this.pano.Settings.ZoomButtonsLocation);
  }

  onMiniMapUpdate(miniMap: MiniMap) {
    if (miniMap) {
      this.miniMapPosition = this.formatPositionValue(miniMap.MiniMapSettings.Position);
    }
  }

  onHotspotSelect(hotspotId: number) {
    this.hotspotSelect.emit(hotspotId);
  }

  zoomPano(direction: PanoZoomDirection) {
    if (this.view.Settings.IsStaticImage) {
      this.krpanoService.zoomPano(direction, 0.1);
    } else {
      this.krpanoService.zoomPano(direction, 5);
    }
  }

  toggleWebVR() {
    const enabled = this.krpanoService.toggleWebVR();
    this.gtmService.addEvent(
      `WebVR ${enabled ? 'On' : 'Off'}`,
      'WebVR',
      enabled ? 'On' : 'Off',
      this.view.ViewId
    );
  }

  formatPositionValue(str: string): string {
    if (!str) {
      return '';
    }

    return str.replace(/\s+/g, '-').toLowerCase();
  }
}
