import { SafeUrl } from '@angular/platform-browser';

export class FloorPlan {
  Name: string;
  Url: string;
  SafeUrl: SafeUrl;

  cost: string;
  sqft: string;
  bedrooms: string;
  bathrooms: string;
  garageCount: string;
  floorCount: string;

  selectedOptions = new Array<string>();
  details = new Array<string>();
  elevations = Array<Elevation>();

  mediaIndex = 0;
}

export class Elevation {
  Name: string;
  Uri: string;
  mediaIndex = 0;
}
