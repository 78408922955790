<div class="title-and-menu">
    <div class="title-bar">
        <div class="title" [class.hidden]="!showTitle">{{view.Name}}</div>
        <button mat-button (click)="toggleHamburgerMenu()">
            <icon [name]="isOpen ? 'close' : 'hamburger'"></icon>
        </button>
    </div>
    <div class="hamburger-menu" [class.open]="isOpen">
        <div class="item" (click)="handleSavePDF()" *ngIf="downloadImageEnabled">
            <icon name="pdf"></icon>Save PDF
        </div>
        <div class="item" (click)="handleShare()">
            <icon name="share"></icon>Share
        </div>
        <div class="item" (click)="handleSaveImage()" *ngIf='downloadImageEnabled'>
            <icon name="download"></icon>Download Image
        </div>
        <div class="item" (click)="toggleGyro()" *ngIf='hasGyroscope'>
            <icon name="gyroscope"></icon>Accelerometer
        </div>
        <div class="item" (click)="toggleWebVR()" *ngIf='hasWebVREnabled'>
            <icon name="gyroscope"></icon>Web VR
        </div>
    </div>
</div>