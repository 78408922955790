import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { GlobalEventService } from '../../entities/global-event.service';
import { Hotspot } from '../../entities/hotspot';
import { KRPanoService } from '../../entities/krpano.service';
import { KrPanoHotspot } from '../../entities/krpano/krpano-data';
import { PopupModalContent } from './popup-modal-content';

import html2canvas from 'html2canvas';

@Component({
  selector: 'popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupModalComponent implements OnInit, AfterViewInit {
  @ViewChild('iframeElement') iframeElement: ElementRef;
  @ViewChild('modal') modal: ElementRef;
  @Input() content: PopupModalContent;
  @Output() modalClose = new EventEmitter<void>();
  currentGalleryIndex = 0;
  orientation = 'left';
  htmlContent: SafeHtml;
  htmlTitleContent: SafeHtml;
  hasHtmlTitle = false;
  isImage = false;

  constructor(
    private sanitizer: DomSanitizer,
    private krpanoService: KRPanoService,
    private globalEventService: GlobalEventService
  ) {}

  ngOnInit(): void {
    this.orientation = this.getOrientation(this.currentGalleryIndex);
    this.htmlTitleContent = this.getHtmlTitleContent(this.currentGalleryIndex);
    this.htmlContent = this.getHtmlContent(this.currentGalleryIndex);
    this.isImage = this.content.Media.length && this.content.Media[0].IsImage;

    this.globalEventService.subscribe('closeVRPopupModal', ng => {
      this.closePopupModal();
    });

    window.addEventListener('message', event => {
      if (event.origin.indexOf('webspinner') > -1) {
        const data = JSON.parse(event.data);
        if (data.scrollHeight && this.iframeElement) {
          this.iframeElement.nativeElement.style.height = `${
            parseInt(data.scrollHeight, 10) + 30
          }px`;

          this.modal.nativeElement.style.maxHeight = `${parseInt(data.scrollHeight, 10) + 130}px`;
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.iframeElement) {
      this.iframeElement.nativeElement.onload = () => {
        this.iframeElement.nativeElement.contentWindow.postMessage('message', '*');
      };
    }
  }

  handleClose() {
    this.modalClose.emit();
  }

  setGalleryIndex(index: number) {
    this.currentGalleryIndex = this.validateIndexBounds(index, this.content.Media.length);
    this.orientation = this.getOrientation(this.currentGalleryIndex);
    this.htmlTitleContent = this.getHtmlTitleContent(this.currentGalleryIndex);
    this.htmlContent = this.getHtmlContent(this.currentGalleryIndex);
  }

  private validateIndexBounds(index: number, total: number) {
    if (index > total - 1) index = 0;
    if (index < 0) index = total - 1;
    return index;
  }

  getOrientation(index: number): string {
    if (this.content.Orientations) if (!this.content.Orientations[index]) return '';

    return this.content.Orientations[index].toString().indexOf('right') > -1
      ? 'right'
      : this.content.Orientations[index].toString().indexOf('portrait') > -1
      ? 'portrait'
      : 'left';
  }

  getHtmlContent(index: number): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.content.Content[index] || '');
  }

  getHtmlTitleContent(index: number): SafeHtml {
    this.hasHtmlTitle = (this.content.Titles[index] || '').length > 0;
    return this.sanitizer.bypassSecurityTrustHtml(this.content.Titles[index] || '');
  }

  createImageHotspot(hotspot: Hotspot) {
    const modal = document.querySelector('.modal-container') as any;
    const modalSize = modal.getBoundingClientRect();

    const video = modal.querySelector('video') as any;
    let videoSrc = '';
    let videoSize = {};
    if (video) {
      videoSrc = video.src;
      video.src = '';
      videoSize = video.getBoundingClientRect();
    }

    html2canvas(document.querySelector('.modal-container'), { useCORS: true }).then(canvas => {
      const mod = this.createPopUpModal(hotspot, canvas.toDataURL());
      setTimeout(() => {
        if (video) {
          this.injectVideo(mod, modalSize, videoSize, videoSrc);
        }

        this.createPopupModalCloseButton(mod);
      }, 100);
    });
  }

  private injectVideo(modal: KrPanoHotspot, modalSize, videoSize, videoSrc) {
    const videoContainer = this.krpanoService.addHotspot('video-container');
    videoContainer.name = 'video_container';
    videoContainer.atv = modal.atv;
    videoContainer.ath = modal.ath;
    videoContainer.edge = modal.edge;
    videoContainer.zorder = 11;
    videoContainer.height = modal.pixelheight * 0.865;
    videoContainer.width = modal.width;
    videoContainer.bgcapture = true;
    videoContainer.distorted = modal.distorted;
    videoContainer.rx = modal.rx;
    videoContainer.ry = modal.ry;
    videoContainer.rz = modal.rz;
    videoContainer.scale = modal.scale;
    videoContainer.renderer = 'css3d';
    videoContainer.url = '%HTMLPATH%/pano/skin/transparentImage.png';

    const videoHotspot = this.krpanoService.addLayer('vid');
    const videoHeightProp = videoSize.height / modalSize.height;

    videoHotspot.align = 'top';
    videoHotspot.zorder = 11;
    videoHotspot.height = modal.pixelheight * videoHeightProp * 1.03;
    videoHotspot.width = modal.width * 0.98;
    videoHotspot.url = '%HTMLPATH%/pano/plugins/videoplayer.js';
    videoHotspot.videourl = videoSrc;
    videoHotspot.bgcapture = true;
    videoHotspot.distorted = modal.distorted;
    videoHotspot.parent = `hotspot[video-container]`;
  }

  private createPopUpModal(hotspot: Hotspot, url: string): KrPanoHotspot {
    const modal = this.krpanoService.addHotspot('popup');
    modal.url = url;

    modal.ath = parseInt(hotspot.ATH, 10) + hotspot.ModalSettings.OffSetX;
    modal.atv = parseInt(hotspot.ATV, 10) + hotspot.ModalSettings.OffSetY;
    modal.edge = 'center';
    modal.width = hotspot.ModalSettings.VRModalWidth;
    modal.height = 'prop' as any;
    modal.distorted = hotspot.ModalSettings.Distorted;
    modal.rx = hotspot.ModalSettings.Rx;
    modal.ry = hotspot.ModalSettings.Ry;
    modal.rz = hotspot.ModalSettings.Rz;
    modal.zorder = 10;

    return modal;
  }

  private createPopupModalCloseButton(modal: KrPanoHotspot) {
    const containerName = 'popup-controls-container';
    const controllerContainer = this.krpanoService.addHotspot(containerName);

    controllerContainer.atv = modal.atv;
    controllerContainer.ath = modal.ath;
    controllerContainer.edge = modal.edge;
    controllerContainer.zorder = 11;
    controllerContainer.height = modal.pixelheight;
    controllerContainer.width = modal.width;
    controllerContainer.onclick = `js(angularEvent('closeVRPopupModal'))`;
    controllerContainer.url = '%HTMLPATH%/pano/skin/transparentImage.png';
    controllerContainer.bgcapture = true;
    controllerContainer.distorted = modal.distorted;
    controllerContainer.rx = modal.rx;
    controllerContainer.ry = modal.ry;
    controllerContainer.rz = modal.rz;
    controllerContainer.scale = modal.scale;
  }

  closePopupModal() {
    this.krpanoService.removeHotspotFromView('popup');
    this.krpanoService.removeHotspotFromView('popup-controls-container');
    this.krpanoService.removeHotspotFromView('video_container');
    this.krpanoService.removeLayerFromView('vid');
  }

  @HostListener('window:keydown', ['$event'])
  onMessage(keyEvent: KeyboardEvent) {
    if (!keyEvent) {
      return;
    }

    if (keyEvent.code === 'Escape') {
      this.handleClose();
    }
  }
}
