import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {
  lastEventTimes = new Map<string, number>();

  events = [
    'changeView',
    'onviewchange',
    'toggleVideoPause',
    'toggleMute',
    'onMouseOver',
    'onMouseOut',
    'onLoaded',
    'onSceneClick',
    'onWebVRAvailable',
    'onWebVRUnavailable',
    'onvideoready',
    'onentervr',
    'onexitvr',
    'closeVRPopupModal'
  ];

  eventsToDebounce = ['changeView', 'onviewchange', 'onSceneClick'];
  debounceTimeInMs = 300;

  private subscriptions: {
    [key: string]: ((args: any) => void)[];
  } = {};

  private subjects = new Map<string, Subject<any>>();

  constructor(private zone: NgZone) {
    this.events.forEach(eventName => {
      this.subscriptions[eventName] = [];
      this.subjects.set(eventName, new Subject<any>());
    });

    window['angularEvent'] = (eventName: string, args: any, args2: any) => {
      if (!this.events.includes(eventName)) {
        throw new Error(`Event (${eventName}) has to be defined in the event list.`);
      }

      if (eventName === 'onSceneClick') args = { X: args, Y: args2 };

      if (this.shouldDebounceAction(args, eventName)) return;

      this.zone.run(() => {
        this.fireEvent(eventName, args);
      });
    };
  }

  shouldDebounceAction(id: string, eventName: string) {
    if (!this.eventsToDebounce.includes(eventName)) return false;

    const eventKey = eventName || id;
    if (
      this.lastEventTimes.has(eventKey) &&
      this.lastEventTimes.get(eventKey) + this.debounceTimeInMs > Date.now()
    )
      return true;

    this.lastEventTimes.set(eventKey, Date.now());
    return false;
  }

  listen$(eventName: string): Observable<any> {
    const subject = this.findSubject(eventName);

    return subject.asObservable();
  }

  private findSubject(eventName: string) {
    const subject = this.subjects.get(eventName);
    if (!subject) throw new Error(`The event '${eventName}' is not listed in GlobalEventService`);
    return subject;
  }

  fireEvent(eventName: string, args: any) {
    if (!this.subscriptions[eventName]) {
      throw new Error(`Event (${eventName}) has to be defined in the event list.`);
    }
    this.subscriptions[eventName].forEach(fn => {
      fn(args);
    });

    const subject = this.findSubject(eventName);
    subject.next(args);
  }

  /** DEPRECATED: use listen$ instead and subscribe to an observable */
  subscribe(eventName: string, func: (args: any) => void) {
    if (!this.events.includes(eventName)) {
      throw new Error(
        'Unable to subscribe to global event. Please verify event is listed within the GlobalEventService'
      );
    }

    this.subscriptions[eventName].push(func);
  }
}
