import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { Category } from '../../../entities/category';
import { Option } from '../../../entities/option';
import { Pano } from '../../../entities/pano';
import { Tour } from '../../../entities/tour';
import { View } from '../../../entities/view';
import { GoogleTagManagerService } from '../../../utility/helpers/google-tag-manager';

@Component({
  selector: 'allviews-menu',
  templateUrl: './allviews-menu.component.html',
  styleUrls: ['./allviews-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AllViewsMenuComponent implements OnInit {
  @Input() pano: Pano;
  @Input() tour: Tour;
  @Output() toggleOpen = new EventEmitter<boolean>();
  @Output() viewSelect = new EventEmitter<number>();
  controlsMenuOpen = false;

  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit() {
    this.controlsMenuOpen = this.pano.LeftMenuOpen;
  }

  toggleControlMenu(forceValue?: boolean) {
    this.controlsMenuOpen = forceValue !== undefined ? forceValue : !this.controlsMenuOpen;
    this.toggleOpen.emit(this.controlsMenuOpen);
  }

  toggleCategoryMenu(category: Category) {
    category.IsOpen = !category.IsOpen;
  }

  selectOption(option: Option, category: Category, view: View) {
    this.tour.Views = this.tour.Views.map(v => {
      v.Categories = v.Categories.map(cat => {
        cat.Options = cat.Options.map(opt => {
          opt.IsSelected = false;
          return opt;
        });

        return cat;
      });
      return v;
    });

    if (category.Options) {
      category.Options = category.Options.map(opt => {
        opt.IsSelected = opt.OptionId === option.OptionId;
        return opt;
      });
    }

    this.gtmService.addEvent('OptionOn', 'Option', option.OptionId.toString(), view.ViewId);

    this.viewSelect.emit(view.ViewId);
  }
}
