export class WebVR {
  enabled: boolean;
  isavailable: boolean;
  entervr() { this.webVR.entervr(); };
  exitvr() { this.webVR.exitvr(); }

  private webVR: any;

  constructor(webVR: any) {
    this.webVR = webVR;
    this.webVR.onentervr = "js(angularEvent('onentervr'))";
    this.webVR.onexitvr = "js(angularEvent('onexitvr'))";
  }
}
