import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ParamMap,
  Router
} from '@angular/router';

import { PanoService } from './entities/pano.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet>
      <div *ngIf="loading" style="height: 100%" class="flex align-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </router-outlet>
    <toast></toast>
  `,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(
    private panoService: PanoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private accelerationDisabledByQueryParam(parameters: ParamMap): boolean {
    return window.location.href.toLowerCase().indexOf('accelerometer=false') > -1;
  }
}
