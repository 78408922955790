import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BrowserService } from '../../../entities/browser.service';
import { Category } from '../../../entities/category';
import { Option } from '../../../entities/option';
import { PanoService } from '../../../entities/pano.service';
import { View } from '../../../entities/view';
import { GoogleTagManagerService } from '../../../utility/helpers/google-tag-manager';
import {
  AnimationState,
  heightExpandCollapseAnimation,
  widthExpandCollapseAnimation
} from '../../animations';
import {
  ResetConfirmDialogComponent
} from '../../reset-confirm-dialog/reset-confirm-dialog.component';
import { ShareDialogComponent } from '../../share-dialog/share-dialog.component';

@Component({
  selector: 'thumbnail-menu',
  templateUrl: './thumbnail-menu.component.html',
  styleUrls: ['./thumbnail-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [heightExpandCollapseAnimation(), widthExpandCollapseAnimation()]
})
export class ThumbnailMenuComponent implements OnInit {
  @Input() view: View;
  disableResetButton = true;
  categoriesWithThumbnails: Category[] = [];
  isAnyCategoryOpen = false;
  expandCollapseWidth = false;
  isCompactScreen: boolean;

  constructor(
    private panoService: PanoService,
    private browserService: BrowserService,
    private matDialog: MatDialog,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    if (this.view)
      this.categoriesWithThumbnails = this.view.Categories;

    for (const category of this.categoriesWithThumbnails) {
      if (!category.Settings.IconSvgContent) {
        // eslint-disable-next-line max-len
        category.Settings.IconSvgContent = `<svg height="480pt" viewBox="0 0 480 480" width="480pt" xmlns="http://www.w3.org/2000/svg"><path d="m432 240c0 106.039062-85.960938 192-192 192s-192-85.960938-192-192 85.960938-192 192-192 192 85.960938 192 192zm0 0"></path><path d="m240 480c-132.546875 0-240-107.453125-240-240s107.453125-240 240-240 240 107.453125 240 240c-.148438 132.484375-107.515625 239.851562-240 240zm0-464c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm0 0"></path><path d="m352 240c0 61.855469-50.144531 112-112 112s-112-50.144531-112-112 50.144531-112 112-112 112 50.144531 112 112zm0 0"></path></svg>`;
      }
    }

    for (const option of this.view.Categories.flatMap(c => c.Options)) {
      if (!option.PreviewFilename) {
        option.PreviewFilename = 'https://argo.ml3ds-stage.com/productdata/panoresources/black-hotspot.svg';
      }
    }

    this.isCompactScreen = this.browserService.isCompact();
    this.setWidthHeightExpandAnimation();
  }
  // Keep mobile responsive for menu changes between landscape and portrait
  @HostListener('window:resize')
  onResize() {
    this.setWidthHeightExpandAnimation();
  }

  handleCategorySelect(category: Category) {
    const currentOpenCategory = this.categoriesWithThumbnails.find(c => c.IsOpen);
    if (currentOpenCategory) {
      if (currentOpenCategory.CategoryId === category.CategoryId) {
        category.IsOpen = !category.IsOpen;
        if (category.IsOpen) {
          category.AnimationState = this.expandCollapseWidth
            ? AnimationState.ExpandedWidth
            : AnimationState.ExpandedHeight;
        } else {
          category.AnimationState = this.expandCollapseWidth
            ? AnimationState.CollapsedWidth
            : AnimationState.CollapsedHeight;
        }
        this.isAnyCategoryOpen = this.categoriesWithThumbnails.some(c => c.IsOpen);
        return;
      }
      currentOpenCategory.IsOpen = false;
      currentOpenCategory.AnimationState = this.expandCollapseWidth
        ? AnimationState.CollapsedWidth
        : AnimationState.CollapsedHeight;
    }
    category.IsOpen = true;
    category.AnimationState = this.expandCollapseWidth
      ? AnimationState.ExpandedWidth
      : AnimationState.ExpandedHeight;
    this.isAnyCategoryOpen = this.categoriesWithThumbnails.some(c => c.IsOpen);
  }

  selectOption(option: Option, category: Category) {
    if (option.IsSelected) {
      return;
    }

    if (category.Options) {
      const currentSelected = category.Options.find(o => o.IsSelected);
      currentSelected.IsSelected = false;
      option.IsSelected = true;

      this.gtmService.addEvent('OptionOn', 'Option', option.OptionId.toString(), this.view.ViewId);
      this.panoService.updateSceneByView(this.view);
      this.disableResetButton = this.view.isInDefaultState();
    }
  }

  setWidthHeightExpandAnimation() {
    this.isCompactScreen = this.browserService.isCompact();
    this.isAnyCategoryOpen = false;
    if (this.isCompactScreen) {
      if (screen.orientation.type === 'landscape-primary') {
        this.expandCollapseWidth = true;
        this.categoriesWithThumbnails.forEach(c => {
          c.AnimationState = AnimationState.CollapsedWidth;
          c.IsOpen = false;
        });
        return;
      }
    }
    this.expandCollapseWidth = false;
    this.categoriesWithThumbnails.forEach(c => {
      c.AnimationState = AnimationState.CollapsedHeight;
      c.IsOpen = false;
    });
  }

  handleShare() {
    this.matDialog.open(ShareDialogComponent);
  }

  handleReset() {
    if (this.disableResetButton) return;
    const modal = this.matDialog.open(ResetConfirmDialogComponent);

    const obs = modal.afterClosed().subscribe(reset => {
      if (reset) {
        this.view.resetToDefault();
        for (const category of this.view.Categories) {
          category.Options = category.Options.map(opt => new Option(opt, opt.IsSelected));
        }
        this.panoService.updateSceneByView(this.view);
        this.disableResetButton = true;
      }
      obs.unsubscribe();
    });
  }

  collapseMenuCloseCategory(category?: Category) {
    const openCat = category ? category : this.categoriesWithThumbnails.find(c => c.IsOpen);
    if (openCat) {
      openCat.IsOpen = false;
      this.isAnyCategoryOpen = false;
      if (screen.orientation.type === 'landscape-primary') {
        openCat.AnimationState = AnimationState.CollapsedWidth;
      } else {
        openCat.AnimationState = AnimationState.CollapsedHeight;
      }
    }
  }
}
