import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MetadataService } from '../../server/utility/metadata.service';
import { BrowserService } from '../entities/browser.service';
import { GlobalEventService } from '../entities/global-event.service';
import { ActionType, Hotspot } from '../entities/hotspot';
import { HotspotService } from '../entities/hotspot.service';
import { KRPanoService, Video } from '../entities/krpano.service';
import { IKrPanoVideo } from '../entities/krpano/krpano';
import { NeighborNavigation, OptionCombination } from '../entities/option-combination';
import { Pano, PanoZoomDirection } from '../entities/pano';
import { PanoBlend } from '../entities/pano-blend';
import { PanoService } from '../entities/pano.service';
import { Tour } from '../entities/tour';
import { View } from '../entities/view';
import { SettingsService } from '../services/settings.service';
import { enterLeaveFadeAnimation } from '../shared/animations';
import { MinimapComponent } from '../shared/minimap/minimap.component';
import { XYMouseTouch } from '../shared/mouse-events.directive';
import { PopupModalContent } from '../shared/popup-modal/popup-modal-content';
import { PopupModalComponent } from '../shared/popup-modal/popup-modal.component';
import { GoogleTagManagerService } from '../utility/helpers/google-tag-manager';
import { Point } from '../utility/helpers/helpers';

import { SettingsCategoryTitle } from '@ml/common';

@Component({
  selector: 'pano-root',
  templateUrl: './pano-root.component.html',
  styleUrls: ['./pano-root.component.scss'],
  animations: [enterLeaveFadeAnimation(0, 500)],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoRootComponent implements OnInit {
  @ViewChild('minimap') minimap: MinimapComponent;
  @ViewChild('popupModal') popupModal: PopupModalComponent;
  pano: Pano;
  tour: Tour;
  view: View;

  errorMessage: string;
  hasPanoLoaded = false;
  popupModalContent: PopupModalContent;

  controlsMenuOpen = false;
  isCategoryMenuOpen = false;
  xYMouseEvent: XYMouseTouch;

  navGuideImgUrl: string;
  showNavGuideImg = false;
  postMessageFromParent: PostMessageEvent;

  setAccelerometerSetting = this.setAccelerometerSettings.bind(this);

  constructor(
    private panoService: PanoService,
    private globalEventService: GlobalEventService,
    private route: ActivatedRoute,
    private router: Router,
    private browserService: BrowserService,
    private settings: SettingsService,
    private metadataService: MetadataService,
    private hotspotService: HotspotService,
    private krpanoService: KRPanoService,
    private cdr: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService
  ) {}

  async ngOnInit() {
    if (!this.loadPanoData()) return;
    await this.krpanoService.loadPanoResources(this.pano);
    this.subscribeToGlobalEvents();

    const [tourId, viewId, optComboId] = this.getRouteParams();
    this.goToTourById(tourId, viewId, optComboId);

    this.initGoogleTagManager();
    this.initNavigationGuide();
    this.updateMetaData(optComboId);
    this.setMenuDefaults();
    this.postDataToParent();
    this.setTouchEvents();
  }

  setReceiverHotspotDefault() {
    for (const hotspot of this.view.Hotspots) {
      if (!hotspot.HotspotReceiversOn)
        for (const id of hotspot.HotspotReceiverIds) {
          this.krpanoService.hideHotspotById(id);
        }
    }
  }

  setTouchEvents() {
    window.addEventListener('touchend', this.setAccelerometerSetting, true);
  }

  loadPanoData(): boolean {
    if (this.route.snapshot.data.pano) {
      this.pano = this.route.snapshot.data.pano;
      return true;
    } else {
      this.errorMessage = 'Unable to retrieve pano data. Please double check your url is correct.';
      return false;
    }
  }

  getRouteParams(): number[] {
    const tourId = this.tryGetTourId();
    const viewId = this.tryGetViewId(this.pano.Tours.find(x => x.TourId === tourId));
    const optionComboId = +this.route.snapshot.queryParams.optComboId;

    return [tourId, viewId, optionComboId];
  }

  tryGetTourId(): number {
    let tourId: number;

    if (this.atLobby())
      tourId = this.pano.Tours.find(t => t.Name?.toLowerCase() === 'lobby')?.TourId;

    if (!tourId) tourId = +this.route.snapshot.paramMap.get('tourId');

    if (!tourId) {
      const tourName = this.route.snapshot.queryParams.tour as string;
      tourId = this.pano.Tours.find(x => x.Name.toLowerCase() === tourName?.toLowerCase())?.TourId;
    }

    return tourId;
  }

  tryGetViewId(tour: Tour | null): number {
    const viewId = +this.route.snapshot.paramMap.get('viewId');
    if (viewId) return viewId;

    if (tour) {
      const viewName = this.route.snapshot.queryParams.view as string;
      const view = tour.Views.find(x => x.Name.toLowerCase() === viewName?.toLowerCase());
      return view?.ViewId;
    }

    return null;
  }

  atLobby(): boolean {
    return this.router.url.indexOf('/lobby') > -1;
  }

  goToTourById(tourId: number, viewId = 0, optComboId = 0) {
    this.tour = this.pano.getTourByIdOrDefault(tourId);

    if (this.isDynamicPano() && !this.dynamicTourExists(tourId, this.tour)) return;

    this.view = this.tour.getViewByIdOrDefault(viewId);
    const optCombo = this.view.getOptComboByIdOrDefault(optComboId);
    if (optCombo) {
      this.view.toggleOptionsByOptionCombinationId(optCombo.OptionCombinationId);
    }

    this.panoService.updateSceneByView(this.view);
  }

  isDynamicPano(): boolean {
    return this.router.url.indexOf('/dynamicpano') === 0;
  }

  dynamicTourExists(tourId: number, tour: Tour): boolean {
    if (!tourId) {
      this.errorMessage = 'Please add StoreId to load a store';
      return false;
    }

    if (!tour) {
      this.errorMessage = 'Please verify the store exists';
      return false;
    }

    return true;
  }

  initGoogleTagManager() {
    this.gtmService.initialize(
      this.pano.ClientName,
      this.pano.ClientId,
      this.pano.Name,
      this.pano.PanoId
    );

    this.gtmService.sendPageRequestBeforeLoadTimeStamp(this.view?.ViewId);
    this.gtmService.sendApiStartRequestTimestamp(this.view?.ViewId);
    this.gtmService.sendApiEndRequestTimestamp(this.view?.ViewId);
  }

  private initNavigationGuide() {
    this.navGuideImgUrl = this.settings.get('NavGuideImage', SettingsCategoryTitle.PanoViewerTheme);
    if (this.navGuideImgUrl) {
      this.showNavGuideImg = true;
    }
  }

  updateMetaData(optComboId: number) {
    const optCombo = this.view.findOptionCombinationByToggledOnOptions();
    this.metadataService.updateMetadata({
      title: this.getPageTitle(),
      description: `${this.view.Name} Pano powered by MediaLab 3D Solutions`,
      image: optCombo
        ? `${this.panoService.getBasePath()}panos/${optCombo.ImagePreviewRelativeUrl}`
        : ''
    });
  }

  private getPageTitle(): string {
    return (this.settings.get('PageTitle', SettingsCategoryTitle.PanoViewerTheme) ?? this.view.Name)
      .replace(/{{viewName}}/gi, this.view.Name)
      .replace(/{{tourName}}/gi, this.tour.Name)
      .replace(/{{panoName}}/gi, this.pano.Name);
  }

  onPanoGraphicsRendered() {
    requestAnimationFrame(() => {
      this.hasPanoLoaded = true;
      this.gtmService.sendPageLoad(this.view?.ViewId);
      this.cdr.markForCheck();

      if (this.pano.Settings?.AutoRotateThruAllViews) {
        this.handleAutoRotateThruAllViews();
      } else if (!this.hasMiniMapInTour()) {
        this.krpanoService.disableOnViewChange();
      }

      this.applyOnLoadVideoMuteSetting();
      this.setReceiverHotspotDefault();
    });
  }

  private applyOnLoadVideoMuteSetting() {
    if (!this.view) return;
    this.view.Hotspots.forEach(hotspot => {
      if (hotspot.ActionType === ActionType.VIDEO && hotspot.Settings.MuteVideoOnLoad) {
        this.krpanoService.muteVideoByHotspotId(true, hotspot.HotspotId);
      }
    });
  }

  subscribeToGlobalEvents() {
    // using 'onviewchange' as a way to detect krpano is fully loaded and view is visible -- only need first event so unsubscribe after that
    const sub = this.globalEventService.listen$('onviewchange').subscribe(() => {
      sub.unsubscribe();
      this.onPanoGraphicsRendered();

      if (this.pano.IsDynamic) {
        this.postMessage('OnLoad', this.tour.Name);
      }
    });

    this.globalEventService.subscribe('changeView', (hotspotId: string) => {
      this.onHotspotSelect(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('toggleVideoPause', (hotspotId: string) => {
      this.onHotspotToggleVideoPause(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('toggleMute', (hotspotId: string) => {
      this.onHotspotToggleMute(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('onMouseOver', (hotspotId: string) => {
      this.onHotspotOnMouseOver(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('onMouseOut', (hotspotId: string) => {
      this.onHotspotOnMouseOut(parseInt(hotspotId, 10));
    });

    this.globalEventService.subscribe('onentervr', () => {
      this.krpanoService.onEnterVR(this.view);
    });

    this.globalEventService.subscribe('onexitvr', () => {
      this.krpanoService.onExitVR(this.view);
    });

    this.globalEventService.subscribe('onSceneClick', (coordinates: Point) => {
      this.onSceneClick(coordinates);
    });

    this.globalEventService.subscribe('onLoaded', (hotspotId: string) => {
      if (isNaN(+hotspotId)) {
        if (this.pano.Settings.IconUnderMouseSettings.Enabled) {
          this.panoService.updateIconUnderMouseIcon(this.pano);
        }
      } else {
        const hotspot = this.hotspotService.getById(+hotspotId, this.tour);

        this.panoService.updateHotspotIcon(hotspot, this.view);
        this.panoService.updateIconUnderMouseIcon(this.pano);
      }
    });

    this.globalEventService.subscribe('onvideoready', (hotspotId: string) =>
      this.onVideoReady(hotspotId)
    );
  }

  onVideoReady(hotspotId: string): IKrPanoVideo {
    const video = this.krpanoService.tryToAutoPlayVideo(
      +hotspotId,
      this.postMessageFromParent,
      this.xYMouseEvent
    );
    this.postMessageFromParent = null;
    return video;
  }

  setMenuDefaults() {
    this.isCategoryMenuOpen =
      this.pano.UseLeftMenu && this.pano.LeftMenuOpen && !this.browserService.isCompact();
  }

  hasMiniMapInTour(): boolean {
    return !!this.tour?.MiniMaps.length && this.tour?.Views.some(t => !!t.MiniMapId);
  }

  handleAutoRotateThruAllViews() {
    let startingPos = this.krpanoService.getCurrentViewPosition().Horizontal;
    this.globalEventService.listen$('onviewchange').subscribe(() => {
      const currentPos = this.krpanoService.getCurrentViewPosition().Horizontal;

      if (currentPos > startingPos + 360) {
        const nextView = this.panoService.getNextViewInOrder(this.view.ViewId);
        this.goToViewById(nextView.ViewId);
        startingPos = this.krpanoService.getCurrentViewPosition().Horizontal;
        this.krpanoService.startAutoRotate();
      }
    });

    this.krpanoService.startAutoRotate();
  }

  onSceneClick(coordinates: Point) {
    if (this.pano.Settings.HotspotSettings.ActivateClosestHotspot) {
      const closestHotspotElement = this.krpanoService.findClosestHotspot(coordinates);
      const hotspotId = this.krpanoService.findHotspotIdFromHotspotElement(closestHotspotElement);

      const hotspot = this.view.Hotspots.find(hs => hs.HotspotId === hotspotId);

      if (hotspot?.ActionType === ActionType.NextView) {
        this.goToViewById(hotspot.GoToViewId, hotspot.PanoBlend || this.pano.MainBlend);
      } else if (hotspot?.ActionType === ActionType.SwitchToOptionCombination) {
        this.goToOptionCombinationById(
          hotspot.OptionCombinationId,
          hotspot.PanoBlend || this.pano.MainBlend
        );
      }
    }
  }

  // NOTE: event is still named "changeView" during xml build but really is used as a general "select" event
  onHotspotSelect(hotspotId: number) {
    if (hotspotId) {
      const hotspot = this.hotspotService.getById(hotspotId, this.tour);

      if (hotspot) {
        this.postMessage('OnHotspotClick', hotspot.Name);

        if (hotspot.ActionType === ActionType.NextView) {
          this.goToViewById(hotspot.GoToViewId, hotspot.PanoBlend || this.pano.MainBlend);
        } else if (hotspot.ActionType === ActionType.SwitchToOptionCombination) {
          this.goToOptionCombinationById(
            hotspot.GoToPanoOptionCombinationId,
            hotspot.PanoBlend || this.pano.MainBlend
          );
        } else if (
          hotspot.ActionType === ActionType.Url ||
          hotspot.ActionType === ActionType.MEDIA
        ) {
          this.popupModalContent = PopupModalContent.CreateFromHotspot(
            hotspot,
            this.panoService.getBasePath(),
            hotspot.ModalSettings.IsVRModal && this.krpanoService.webVR$.value?.enabled
          );

          if (hotspot.ModalSettings.IsVRModal && this.krpanoService.webVR$.value?.enabled) {
            this.injectIntoPano(hotspot);
          }

          this.cdr.markForCheck();
        } else if (hotspot.ActionType === ActionType.ControllerHotspot) {
          this.toggleHotspotReceivers(hotspot);
        }

        this.gtmService.addEvent(
          'OnHotspotClick',
          'Click',
          hotspot.HotspotId.toString(),
          this.view.ViewId,
          {
            ActionType: hotspot.ActionType,
            ViewId: this.view.ViewId
          }
        );
      } else {
        console.log(
          `Awkward! Unable to find hotspot within any of the views. Maybe a video hotspot. HotspotId: ${hotspotId}`
        );
      }
    } else {
      console.log(`Hotspot called but falsy value given. Ignoring action. HotspotId: ${hotspotId}`);
    }
  }

  toggleHotspotReceivers(hotspot: Hotspot) {
    hotspot.HotspotReceiversOn = !hotspot.HotspotReceiversOn;

    for (const id of hotspot.HotspotReceiverIds) {
      if (hotspot.HotspotReceiversOn) {
        this.krpanoService.showHotspotById(id);
      } else {
        this.krpanoService.hideHotspotById(id);
      }
    }
  }

  injectIntoPano(hotspot) {
    setTimeout(() => {
      this.popupModal.createImageHotspot(hotspot);
    }, 200);
  }

  handlePopupModalClose() {
    this.popupModalContent = null;
  }

  onHotspotToggleVideoPause(hotspotId: number) {
    this.krpanoService.togglePauseVideo(hotspotId, this.xYMouseEvent);
  }

  onHotspotToggleMute(hotspotId: number) {
    this.krpanoService.toggleMute(hotspotId, this.xYMouseEvent);
  }

  onHotspotOnMouseOver(hotspotId: number) {
    const hotspot = this.view.Hotspots.find(hs => hs.HotspotId === hotspotId);
    this.krpanoService.onMouseOver(hotspot, this.xYMouseEvent);
  }

  onHotspotOnMouseOut(hotspotId: number) {
    this.krpanoService.onMouseOut(hotspotId);
  }

  postMessage(actionType: string, name: string) {
    const currentVideo = this.krpanoService.getCurrentVideo();
    const event = this.createPostMessage(
      actionType,
      name,
      this.view.TourId,
      this.xYMouseEvent,
      currentVideo
    );

    window.parent.postMessage(JSON.stringify(event), '*');
  }

  private createPostMessage(
    event: string,
    name: string,
    id: number,
    xYMouseEvent: XYMouseTouch,
    video: Video
  ): PostMessageEvent {
    const postMessageEvent = new PostMessageEvent();

    postMessageEvent.Event = event;
    postMessageEvent.Name = name;
    postMessageEvent.Id = id;
    postMessageEvent.X = xYMouseEvent?.x;
    postMessageEvent.Y = xYMouseEvent?.y;
    postMessageEvent.Video = new PostMessageVideoEvent();
    postMessageEvent.Video.Id = video ? +video.id : null;
    postMessageEvent.Video.Time = video ? video.time : null;
    postMessageEvent.Video.Playing = video ? video.isPlaying : null;
    postMessageEvent.Video.Mute = this.krpanoService.getMute();

    return postMessageEvent;
  }

  goToViewById(viewId: number, blend?: PanoBlend) {
    if (!blend) blend = this.panoService.pano.MainBlend;

    const view = this.panoService.pano.getViewById(viewId);
    this.goToView(view, blend);
  }

  goToView(view: View, blend?: PanoBlend) {
    if (!blend) blend = this.panoService.pano.MainBlend;

    if (view) {
      this.view = view;
      this.tour = this.pano.Tours.find(t => t.TourId === this.view.TourId);

      if (this.minimap) {
        this.minimap.miniMapOpen = false;
      }

      this.panoService.updateSceneByView(view, blend);
      this.metadataService.updateMetadata({
        title: this.getPageTitle()
      });
      this.cdr.markForCheck();
    }
  }

  goToOptionCombinationById(optionCombinationId: number, blend: PanoBlend) {
    for (const tour of this.pano.Tours) {
      for (const view of tour.Views) {
        const optionCombo = view.OptionCombinations.find(
          oc => oc.OptionCombinationId === optionCombinationId
        );

        if (optionCombo) {
          if (view) {
            view.toggleOptionsByOptionCombinationId(optionCombinationId);
            this.view = Object.assign(new View(), view);
            this.tour = tour;

            if (this.minimap) {
              this.minimap.miniMapOpen = false;
            }

            this.panoService.updateSceneByOptionCombination(optionCombo, view, blend);
            this.cdr.markForCheck();
            return;
          }
        }
      }
    }
  }

  handleSideMenuToggle(isOpen: boolean) {
    this.isCategoryMenuOpen = isOpen;
  }

  onMouseMove(event: XYMouseTouch) {
    this.xYMouseEvent = Object.assign(new XYMouseTouch(), event);

    if (this.pano.Settings?.IconUnderMouseSettings?.Enabled) {
      this.krpanoService.updateIconUpdateMousePosition(
        this.pano.Settings?.IconUnderMouseSettings,
        this.xYMouseEvent
      );
    }
  }

  onSpinChange(event: NeighborNavigation) {
    const optionComboId =
      this.view.currentOptionCombination?.getNeighboringOptionComboIdByNavigation(event);
    if (optionComboId) {
      const views = this.pano.Tours.map(tour => tour.Views).reduce(
        (prev: View[], curr: View[]) => [...curr, ...prev],
        new Array<View>()
      );

      const optionCombos = views
        .map(view => view.OptionCombinations)
        .reduce(
          (prev: OptionCombination[], curr: OptionCombination[]) => [...curr, ...prev],
          new Array<OptionCombination>()
        );

      const optionCombo = optionCombos.find(oc => oc.OptionCombinationId === optionComboId);
      this.view = views.find(v => v.ViewId === optionCombo.ViewId);
      this.tour = this.pano.Tours.find(t => t.TourId === this.view.TourId);
      this.panoService.updateSceneByOptionCombination(optionCombo, this.view);
    }
  }

  closeNavGuide() {
    this.showNavGuideImg = false;
  }

  @HostListener('window:message', ['$event'])
  onMessage(messageEvent: MessageEvent) {
    if (!messageEvent || !messageEvent.data) {
      return;
    }

    const event =
      typeof messageEvent.data === 'string' ? JSON.parse(messageEvent.data) : messageEvent.data;

    switch (event.Name?.toString().toLowerCase()) {
      case 'video':
        this.setVideoByPostMessageEvent(new PostMessageVideoEvent(event));
        break;
      case 'view':
        this.setViewByPostMessageEvent(new PostMessageEvent(event));
        break;
      case 'store':
        this.setTourByPostMessageEvent(new PostMessageEvent(event));
        break;
    }
  }

  setTourByPostMessageEvent(event: PostMessageEvent) {
    this.goToTourById(event.Id);
  }

  setViewByPostMessageEvent(event: PostMessageEvent) {
    const viewId = +event.Id;
    this.goToViewById(viewId);
    // slight HACK to ensure menu states of other views are set... better solution would require big refactor
    this.pano.Tours.flatMap(x => x.Views)
      .filter(v => v.ViewId !== viewId)
      .flatMap(v => v.Categories)
      .flatMap(c => c.Options)
      .forEach(o => (o.IsSelected = false));

    if (event.Video && event.Video.Id) {
      this.krpanoService.setMute(event.Video.Mute);
      this.postMessageFromParent = event;
    } else {
      this.postMessageFromParent = null;
    }
  }

  setVideoByPostMessageEvent(event: PostMessageVideoEvent): IKrPanoVideo {
    if (event) {
      this.krpanoService.setMute(event.Mute);

      if (event.Playing) {
        return this.krpanoService.playVideo(event.Id, event.Time);
      } else {
        return this.krpanoService.pauseVideo(event.Id, event.Time);
      }
    }

    return null;
  }

  setAccelerometerSettings() {
    window.removeEventListener('touchend', this.setAccelerometerSetting, true);

    // query param takes precedence
    const hasAccelerometerQueryParamOverride =
      this.route.snapshot.queryParams.accelerometer?.toLowerCase() === 'true' ||
      this.route.snapshot.queryParams.accelerometer?.toLowerCase() === 'false';

    if (hasAccelerometerQueryParamOverride) {
      if (this.route.snapshot.queryParams.accelerometer?.toLowerCase() === 'true') {
        this.krpanoService.setGyroscope(true);
      } else {
        this.krpanoService.setGyroscope(false);
      }
    } else {
      if (this.pano.Settings.EnableAccelerometer) {
        this.krpanoService.setGyroscope(true);
      } else {
        this.krpanoService.setGyroscope(false);
      }
    }
  }

  private postDataToParent() {
    // if iframed then send up view data
    if (window.top !== window) {
      window.top.postMessage(
        {
          Name: 'AllPanoViews',
          Views: this.pano.Tours.flatMap(t => t.Views).map(v => ({
            ViewId: v.ViewId,
            Name: v.Name
          }))
        },
        '*'
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.view.Settings.IsStaticImage) {
      this.krpanoService.setMaxFoV();
    }
  }
}

export class PostMessageEvent {
  Name: string;
  Event: string;
  Id: number;
  X: number;
  Y: number;
  Video: PostMessageVideoEvent;

  constructor(event: any = null) {
    if (event) {
      Object.assign(this, event);
      this.Video = new PostMessageVideoEvent(event.Video);
    }
  }
}

export class PostMessageVideoEvent {
  Name: string;
  Id: number;
  Time: number;
  Playing: boolean;
  Mute: boolean;

  constructor(video: any = null) {
    if (video) {
      Object.assign(this, video);
      this.Mute = this.Mute !== true ? false : true;
    }
  }
}
