import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';

import { Favorite } from './favorite';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private FavoriteSubject = new BehaviorSubject<Favorite>(null);

  constructor(private sanitizer: DomSanitizer) {}

  get current$(): Observable<Favorite> {
    if (!this.FavoriteSubject) this.FavoriteSubject.next(new Favorite());
    return this.FavoriteSubject.asObservable();
  }

  get current(): Favorite {
    if (!this.FavoriteSubject.value) {
      this.FavoriteSubject.next(new Favorite());
    }

    return this.FavoriteSubject.value;
  }

  set current(fav: Favorite) {
    this.FavoriteSubject.next(fav);
  }
}
