import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { Hotspot } from '../../entities/hotspot';
import { MiniMap } from '../../entities/minimap';
import { Pano } from '../../entities/pano';
import { Tour } from '../../entities/tour';
import { View } from '../../entities/view';
import { ThemeService } from '../../services/theme.service';
import { distance, Point } from '../../utility/helpers/helpers';

@Component({
  selector: 'minimap',
  templateUrl: './minimap.component.html',
  styleUrls: ['./minimap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MinimapComponent implements AfterViewInit, OnChanges {
  @Input() pano: Pano;
  @Input() tour: Tour;
  @Input() currentView: View;
  @Input() showVideoTour = false;
  @Output() hotspotSelect = new EventEmitter<number>();
  @Output() viewVideoTour = new EventEmitter();
  @Output() miniMapUpdate = new EventEmitter();
  @ViewChild('miniMapImage') miniMapImage: ElementRef<HTMLElement>;

  miniMap: MiniMap;
  miniMapOpen = false;
  mapFilePath: string;
  mapHasLoaded = false;

  constructor(private themeService: ThemeService) {}

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tour || changes.currentView) {
      if (!this.tour?.MiniMaps.length || !this.currentView?.MiniMapId) {
        this.miniMap = null;
        this.mapFilePath = null;
        return;
      }

      this.miniMap = this.tour.MiniMaps.find(mm => mm.MiniMapId === this.currentView.MiniMapId);
      if (this.miniMap?.FileName) {
        const newFilePath = this.miniMap.FileName;

        if (newFilePath !== this.mapFilePath) {
          this.mapFilePath = newFilePath;
          this.mapHasLoaded = false;
        }
      }

      if (this.miniMap.MiniMapSettings.Width)
        this.themeService.updateCssVariable(
          '--mini-map-width',
          `${this.miniMap.MiniMapSettings.Width.toString()}vw`
        );

      if (this.miniMap.MiniMapSettings.MobileWidth)
        this.themeService.updateCssVariable(
          '--mini-map-mobile-width',
          `${this.miniMap.MiniMapSettings.MobileWidth.toString()}vw`
        );
    }

    if (this.miniMap.MiniMapSettings.OpenOnLoad && this.miniMapOpen === false) {
      this.toggleMiniMap();
    }

    this.miniMapUpdate.emit(this.miniMap);
  }

  toggleMiniMap() {
    this.miniMapOpen = !this.miniMapOpen;
    this.miniMap.Hotspots = this.miniMap.Hotspots.map(x => Object.assign(new Hotspot(), x));
    this.miniMapUpdate.emit(this.miniMap);
  }

  onHotspotSelect(hotspot: Hotspot) {
    this.hotspotSelect.emit(hotspot.HotspotId);
  }

  handleMiniMapClick(evt) {
    if (this.miniMap.Hotspots.length > 0 && this.pano.Settings.HotspotSettings.ActivateClosestHotspotOnMiniMap) {
      const clickPoint: Point = { X: evt.offsetX, Y: evt.offsetY };
      const closestHotspot = this.miniMap.Hotspots.reduce((a, b) =>
        Math.abs(distance(a.miniMapCenterPoint, clickPoint)) < Math.abs(distance(b.miniMapCenterPoint, clickPoint))
          ? a
          : b
      );

      this.hotspotSelect.emit(closestHotspot.HotspotId);
    }
  }

  handleMiniMapLoad() {
    this.mapHasLoaded = true;
    const miniMapImageSize = {
      Width: this.miniMapImage.nativeElement.clientWidth,
      Height: this.miniMapImage.nativeElement.clientHeight
    };
    this.miniMap.Hotspots = this.miniMap.Hotspots.map(h => new Hotspot(h, miniMapImageSize));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.miniMap) this.handleMiniMapLoad();
  }
}
