import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';

import { Pano } from '../entities/pano';
import { View } from '../entities/view';

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  public generatePdfAndDownload(data: PdfData) {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const padding = 10;
    const contentWidth = pageWidth - padding * 2;
    let workingPageHeight = padding;
    const lineHeight = doc.getLineHeight();
    const currentFont = doc.getFont().fontName;

    const topRectHeight = 30;
    doc.setFillColor(242, 242, 242);
    doc.rect(padding, padding, pageWidth - 20, topRectHeight, 'F');
    workingPageHeight += topRectHeight;

    doc.setFontSize(20);
    if (data.PanoTitle.length) {
      doc.text(data.PanoTitle, padding * 2, padding + 12.5);
      doc.setFontSize(16);
      doc.setFont(currentFont, 'bold');
      if (data.ViewName) {
        doc.text('- ' + data.ViewName, padding * 2, padding + 22.5);
      }
    } else if (data.ViewName) {
      doc.text(data.ViewName, padding * 2, padding + 17.5);
    }

    if (data.LogoCanvas) {
      const canvas = data.LogoCanvas;
      const imgDataUrl = canvas.toDataURL(data.logoFormat());
      let imgHeight = 23;
      const ratio = canvas.width / canvas.height;
      let imgWidth = imgHeight * ratio;

      const MAX_WIDTH = 50
      if (imgWidth > MAX_WIDTH) {
        imgWidth = MAX_WIDTH;
        imgHeight = imgWidth / ratio
      }

      doc.addImage(
        imgDataUrl,
        data.logoFormat(),
        contentWidth - imgWidth + 8,
        padding + 4 + (23 - imgHeight) / 2,
        imgWidth,
        imgHeight
      );
    }

    if (data.Canvas) {
      const canvas = data.Canvas;
      const imgDataUrl = canvas.toDataURL('image/png');
      const imgWidth = contentWidth;
      const ratio = canvas.height / canvas.width;
      const imgHeight = imgWidth * ratio;

      doc.addImage(imgDataUrl, 'image/png', padding, workingPageHeight, imgWidth, imgHeight);
      workingPageHeight += imgHeight;
    }

    if (data.SelectedOptions.length) {
      doc.line(padding, workingPageHeight, pageWidth - padding, workingPageHeight);

      doc.setFontSize(16);
      doc.setFont(currentFont, 'normal');
      doc.text('Selected Options:', 15, workingPageHeight + lineHeight / 2);
      workingPageHeight += lineHeight;

      const singleColumnWidth = (pageWidth - padding * 2) / 2;

      const selectedComponents = data.SelectedOptions;

      if (selectedComponents.length > 8) {
        const secondHalf = selectedComponents.splice(8);
        this.createSelectedOptionsBlock(
          selectedComponents,
          doc,
          workingPageHeight,
          15,
          singleColumnWidth,
          lineHeight,
          currentFont
        );
        this.createSelectedOptionsBlock(
          secondHalf,
          doc,
          workingPageHeight,
          singleColumnWidth + 15,
          singleColumnWidth,
          lineHeight,
          currentFont
        );
      } else {
        this.createSelectedOptionsBlock(
          selectedComponents,
          doc,
          workingPageHeight,
          15,
          singleColumnWidth,
          lineHeight,
          currentFont
        );
      }
    }
    const fileName = data.ViewName ? `${data.ViewName}` : 'PDF';
    doc.save(fileName + '-Details.pdf');
  }

  private createSelectedOptionsBlock(
    options: PdfViewOptions[],
    doc: jsPDF,
    startingHeight: number,
    startingWidth: number,
    columnWidth: number,
    lineHeight: number,
    currentFont: string
  ) {
    let workingPageHeight = startingHeight;
    options.forEach(option => {
      doc.setFontSize(14);
      doc.setFont(currentFont, 'bold');
      const text = '· ' + option.CategoryName + ':  ';
      doc.text(text, startingWidth, workingPageHeight);
      const textWidth = doc.getTextWidth(text);
      doc.setFont(currentFont, 'normal');
      doc.text(option.SelectedOptions.join(', '), startingWidth + textWidth, workingPageHeight, {
        maxWidth: columnWidth - textWidth
      });
      workingPageHeight += lineHeight / 2;
    });
  }
}

export class PdfViewOptions {
  constructor(public CategoryName: string, public SelectedOptions: string[]) { }
}

export class PdfData {
  PanoTitle = '';
  ViewName = '';
  LogoCanvas: HTMLCanvasElement;
  SelectedOptions: PdfViewOptions[] = [];
  Canvas: HTMLCanvasElement;

  private logoUrl = '';

  constructor(pano?: Pano, view?: View, canvas?: HTMLCanvasElement, logo = '') {
    if (pano && pano.CommunityName) {
      this.PanoTitle = pano.CommunityName;
    }

    if (view && view.Name) {
      this.ViewName = view.Name;
    }

    if (view && view.Categories) {
      this.SelectedOptions = view.Categories.map(
        c =>
          new PdfViewOptions(
            c.Name,
            c.Options.filter(o => o.IsSelected).map(o => o.Name)
          )
      );
    }

    this.Canvas = canvas;
  }

  logoFormat(): string {
    if (this.logoUrl.indexOf('.jpg') > -1 || this.logoUrl.indexOf('.jpeg') > -1) {
      return 'image/jpg';
    }

    if (this.logoUrl.indexOf('.png') > -1) {
      return 'image/png';
    }

    return 'image/png';
  }

  async loadLogo(url: string): Promise<void> {
    this.logoUrl = url;
    this.LogoCanvas = await this.getImageFromUrl(url, 10, 10);
  }

  private async getImageFromUrl(
    url: string,
    height: number,
    width: number
  ): Promise<HTMLCanvasElement> {
    return new Promise(resolve => {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');

      image.addEventListener('load', () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        resolve(canvas);
      });

      image.src = url;
    });
  }
}
