import { hasImageFileExtension, hasVideoFileExtension } from '@ml/common';

export interface IMedia {
  MediaId: number;
  PanoId: number;
  Filename: string;
  Title: string;
  Order: number;
  BaseDirectory: string;
  Orientation: string;
  Content: string;
}

export class Media implements IMedia {
  MediaId: number;
  PanoId: number;
  Filename: string;
  Title: string;
  Order: number;
  BaseDirectory: string;
  Orientation: string;
  Content: string;

  IsImage = false;
  IsVideo = false;

  constructor(iMedia: IMedia) {
    if (iMedia) {
      Object.assign(this, iMedia);

      this.IsImage = hasImageFileExtension(this.Filename);
      this.IsVideo = hasVideoFileExtension(this.Filename);
    }
  }
}
export interface IHotspotMedia {
  MediaId: number;
  HotspotId: number;
}

export class HotspotMedia implements IHotspotMedia {
  MediaId: number;
  HotspotId: number;

  constructor(hm: IHotspotMedia) {
    if (hm) {
      Object.assign(this, hm);
    }
  }
}
