import { Category, ICategory } from './category';
import { Hotspot, IHotspot } from './hotspot';
import { MiniMapSettings } from './minimap-settings';
import { IOptionCombination, OptionCombination } from './option-combination';
import { IViewGroup } from './view-group';
import { ViewSettings } from './view-settings';

import { sortByOrder } from '@ml/common';

export interface IView {
  ViewId: number;
  TourId: number;
  Name: string;
  BaseDirectory: string;
  FilenameBase: string;
  Order: number;
  IsMiniMap: boolean;
  MiniMapId: number;

  Categories: Array<ICategory>;
  Hotspots: Array<IHotspot>;
  OptionCombinations: Array<IOptionCombination>;
  ViewGroupsAsSource: Array<IViewGroup>;
  ViewSettings: ViewSettings;
  Settings: IAdvancedViewSettings;
  KeepLookAtViews: Array<number>;
  MiniMapSettings: MiniMapSettings;
}

export class View implements IView {
  ViewId: number;
  TourId: number;
  Name: string;
  BaseDirectory: string;
  FilenameBase: string;
  Order: number;
  IsMiniMap: boolean;
  MiniMapId: number;

  Categories = new Array<Category>();
  Hotspots = new Array<Hotspot>();
  OptionCombinations = new Array<OptionCombination>();
  ViewGroupsAsSource = new Array<IViewGroup>();
  ViewSettings: ViewSettings;
  KeepLookAtViews: Array<number>;
  MiniMapSettings: MiniMapSettings;
  Settings: IAdvancedViewSettings;

  // VM for MiniMap,
  currentOptionCombination: OptionCombination;

  constructor(iView?: IView) {
    if (iView) {
      Object.assign(this, iView);

      if (this.IsMiniMap) {
        this.FilenameBase = this.FilenameBase ? this.FilenameBase.replace('pano/', '') : '';
        if (!this.MiniMapSettings) this.MiniMapSettings = new MiniMapSettings();
      }

      this.Categories = new Array<Category>();
      if (iView.Categories) {
        this.Categories = iView.Categories.map(
          (cat: ICategory, index: number) => new Category(cat)
        ).sort(sortByOrder);
      }

      this.Hotspots = new Array<Hotspot>();
      if (iView.Hotspots) {
        this.Hotspots = iView.Hotspots.map(hotspot => new Hotspot(hotspot));
      }

      if (iView.OptionCombinations) {
        this.OptionCombinations = iView.OptionCombinations.map(
          combo => new OptionCombination(combo)
        );
      }

      this.KeepLookAtViews = iView.KeepLookAtViews;
      if (iView.MiniMapSettings)
        this.MiniMapSettings = this.formatMiniMapSettings(iView.MiniMapSettings);
    }
  }

  private formatMiniMapSettings(miniMapSettings: MiniMapSettings): MiniMapSettings {
    if (miniMapSettings && miniMapSettings.Position) {
      miniMapSettings.MiniMapPosition = miniMapSettings.Position.toLowerCase().replace(' ', '-');
    }

    return miniMapSettings;
  }

  isInDefaultState(): boolean {
    let defaultOptionCombo = this.OptionCombinations.find(oc => oc.IsDefault);

    if (!defaultOptionCombo) {
      defaultOptionCombo = new OptionCombination();
      defaultOptionCombo.OptionIds = this.Categories.map(cat =>
        cat.Options.length > 0 ? cat.Options[0].OptionId : null
      ).filter(x => x);
    }

    const selectedOptions = this.Categories.flatMap(cat => cat.Options).filter(
      opt => opt.IsSelected
    );

    if (selectedOptions.length !== defaultOptionCombo.OptionIds.length) {
      return false;
    }

    for (const selectedOption of selectedOptions) {
      if (defaultOptionCombo.OptionIds.includes(selectedOption.OptionId) === false) {
        return false;
      }
    }

    return true;
  }

  resetToDefault() {
    const defaultOptionCombo = this.OptionCombinations.find(oc => oc.IsDefault);
    const options = this.Categories.flatMap(cat => cat.Options);

    if (defaultOptionCombo) {
      for (const option of options) {
        option.IsSelected = defaultOptionCombo.OptionIds.includes(option.OptionId);
      }
    } else {
      this.selectFirstOptionInEachCategory();
    }
  }

  private selectFirstOptionInEachCategory() {
    const options = this.Categories.flatMap(cat => cat.Options);
    for (const option of options) {
      option.IsSelected = false;
    }

    for (const category of this.Categories) {
      if (category.Options.length > 0) {
        category.Options[0].IsSelected = true;
      }
    }
  }

  toggleOptionsByOptionCombinationId(optComboId: number) {
    const optCombo = this.OptionCombinations.find(oc => oc.OptionCombinationId === optComboId);
    if (!optCombo) return;

    this.currentOptionCombination = optCombo;
    for (const cat of this.Categories) {
      for (const option of cat.Options) {
        option.IsSelected = optCombo.OptionIds.includes(option.OptionId);
      }
    }
  }

  getOptComboByIdOrDefault(optComboId: number): OptionCombination {
    if (!this.OptionCombinations || this.OptionCombinations.length < 1) return null;

    let combo = this.getOptionComboById(optComboId);
    if (combo) return combo;

    combo = this.OptionCombinations.find(oc => oc.IsDefault);
    if (combo) return combo;

    const optionIds = this.Categories.map(cat =>
      cat.Options.length > 0 ? cat.Options[0].OptionId : null
    ).filter(x => x);

    return this.getOptionCombinationByOptionIds(optionIds);
  }

  getOptionCombinationByOptionIds(optionIds: Array<number>): OptionCombination {
    if (!optionIds) return null;

    let optionCombinations = this.OptionCombinations;
    for (const optionId of optionIds) {
      optionCombinations = optionCombinations.filter(optCombo =>
        optCombo.OptionIds.some(id => id === optionId)
      );
    }

    return optionCombinations.length > 0 ? optionCombinations[0] : null;
  }

  getOptionComboById(optComboId: number): OptionCombination {
    return this.OptionCombinations.find(c => c.OptionCombinationId === optComboId);
  }

  findOptionCombinationByToggledOnOptions(): OptionCombination {
    const optionIds = this.Categories.map(cat => cat.Options.find(opt => opt.IsSelected))
      .filter(x => x)
      .map(opt => opt.OptionId);

    if (optionIds.length === 0) return null;

    const optionCombo = this.OptionCombinations.filter(optCombo =>
      optionIds.every(id => optCombo.OptionIds.includes(id))
    );

    return optionCombo && optionCombo.length > 0 ? optionCombo[0] : null;
  }

  getDefaultOptionCombination(): OptionCombination {
    if (!this.OptionCombinations || this.OptionCombinations.length < 1) return null;

    const defaultCombo = this.OptionCombinations.find(combo => combo.IsDefault);
    if (defaultCombo) {
      return defaultCombo;
    }

    return this.OptionCombinations[0];
  }
}

export class IAdvancedViewSettings {
  IsStaticImage = false;

  constructor(setting?: IAdvancedViewSettings) {
    if (setting) {
      Object.assign(this, setting);
    }
  }
}
