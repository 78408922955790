import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Option } from '../../../../entities/option';

@Component({
  selector: 'option-list-item',
  templateUrl: './option-list-item.component.html',
  styleUrls: ['./option-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionListItemComponent implements OnInit {
  @Input() option: Option;
  @Output() optionSelect = new EventEmitter<Option>();

  constructor() { }

  ngOnInit(): void {
  }

  handleOptionSelect() {
    this.optionSelect.emit(this.option);
  }
}
