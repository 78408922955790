<div class="tool-bar-bottom-right flex align-center" [class.left-menu-open]="controlsMenuOpen">
    <fullscreen *ngIf="showingFullScreenButton"></fullscreen>
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'bottom-right'">
    </minimap>
    <div *ngIf="zoomButtonsPosition === 'bottom-right'" class="pano-zoom-controls">
        <button (click)="zoomPano(panoZoomDirection.In)">+</button>
        <button (click)="zoomPano(panoZoomDirection.Out)">-</button>
    </div>
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'bottom-right'">
        <icon name="webvr"></icon>
    </button>
    <button *ngIf="disclaimerSettings.Position === 'bottom-right' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-bottom">
    <div class="flex align-center">
        <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'bottom'">
            <icon name="webvr"></icon>
        </button>
        <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
                 (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'bottom'">
        </minimap>
        <button *ngIf="disclaimerSettings.Position === 'bottom' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
                aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
            <icon name="circle_i" class="info"></icon>
        </button>
    </div>
    <div>
        <alert-banner *ngIf="disclaimerSettings.Enabled && disclaimerSettings.Type === 'Banner'" name="disclaimer_banner" class="disclaimer"
                      [allowClose]="false">
            {{disclaimerSettings.Text}}
        </alert-banner>
    </div>
</div>
<div class="tool-bar-bottom-left flex align-center" [class.left-menu-open]="controlsMenuOpen">
    <div *ngIf="zoomButtonsPosition === 'bottom-left'" class="pano-zoom-controls">
        <button (click)="zoomPano(panoZoomDirection.In)">+</button>
        <button (click)="zoomPano(panoZoomDirection.Out)">-</button>
    </div>
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'bottom-left'">
    </minimap>
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'bottom-left'">
        <icon name="webvr"></icon>
    </button>
    <button *ngIf="disclaimerSettings.Position === 'bottom-left' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-left flex align-center">
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'left'">
    </minimap>
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'left'">
        <icon name="webvr"></icon>
    </button>
    <button *ngIf="disclaimerSettings.Position === 'left' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="right">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-top-left flex align-center">
    <div *ngIf="zoomButtonsPosition === 'top-left'" class="pano-zoom-controls">
        <button (click)="zoomPano(panoZoomDirection.In)">+</button>
        <button (click)="zoomPano(panoZoomDirection.Out)">-</button>
    </div>
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'top-left'">
    </minimap>
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'top-left'">
        <icon name="webvr"></icon>
    </button>
    <button *ngIf="disclaimerSettings.Position === 'top-left' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-top flex align-center">
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'top'">
    </minimap>
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'top'">
        <icon name="webvr"></icon>
    </button>
    <button *ngIf="disclaimerSettings.Position === 'top' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-top-right flex align-center" [class.left-menu-open]="controlsMenuOpen">
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'top-right'">
        <icon name="webvr"></icon>
    </button>
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'top-right'">
    </minimap>
    <div *ngIf="zoomButtonsPosition === 'top-right'" class="pano-zoom-controls">
        <button (click)="zoomPano(panoZoomDirection.In)">+</button>
        <button (click)="zoomPano(panoZoomDirection.Out)">-</button>
    </div>
    <button *ngIf="disclaimerSettings.Position === 'top-right' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>
<div class="tool-bar-right flex align-center" [class.left-menu-open]="controlsMenuOpen">
    <button (click)="toggleWebVR()" *ngIf="webVRPosition === 'right'">
        <icon name="webvr"></icon>
    </button>
    <minimap class="mini-map" [pano]="pano" [currentView]="view" [tour]="tour" (hotspotSelect)="onHotspotSelect($event)"
             (miniMapUpdate)="onMiniMapUpdate($event)" *ngIf="miniMapPosition === 'right'">
    </minimap>
    <button *ngIf="disclaimerSettings.Position === 'right' && disclaimerSettings.Type === 'Tooltip'" mat-icon-button color="white"
            aria-label="Disclaimer text" #tooltip="matTooltip" [matTooltip]="disclaimerSettings.Text" matTooltipPosition="above">
        <icon name="circle_i" class="info"></icon>
    </button>
</div>