<div *ngIf="showHotspotDebugMenu" class="hotspot-debug-menu">
    <h3>Advanced Hotspot Menu</h3>
    <p>Select a hotspot to view details. Then you can update values and see the changes in real time.</p>

    <mat-select placeholder="All Hotspots" (selectionChange)="handleSelectChange($event)">
        <mat-option *ngFor="let hotspot of allHotspots" [value]="hotspot">{{hotspot.Name}}</mat-option>
    </mat-select>

    <em *ngIf="hotspot && !krPanoHotspot">Unable to find this hotspot in KrPano</em>

    <div *ngIf="hotspot" class="info-container">
        <p><b>Name:</b> {{hotspot.Name}}</p>
        <p><b>Id:</b> {{hotspot.HotspotId}}</p>
        <button mat-raised-button (click)="toggleOtherHotspotVisibility()">Toggle other hotspots visibility</button>
    </div>

    <div *ngIf="krPanoHotspot" class="form-container">
        <mat-form-field>
            <mat-label>Width</mat-label>
            <input matInput step=".25" name="Width" type="number" [(ngModel)]="krPanoHotspot.width" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Height</mat-label>
            <input matInput step=".25" name="Height" type="number" [(ngModel)]="krPanoHotspot.height" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>ATH</mat-label>
            <input matInput step=".1" name="ATH" type="number" [(ngModel)]="krPanoHotspot.ath" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>ATV</mat-label>
            <input matInput step=".1" name="ATV" type="number" [(ngModel)]="krPanoHotspot.atv" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rx</mat-label>
            <input matInput step=".1" name="Rx" type="number" [(ngModel)]="krPanoHotspot.rx" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Ry</mat-label>
            <input matInput step=".1" name="Ry" type="number" [(ngModel)]="krPanoHotspot.ry" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rz</mat-label>
            <input matInput step=".1" name="Rz" type="number" [(ngModel)]="krPanoHotspot.rz" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Image Url</mat-label>
            <input matInput name="Image Url" type="text" [(ngModel)]="imageUrl" (ngModelChange)="handleImageUrlChange()" />
        </mat-form-field>
    </div>
</div>