<h2 mat-dialog-title>Share</h2>
<form #shareForm="ngForm" (ngSubmit)="handleFormSubmit()">
    <mat-dialog-content class="content">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required name="name" [(ngModel)]="shareData.Name" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput required email name="email" [(ngModel)]="shareData.EmailAddress" />
            <mat-error *ngIf="shareForm.controls['email']?.hasError('email')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Notes</mat-label>
            <textarea matInput name="notes" rows="3" [(ngModel)]="shareData.Notes"></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close>Cancel</button>
        <button type="submit" mat-button color="primary" mat-dialog-close [disabled]="!shareForm.form.valid">Share</button>
    </mat-dialog-actions>
</form>