export class MouseSettings {
  Standard = 'default';
  Dragging = 'move';
  Moving = 'move';
  Url = '';
  Move = '';
  Drag = '';

  constructor(settings?: MouseSettings) {
    if (settings) Object.assign(this, settings);
  }
}

export class IconUnderMouseSettings {
  Enabled = false;
  Url = '';
  Height = 60;
  Width = 60;
  MouseScaleFadeStart = 0;
  MouseScaleFadeEnd = 0;

  constructor(settings?: IconUnderMouseSettings) {
    if (settings) {
      Object.assign(this, settings);
    }
  }
}
