import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewEncapsulation
} from '@angular/core';

import { IconLibrary } from './icon-library';

@Component({
  selector: 'icon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input() name: string;
  @Input() svg = '';
  svgToInject = '';

  iconLibrary: Record<string, string> = IconLibrary;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    if (this.svg) {
      this.svgToInject = this.svg;
    } else {
      this.svgToInject = this.iconLibrary[this.name];
    }

    if (this.svgToInject) {
      this.elementRef.nativeElement.innerHTML = this.svgToInject;
    } else {
      console.error(`Error: Unable to find an icon named "${this.name}"`);
    }
  }
}
