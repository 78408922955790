<div class="modal-outside-bounds" (click)="handleClose()"></div>
<div class="modal-container {{content.Size}} {{content.Position}} {{content?.VWSize}} {{content.Height}}"
     [class.with-text]="!!content.Content[currentGalleryIndex]" [class.without-text]="!content.Content[currentGalleryIndex]"
     [class.isVR]="content.IsVRModal" [class.iframed]="content.IframeUrl" [class.is-image]="isImage" #modal>
    <div class="modal-header" [class.no-title]="!htmlTitleContent" *ngIf="hasHtmlTitle || content.Media.length > 0">
        <h1 class="title" *ngIf="htmlTitleContent" [innerHTML]="htmlTitleContent"></h1>
        <div class="right-header-content">
            <div *ngIf="content.Media.length > 1" class="gallery-pager">
                {{currentGalleryIndex + 1}} / {{content.Media.length}}
            </div>
        </div>
    </div>
    <button class="close-modal-button" (click)="handleClose()">
        <icon name="close"></icon>
    </button>
    <div class="modal-content" [class.iframed]="content.IframeUrl">
        <iframe #iframeElement *ngIf="content.IframeUrl" class="{{content.MinHeightSize}}" [src]="content.IframeUrl | safeUrl" class=""></iframe>
        <div *ngIf="content.Media.length" class="orientation-container {{orientation}}" [class.with-text]="!!content.Content[currentGalleryIndex]">
            <div class="images-container" gallerySwiper [index]="currentGalleryIndex" [slideCount]="content.Media.length"
                 (indexChange)="setGalleryIndex($event)">
                <ng-container *ngFor="let med of content.Media">
                    <img *ngIf="med.IsImage" [src]="med.FullUrl | safeUrl" />
                    <video *ngIf="med.IsVideo" [src]="med.FullUrl | safeUrl" autoplay controls></video>
                </ng-container>
            </div>
            <div *ngIf="!!content.Content[currentGalleryIndex]" class="text-content" [innerHTML]="htmlContent">
            </div>
        </div>
        <ng-container *ngIf="content.Media.length > 1">
            <button class="left-arrow" mat-button (click)="setGalleryIndex(currentGalleryIndex - 1)">
                <icon name="carrot_arrow"></icon>
            </button>
            <button class="right-arrow" mat-button (click)="setGalleryIndex(currentGalleryIndex + 1)">
                <icon name="carrot_arrow"></icon>
            </button>
        </ng-container>
    </div>
    <div *ngIf="content.Name" class="modal-footer">
        <p>{{content.Name}}</p>
    </div>
</div>