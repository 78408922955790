import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Favorite } from '../../entities/favorite';
import { FavoriteService } from '../../entities/favorite.service';
import { Elevation, FloorPlan } from '../../entities/floorplan';

@Component({
  selector: 'favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FavoritesComponent implements OnInit {
  @Input() showing = false;
  favorite: Favorite;

  Name = 'Astor II';
  cost = 'From the $200,000\'s';
  sqft = '2,108 Square Feet';
  bedrooms = '2 Bedrooms';
  garageCount = '1 Car Garage';
  bathrooms = '3 Bathrooms';
  floorCount = '2 Floor';

  mockFloorplan: FloorPlan;

  svgUrl = 'https://argo.ml3ds-rt-stage.com/productdata/Lennar_2/Summers%20Corner_45043/Row%20Series%20at%20Azalea%20Ridge_47181/Calhoun%20Slab_276289/First%20Floor_357797.svg';

  constructor(private sanitizer: DomSanitizer, private favoriteService: FavoriteService) { }

  ngOnInit() {
    this.favoriteService.current$.subscribe(fav => (this.favorite = fav));
    this.mockFloorplan = this.mockFavoriteFPs();
  }

  showHomeMenu() {
    this.showing = false;
  }

  previousElevation(fp: FloorPlan) {
    --fp.mediaIndex;

    if (fp.mediaIndex < 0) fp.mediaIndex = 0;
  }

  nextElevation(fp: FloorPlan) {
    ++fp.mediaIndex;

    if (fp.mediaIndex >= fp.elevations.length) fp.mediaIndex = fp.elevations.length - 1;
  }

  mockFavoriteFPs(): FloorPlan {
    const floorplanA = new FloorPlan();

    floorplanA.Name = 'Astor II';
    floorplanA.cost = 'From the $200,000\'s';
    floorplanA.sqft = '2,108 Square Feet';
    floorplanA.bedrooms = '2 Bedrooms';
    floorplanA.garageCount = '1 Car Garage';
    floorplanA.bathrooms = '3 Bathrooms';
    floorplanA.floorCount = '2 Floor';
    floorplanA.Url = 'https://myscp.ml3ds-icon.com/scp/55268/floorplan/279793?standalone=true';
    floorplanA.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(floorplanA.Url);

    const elevationA = new Elevation();
    elevationA.Name = 'Elevation A';
    elevationA.Uri =
      'https://medialabazure.blob.core.windows.net/productdata/Lennar_2/Venue%20at%20Monroe_55268/Carriage%20Homes_57561/Astor%20II_279793/thumbs/lg/Astor%20II-Beacon%20II-Duplex-elev.jpg';

    floorplanA.elevations.push(elevationA);

    const elevationB = new Elevation();
    elevationB.Name = 'Elevation B';
    elevationB.Uri =
      'https://medialabazure.blob.core.windows.net/productdata/Lennar_2/Venue%20at%20Monroe_55268/Carriage%20Homes_57561/Beacon%20II_279794/thumbs/lg/Astor%20II-Beacon%20II-Duplex-elev.jpg';

    floorplanA.elevations.push(elevationB);

    return floorplanA;
  }
}
