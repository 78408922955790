import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'reset-confirm-dialog',
  templateUrl: './reset-confirm-dialog.component.html',
  styleUrls: ['./reset-confirm-dialog.component.scss']
})
export class ResetConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
