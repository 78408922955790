import { MiniMap } from './minimap';
import { IView, View } from './view';

import { sortByOrder } from '@ml/common';

export interface ITour {
  TourId: number;
  Name: string;
  Order: number;
  PanoId: number;
  IsBaseCommunity: boolean;
  IsModelRow: boolean;

  Views: Array<IView>;
  MiniMaps: MiniMap[];
}

export class Tour implements ITour {
  TourId: number;
  Name: string;
  Order: number;
  PanoId: number;
  IsBaseCommunity: boolean;
  IsModelRow: boolean;

  MiniMaps: MiniMap[] = [];
  Views = new Array<View>();

  private DefaultViewIndex = 0;
  constructor(iTour?: ITour) {
    if (iTour) {
      Object.assign(this, iTour);

      if (iTour.Views) {
        this.MiniMaps = iTour.MiniMaps.map(mm => new MiniMap(mm));
        this.Views = iTour.Views.filter(view => !view.IsMiniMap)
          .map(view => new View(view))
          .sort(sortByOrder);
      }
    }
  }

  getDefaultView(): View {
    return this.Views ? this.Views[this.DefaultViewIndex] : null;
  }

  getViewByIdOrDefault(viewId: number): View {
    return viewId > 0 ? this.Views.find(t => t.ViewId === viewId) : this.getDefaultView();
  }
}
