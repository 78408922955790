import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { get as getScript } from 'scriptjs';

import { environment } from '../../environments/environment';
import { PostMessageEvent } from '../pano-root/pano-root.component';
import { XYMouseTouch } from '../shared/mouse-events.directive';
import { distance, Point } from '../utility/helpers/helpers';
import { Lookup } from '../utility/helpers/lookup';
import { ActionType, Hotspot } from './hotspot';
import { IKRElement, IKRHotspot, IKrPano, IKrPanoVideo } from './krpano/krpano';
import { KrPanoHotspot, KrPanoViewPosition } from './krpano/krpano-data';
import { WebVR } from './krpano/webvr';
import { Pano, PanoZoomDirection } from './pano';
import { PanoBlend } from './pano-blend';
import { IconUnderMouseSettings } from './settings/mouse-settings';
import { SoundSettings } from './settings/pano-settings';
import { View } from './view';

declare const embedpano: any;

@Injectable({
  providedIn: 'root'
})
export class KRPanoService {
  krpano: IKrPano;
  gyroEnabled = false;
  webVR$ = new BehaviorSubject<WebVR>(null);
  muted = false;
  videoFromLastView: Video;

  constructor(private lookup: Lookup, private snackBar: MatSnackBar) {}

  loadPanoResources(pano: Pano): Promise<void> {
    return new Promise((resolve, reject) => {
      getScript('/assets/tour.js', () => {
        const basePath = this.lookup.getBasePanoPath(pano);
        const tourXml = environment.isOffline
          ? 'panoresources/tour.xml'
          : basePath + 'tour.xml?date=' + Date.now();

        embedpano({
          xml: tourXml,
          target: 'pano',
          html5: 'only',
          mobilescale: 1.0,
          passQueryParameters: true,
          basepath: basePath,
          // this is off by default because of performance but is required to be able
          // to get a screenshot of the canvas with toDataURL() for the pdf
          // more info: https://krpano.com/docu/html/#webglsettings
          webglsettings: { preserveDrawingBuffer: pano.Settings.EnableDownloadImage },
          onready: krpano => {
            this.krpano = krpano;
            this.playBackgroundSound(pano.Settings.SoundSettings);
            resolve();
          },
          consolelog: !environment.production,
          onerror: (errorMessage: string) => {
            reject(errorMessage);
          },
          mwheel: !pano.Settings.DisableMouseWheelZoom
        });
      });
    });
  }

  zoomPano(direction: PanoZoomDirection, increment: number) {
    const currentFov = +this.krpano.get('view.fov');
    if (direction === PanoZoomDirection.In) {
      if (currentFov - increment > 0) this.krpano.call(`set(view.fov, ${currentFov - increment})`);
    }
    if (direction === PanoZoomDirection.Out)
      if (currentFov - increment < 180)
        this.krpano.call(`set(view.fov, ${currentFov + increment})`);
  }

  playBackgroundSound(soundSettings: SoundSettings) {
    if (soundSettings?.Enabled) {
      this.krpano.call(`playsound(bgsound, ${soundSettings.Url}, ${soundSettings.Loop})`);
    }
  }

  private pauseVideoBeforeSwitchingScenes(): Video {
    const video = this.getCurrentVideo();
    if (video) {
      this.pauseVideo(video.id);
      return this.getCurrentVideo();
    }
  }

  loadSceneByName(sceneName: string, view: View, blend?: PanoBlend, keepAt?: boolean) {
    this.videoFromLastView = this.pauseVideoBeforeSwitchingScenes();

    if (sceneName) {
      let viewPosition = this.getCurrentViewPosition();

      if (this.onWebsiteLoading()) {
        const loadScene = `loadscene(${sceneName}, null, MERGE, NOBLEND))`;
        this.krpano.call(loadScene);
      } else if (
        !blend ||
        blend.Blend.toLowerCase() !== 'punchmarkzoomblend' ||
        this.onSameScene(sceneName)
      ) {
        const loadScene = `loadscene(${sceneName}, null, ${
          keepAt ? 'KEEPLOOKAT | MERGE' : 'MERGE'
        }, ${this.formatBlendTransition(blend)}))`;
        this.krpano.call(loadScene);

        if (!keepAt) {
          viewPosition = this.getBaseViewViewSettings(view);
        }

        this.setViewPosition(viewPosition);
      } else if (blend.Blend.toLowerCase() === 'punchmarkzoomblend') {
        if (!keepAt) {
          viewPosition = this.getBaseViewViewSettings(view);
        }

        this.modifiedZoom(sceneName, keepAt);

        if (!keepAt) {
          this.setViewPosition(viewPosition);
        }
      }
    } else {
      console.error(`Skipping loading scene by name. Scene name is invalid: ${sceneName}`);
    }

    setTimeout(() => {
      if (this.webVR$?.value?.enabled) {
        this.onEnterVR(view);
      } else {
        this.onExitVR(view);
      }
    }, 100);
  }

  private getBaseViewViewSettings(view: View) {
    const viewPosition = new KrPanoViewPosition();
    viewPosition.Horizontal = view.ViewSettings.HLookAt;
    viewPosition.Vertical = view.ViewSettings.VLookAt;
    viewPosition.FOV = view.ViewSettings.Fov;
    return viewPosition;
  }

  private onWebsiteLoading(): boolean {
    const currentScene = this.krpano.get('xml.scene');
    return !currentScene;
  }

  private onSameScene(sceneName: string): boolean {
    const currentScene = this.krpano.get('xml.scene');
    return currentScene === sceneName;
  }

  private modifiedZoom(sceneName: string, keepAt: boolean) {
    //
    // IMPORTANT This is Punchmark Lobby specific. Do not touch unless you know wtf you are doing.
    // GIFLENS-https://media0.giphy.com/media/l1J9wMccAykUCLIic/200.gif
    //

    // All parameters must be variables, using values is not possible!
    this.krpano.call('set(fov, get(view.fov));');
    this.krpano.call('set(hlookat, get(view.hlookat));');
    this.krpano.call('set(vlookat, get(view.vlookat));');

    const fov = parseInt(this.krpano.get('fov'), 10);
    const innerZoom = fov - 5;
    this.krpano.call(`set(innerZoom, calc(${innerZoom}));`);
    const outerZoom = fov + 20;
    this.krpano.call(`set(outerZoom, calc(${outerZoom}));`);

    this.krpano.call(`set(depth, calc(1000))`);
    this.krpano.call(`set(hs_linkedscene, ${sceneName})`);
    this.krpano.call(`set(sceneBlend, OPENBLEND(1.5, 0.0, 0.75, 0.05, linear));`);
    this.krpano.call(`spheretospace(get(view.hlookat),get(view.vlookat),get(depth), tx,ty,tz);`);
    this.krpano.call('set(s, -0.5);');

    let zoomAnimation = ` image.ox|image.oy|image.oz|caller.alpha, calc((s*tx)+'|'+(s*ty)+'|'+(s*tz)+'|0'), 0.5, default,
                          skin_loadscene(get(hs_linkedscene), get(sceneBlend));
                          set(view.oz, 500);
                          tween(view.oz, 0);`;
    if (keepAt) {
      zoomAnimation += `
        set(view.fov, get(fov));
        set(view.hlookat, get(hlookat));
        set(view.vlookat, get(vlookat));
      `;
    }

    this.krpano.call(`tween(${zoomAnimation});`);
  }

  private formatBlendTransition(blend: PanoBlend): string {
    if (!blend) return `BLEND(0.1, easeOutCubic)`;
    return blend.toString();
  }

  getCurrentViewPosition(): KrPanoViewPosition {
    const horizontal = this.krpano.get('view.hlookat');
    const vertical = this.krpano.get('view.vlookat');
    const fov = this.krpano.get('view.fov');
    const viewPosition = new KrPanoViewPosition(horizontal, vertical, fov);

    return viewPosition;
  }

  private setViewPosition(position: KrPanoViewPosition) {
    let view = '';

    if (position.Horizontal) view += `set(view.hlookat, ${position.Horizontal}); `;
    if (position.Vertical) view += ` set(view.vlookat, ${position.Vertical}); `;
    if (position.FOV) view += ` set(view.fov, ${position.FOV}); `;

    if (view) this.krpano.call(view);
  }

  setMaxFoV() {
    this.krpano.call('set(view.fov, 120)');
  }

  setGyroscope(value: boolean) {
    this.gyroEnabled = value;
    this.krpano.call(`set(plugin[gyro].enabled, ${this.gyroEnabled});`);
  }

  toggleGyroscope(): boolean {
    this.gyroEnabled = !this.gyroEnabled;
    this.snackBar.open(
      `Gryoscope ${this.gyroEnabled ? 'enabled for applicable devices' : 'disabled'}`,
      '',
      { duration: 2500 }
    );
    this.krpano.call(`set(plugin[gyro].enabled, ${this.gyroEnabled});`);
    return this.gyroEnabled;
  }

  toggleWebVR(): boolean {
    if (this.webVR$.value.isavailable) {
      this.webVR$.value.enabled = !this.webVR$.value.enabled;

      if (this.webVR$.value.enabled) {
        this.webVR$.value.entervr();
      } else {
        this.webVR$.value.exitvr();
      }
    } else {
      this.webVR$.value.enabled = false;
    }

    return this.webVR$.value.enabled;
  }

  isWebVRAvailable(): boolean {
    if (!this.webVR$.value) {
      const webvr = new WebVR(this.krpano.get('plugin["webvr"]') || { enabled: false });
      this.webVR$.next(webvr);
    }

    return this.webVR$?.value?.isavailable;
  }

  setWebVRAvailable(val: boolean) {
    if (!this.webVR$.value) {
      const webvr = new WebVR(this.krpano.get('plugin["webvr"]') || { enabled: false });
      this.webVR$.next(webvr);
    }

    this.webVR$.value.isavailable = val;
  }

  tryToAutoPlayVideo(id: number, event: PostMessageEvent, mouseEvent: XYMouseTouch): IKrPanoVideo {
    let video: IKrPanoVideo;

    if (event) {
      if (this.hotspotMatchesPostMessageEvent(+id, event)) {
        if (event.Video.Playing) {
          video = this.playVideo(event.Video.Id, event.Video.Time);
          this.hideAllVideoControls(id);
        } else {
          video = this.pauseVideo(event.Video.Id, event.Video.Time);
        }
      }
    }

    if (!video) {
      if (this.videoFromLastView) {
        video = this.restartCurrentVideo(id);
      } else {
        video = this.playVideo(id, 0, mouseEvent);
        this.hideAllVideoControls(id);
      }
    }

    return video;
  }

  hideAllVideoControls(id: number) {
    this.hideMuteButton(id);
    this.hideUnMuteButton(id);
    this.hidePlayButton(id);
    this.hidePauseButton(id);
  }

  private hotspotMatchesPostMessageEvent(hotspotId: number, event: PostMessageEvent): boolean {
    if (!event || !event.Video) {
      return false;
    }

    return hotspotId === +event.Video.Id;
  }

  getCurrentVideo(): Video {
    const video = this.hotspotsInView()?.find(hs => hs.actiontype === 'video');

    if (!video) {
      return null;
    }

    const result = new Video();
    result.url = video.videourl;
    result.time = video.time;
    result.isPlaying = !video.ispaused;

    const matchCollection = video.name?.toLowerCase().match(/hotspot_(\d+)/);
    if (matchCollection.length >= 2)
      result.id = +video.name?.toLowerCase().match(/hotspot_(\d+)/)[1];

    return result;
  }

  private restartCurrentVideo(hotspotId: number): IKrPanoVideo {
    const currentVideo = this.getVideo(hotspotId);

    if (!currentVideo) {
      return null;
    }

    currentVideo.muted = this.muted;
    this.hideAllVideoControls(hotspotId);

    if (this.videoFromLastView) {
      if (this.previousAndCurrentVideoUrlsMatch(currentVideo, this.videoFromLastView)) {
        currentVideo.seek(this.videoFromLastView.time);

        if (this.videoFromLastView.isPlaying) {
          currentVideo.play();
          currentVideo.ispaused = false;
        } else {
          currentVideo.ispaused = true;
          currentVideo.pause();
          this.showPlayButton(hotspotId);

          if (this.muted) {
            this.showMuteButton(hotspotId);
          } else {
            this.showUnMuteButton(hotspotId);
          }
        }
      }
    } else {
      currentVideo.seek(0);
      currentVideo.play();
    }

    currentVideo.muted = this.muted;

    return currentVideo;
  }

  private previousAndCurrentVideoUrlsMatch(
    currentVideo: IKrPanoVideo,
    previousVideo: Video
  ): boolean {
    return (
      currentVideo &&
      currentVideo.videourl &&
      previousVideo &&
      previousVideo.url &&
      currentVideo.videourl.toLowerCase().trim() === previousVideo.url.toLowerCase().trim()
    );
  }

  togglePauseVideo(id: number, xYMouseTouch?: XYMouseTouch): IKrPanoVideo {
    const video = this.getVideo(id);
    if (!video) {
      return null;
    }

    video.muted = this.muted;
    video.togglepause();

    const isPaused = video.ispaused && video.time !== 0;

    if (isPaused) {
      this.hidePauseButton(id);
      this.showPlayButton(id);
    } else {
      this.hidePlayButton(id);
      this.hidePauseButton(id);
      if (xYMouseTouch && !xYMouseTouch.isTouchDevice) {
        this.showPauseButton(id);
        this.setMuteIcon(id, xYMouseTouch, this.muted);
      }
    }

    return video;
  }

  setMute(mute: boolean) {
    this.muted = mute;
  }

  getMute(): boolean {
    return this.muted;
  }

  playVideo(id: number, time: number = 0, xYMouseTouch: XYMouseTouch = null): IKrPanoVideo {
    const video = this.getVideo(id);
    if (!video) return null;
    video.muted = this.muted;

    if (time > 0) {
      video.seek(time);
    }

    video.play();

    this.hidePlayButton(id);
    this.hidePauseButton(id);

    if (xYMouseTouch && xYMouseTouch.isTouchDevice === false) {
      this.showPauseButton(id);
      this.setMuteIcon(id, xYMouseTouch, this.muted);
    }

    return video;
  }

  pauseVideo(id: number, time: number = 0, xYMouseTouch: XYMouseTouch = null): IKrPanoVideo {
    const video = this.getVideo(id);
    if (!video) return null;
    video.muted = this.muted;

    if (time > 0) {
      video.seek(time);
      video.play();
    }

    video.pause();

    this.showPlayButton(id);
    this.hidePauseButton(id);
    this.setMuteIcon(id, xYMouseTouch, this.muted);
    return video;
  }

  toggleMute(id: number, xYMouseTouch?: XYMouseTouch) {
    const video = this.getVideo(id);

    this.muted = !this.muted;
    video.muted = this.muted;
    this.setMuteIcon(id, xYMouseTouch, this.muted);
  }

  muteVideoByHotspotId(mute: boolean, id: number) {
    const video = this.getVideo(id);
    if (!video) return;

    this.muted = mute;
    video.muted = this.muted;

    if (this.muted) {
      this.hideUnMuteButton(id);
      this.showMuteButton(id);
    } else {
      this.showUnMuteButton(id);
      this.hideMuteButton(id);
    }
  }

  private setMuteIcon(id: number, xYMouseTouch?: XYMouseTouch, isMute: boolean = false) {
    if (xYMouseTouch) {
      if (isMute) {
        this.hideUnMuteButton(id);
        this.showMuteButton(id);
      } else {
        this.showUnMuteButton(id);
        this.hideMuteButton(id);
      }
    } else {
      this.hideMuteButton(id);
      this.hideUnMuteButton(id);
    }
  }

  onMouseOver(hotspot: Hotspot, xYMouseTouch?: XYMouseTouch) {
    if (
      hotspot &&
      hotspot.ActionType === ActionType.VIDEO &&
      xYMouseTouch &&
      !xYMouseTouch.isTouchDevice
    ) {
      const video = this.getVideo(hotspot.HotspotId);
      if (!video.ispaused) {
        this.showPauseButton(hotspot.HotspotId);
      }

      if (video.muted) {
        this.showMuteButton(hotspot.HotspotId);
        this.hideUnMuteButton(hotspot.HotspotId);
      } else {
        this.hideMuteButton(hotspot.HotspotId);
        this.showUnMuteButton(hotspot.HotspotId);
      }
    }
  }

  onMouseOut(id: number) {
    const video = this.getVideo(id);
    if (video.ispaused) {
      this.showPlayButton(id);
    } else {
      this.hidePlayButton(id);
    }

    this.hidePauseButton(id);
    this.hideMuteButton(id);
    this.hideUnMuteButton(id);
  }

  showPlayButton(id: number) {
    const playButton = this.getPlayButton(id);
    if (playButton) {
      playButton.alpha = '.5';
    }
  }

  hidePlayButton(id: number) {
    const playButton = this.getPlayButton(id);
    if (playButton) {
      playButton.alpha = '0';
    }
  }

  showPauseButton(id: number) {
    const pauseButton = this.getPauseButton(id);
    if (pauseButton) {
      pauseButton.alpha = '.5';
    }
  }

  hidePauseButton(id: number) {
    const pauseButton = this.getPauseButton(id);
    if (pauseButton) {
      pauseButton.alpha = '0';
    }
  }

  showMuteButton(id: number) {
    const muteButton = this.getMuteButton(id);
    if (muteButton) {
      muteButton.alpha = '.5';
    }
  }

  hideMuteButton(id: number) {
    const muteButton = this.getMuteButton(id);
    if (muteButton) {
      muteButton.alpha = '0';
    }
  }

  showUnMuteButton(id: number) {
    const unMuteButton = this.getUnMuteButton(id);
    if (unMuteButton) {
      unMuteButton.alpha = '.5';
    }
  }

  hideUnMuteButton(id: number) {
    const unMuteButton = this.getUnMuteButton(id);
    if (unMuteButton) {
      unMuteButton.alpha = '0';
    }
  }

  getVideo(hotspotId: number): IKrPanoVideo {
    let video = this.krpano.get(`hotspot['Hotspot_${hotspotId}']`);
    if (!video) {
      video = this.krpano.get(`hotspot['Hotspot_${hotspotId}_DynamicHotspotId_-1']`);
    }

    return video;
  }

  private getPlayButton(hotspotId: number): IKRElement {
    let playButton = this.krpano.get(`hotspot['Hotspot_${hotspotId}_play']`);
    if (!playButton)
      playButton = this.krpano.get(`hotspot['Hotspot_${hotspotId}_DynamicHotspotId_-1_play']`);

    return playButton;
  }

  private getPauseButton(hotspotId: number): IKRElement {
    let pauseButton: IKRElement = this.krpano.get(`hotspot['Hotspot_${hotspotId}_pause']`);
    if (!pauseButton)
      pauseButton = this.krpano.get(`hotspot['Hotspot_${hotspotId}_DynamicHotspotId_-1_pause']`);

    return pauseButton;
  }

  private getMuteButton(hotspotId: number): IKRElement {
    let muteButton = this.krpano.get(`layer['Hotspot_${hotspotId}_mute']`);
    if (!muteButton)
      muteButton = this.krpano.get(`layer['Hotspot_${hotspotId}_DynamicHotspotId_-1_mute']`);

    return muteButton;
  }

  private getUnMuteButton(hotspotId: number): IKRElement {
    let unMuteButton = this.krpano.get(`layer['Hotspot_${hotspotId}_unmute']`);
    if (!unMuteButton)
      unMuteButton = this.krpano.get(`layer['Hotspot_${hotspotId}_DynamicHotspotId_-1_unmute']`);

    return unMuteButton;
  }

  showHotspotById(hotspotId: number) {
    const hotspot: IKRHotspot = this.krpano.get(`hotspot['Hotspot_${hotspotId}']`);
    if (hotspot) {
      hotspot.alpha = '1';
      hotspot.enabled = true;
    }
  }

  hideHotspotById(hotspotId: number) {
    const hotspot: IKRHotspot = this.krpano.get(`hotspot['Hotspot_${hotspotId}']`);
    if (hotspot) {
      hotspot.alpha = '0';
      hotspot.enabled = false;
    }
  }

  findHotspotElement(hotspot: Hotspot): HTMLElement {
    const hotspotObj = this.getHotspotObject(hotspot.HotspotId);
    return hotspotObj?.sprite;
  }

  getHotspotObject(hotspotId: number): KrPanoHotspot {
    let hotspot = this.krpano.get(`hotspot['Hotspot_${hotspotId}']`);

    if (!hotspot) {
      const hotspots = this.krpano.get('hotspot').getArray();
      hotspot = hotspots.find(hs => hs.name.indexOf(`dynamichotspotid_${hotspotId}`) > -1);
    }

    return hotspot;
  }

  getIconUnderMouseElement(): HTMLElement {
    const krHotspot = this.krpano.get(`hotspot['iconundermouse']`);
    if (krHotspot) return krHotspot.sprite;
    return null;
  }

  hotspotsInView(): Array<KrPanoHotspot> {
    return this.krpano.get('hotspot').getArray();
  }

  layersInView(): Array<KrPanoHotspot> {
    return this.krpano.get('layer').getArray();
  }

  removeHotspotFromView(name: string) {
    this.krpano.call(`removehotspot("${name}")`);
  }

  removeLayerFromView(name: string) {
    this.krpano.call(`removelayer("${name}")`);
  }

  onEnterVR(view: View) {
    const krpanoHotspots = this.hotspotsInView();
    for (const krHotspot of krpanoHotspots) {
      const match = krHotspot.name.match(/hotspot_(\d+)$/);
      const id = match?.length > 1 ? parseInt(match[1], 10) : 0;
      if (krHotspot.actiontype !== 'video' && id > 0) {
        let hotspot = view.Hotspots.find(hs => hs.HotspotId === id);
        if (!hotspot) {
          hotspot = view.OptionCombinations.flatMap(oc => oc.Hotspots).find(
            hs => hs.HotspotId === id
          );
        }

        if (hotspot) {
          if (krHotspot.height && krHotspot.height.toString() !== 'prop') {
            krHotspot.height = parseInt(krHotspot.height.toString(), 10) * 0.2;
          }

          if (krHotspot.width && krHotspot.width.toString() !== 'prop') {
            krHotspot.width = parseInt(krHotspot.width.toString(), 10) * 0.2;
          }
        }
      }
    }
  }

  onExitVR(view: View) {
    const krpanoHotspots = this.hotspotsInView();
    for (const krHotspot of krpanoHotspots) {
      const match = krHotspot.name.match(/hotspot_(\d+)$/);
      const id = match?.length > 1 ? parseInt(match[1], 10) : 0;
      if (krHotspot.actiontype !== 'video' && id > 0) {
        let hotspot = view.Hotspots.find(hs => hs.HotspotId === id);
        if (!hotspot) {
          hotspot = view.OptionCombinations.flatMap(oc => oc.Hotspots).find(
            hs => hs.HotspotId === id
          );
        }

        if (hotspot) {
          krHotspot.height = hotspot.Height;
          krHotspot.width = hotspot.Width;
        }
      }
    }
  }

  startAutoRotate() {
    this.krpano.call('autorotate.start()');
  }

  findClosestHotspot(coordinates: Point) {
    let hotspots: Array<KrPanoHotspot> = this.krpano.get('hotspot').getArray();
    hotspots = hotspots.filter(x => x.onclick);

    let shortestDistance;
    let shortestDistancedHotspot;

    this.krpano.call(`screentosphere(mouse.stagex, mouse.stagey, gx, gy)`);
    const mousePoint = new Point(this.krpano.get('gx'), this.krpano.get('gy'));

    for (const hotspot of hotspots) {
      if (hotspot.name.toLowerCase() !== 'iconundermouse') {
        const dist = distance(mousePoint, new Point(hotspot.ath, hotspot.atv));
        if (!shortestDistance || dist < shortestDistance) {
          shortestDistance = dist;
          shortestDistancedHotspot = hotspot;
        }
      }
    }

    return shortestDistancedHotspot;
  }

  findHotspotIdFromHotspotElement(hotspotElement: any) {
    const idRegex = /\w*_(\d+)/;
    const result = idRegex.exec(hotspotElement.name)[1];
    return result ? parseInt(result, 10) : 0;
  }

  addHotspot(name: string) {
    this.krpano.call(`addhotspot(${name})`) as any;
    return this.hotspotsInView().find(hs => hs.name === name);
  }

  addLayer(name: string) {
    this.krpano.call(`addLayer(${name})`) as any;
    return this.layersInView().find(hs => hs.name === name);
  }

  removeHotspot(name: string) {
    this.krpano.call(`removehotspot(${name})`);
  }

  getMouseXY(): Point {
    return new Point(this.krpano.get('mouse.x'), this.krpano.get('mouse.y'));
  }

  updateIconUpdateMousePosition(iconSettings: IconUnderMouseSettings, xYMouse: XYMouseTouch) {
    if (this.krpano) {
      const iconUnderMouseName = 'IconUnderMouse';
      this.krpano.call(`set(mouseX, ${xYMouse.x})`);
      this.krpano.call(`set(mouseY, ${xYMouse.y})`);

      this.krpano.call(`screentosphere(mouseX, mouseY, gx, gy)`);
      const mousePoint = new Point(this.krpano.get('gx'), this.krpano.get('gy'));

      const x = mousePoint.X ? mousePoint.X : 0;
      const y = mousePoint.Y ? mousePoint.Y + 5 : 0;

      let scale = 1;

      if (this.mouseUnderIconScales(iconSettings)) {
        scale = this.calculateMouseUnderIconScale(iconSettings, y);
      }

      this.krpano.call(`set(hotspot['${iconUnderMouseName}'].ath, ${x || 0})`);
      this.krpano.call(`set(hotspot['${iconUnderMouseName}'].atv, ${y || 0})`);
      this.krpano.call(`set(hotspot['${iconUnderMouseName}'].scale, ${isNaN(scale) ? 1 : scale})`);
    }
  }

  private calculateMouseUnderIconScale(iconSettings: IconUnderMouseSettings, y: number): number {
    let scale = 1;
    const start = iconSettings.MouseScaleFadeStart;
    const end = iconSettings.MouseScaleFadeEnd;
    const isInverse = end > start;

    if (!isInverse) {
      if (y < end) {
        scale = 0;
      } else if (y <= start) {
        const range = start - end;
        const location = end - y;
        scale = Math.abs(location / range);
      }
    } else {
      if (y > end) {
        scale = 0;
      } else if (y >= start) {
        const range = start - end;
        const location = y - end;
        scale = Math.abs(location / range);
      }
    }

    return scale;
  }

  private mouseUnderIconScales(iconSettings: IconUnderMouseSettings): boolean {
    return (
      !isNaN(iconSettings.MouseScaleFadeStart) &&
      !isNaN(iconSettings.MouseScaleFadeEnd) &&
      iconSettings.MouseScaleFadeStart !== iconSettings.MouseScaleFadeEnd
    );
  }

  disableOnViewChange() {
    this.krpano.get('events').onviewchange = null;
  }
}

export class Video {
  isPlaying: boolean;
  time: number;
  url: string;
  id: number;
}
