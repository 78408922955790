import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { EmailRequest } from '../../entities/email-request';
import { Pano } from '../../entities/pano';
import { IPanoSelections, PanoService } from '../../entities/pano.service';
import { EmailService } from '../../services/email.service';
import { SettingsService } from '../../services/settings.service';
import { GoogleTagManagerService } from '../../utility/helpers/google-tag-manager';
import { ToastService } from '../toast/toast.service';

import { SettingsCategoryTitle } from '@ml/common';

@Component({
  selector: 'share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareDialogComponent implements OnInit {
  shareData = new ShareData();

  constructor(
    private emailService: EmailService,
    private toaster: ToastService,
    private panoService: PanoService,
    private settingsService: SettingsService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {}

  handleFormSubmit() {
    this.toaster.showLoading();
    const emailRequest = this.buildEmail(this.shareData);
    const shareToastBody = this.getShareToastBody();

    this.emailService.sendEmail(emailRequest).subscribe(
      () => {
        this.toaster.showSuccess('Success!', shareToastBody);
      },
      (error: HttpErrorResponse) => {
        console.error(`Error sending email: ${error.message}`);
        this.toaster.showStickyError(
          'Oh no...',
          'Something went wrong.\nPlease close this window and try again.'
        );
      }
    );
  }

  private getShareToastBody(): string {
    return this.settingsService.get(
      'ShareModalToastBodyMessage',
      SettingsCategoryTitle.PanoViewerTheme
    );
  }

  private buildEmail(data: ShareData) {
    const title = 'Your Pano Configuration';
    const selections = this.panoService.getSelections();
    this.gtmService.addEvent('Share', 'Share', 'Share', selections.viewId);

    const pano = this.panoService.pano;
    const message = this.buildEmailBody(
      data.Name,
      this.getShareLink(),
      data.Notes,
      pano,
      selections
    );
    return new EmailRequest(data.EmailAddress, pano.ClientId, title, message);
  }

  private buildEmailBody(
    userName: string,
    link: string,
    notes: string,
    pano: Pano,
    selections: IPanoSelections
  ) {
    const emailMessage = this.settingsService.get('ShareEmailBodyMessage') as string;
    if (emailMessage) {
      const tour = pano.Tours.find(x => x.TourId === selections.tourId);
      const view = tour?.Views.find(x => x.ViewId === selections.viewId);
      return emailMessage
        .replace(/{{panoName}}/gi, pano.Name)
        .replace(/{{tourName}}/gi, tour?.Name)
        .replace(/{{viewName}}/gi, view?.Name)
        .replace(/{{userName}}/gi, userName)
        .replace(/{{linkUrl}}/gi, link)
        .replace(/{{userNotes}}/gi, notes);
    }

    return `Hi ${userName},

Here's your pano: ${link}

${notes || ''}`;
  }

  private getShareLink() {
    const { panoId, tourId, viewId, optComboId } = this.panoService.getSelections();
    return `${window.location.origin}/pano/${panoId}/tour/${tourId}/view/${viewId}?optComboId=${optComboId}`;
  }
}

export class ShareData {
  Name: string;
  EmailAddress: string;
  Notes: string;
}
