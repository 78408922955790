import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorReasons } from './error-reasons.enum';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit {
  errorReason: ErrorReasons;
  errorReasons = ErrorReasons;
  attemptedRoute: string;
  attemptedOfflineCommunityId = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.errorReason = +this.route.snapshot.queryParams.reason;
    this.attemptedRoute = this.route.snapshot.queryParams.route;
  }
}
