import { Injectable } from '@angular/core';

import { Hotspot } from './hotspot';
import { OptionCombination } from './option-combination';
import { Tour } from './tour';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {
  constructor() {}

  getById(hotspotId: number, tour: Tour): Hotspot {
    const optionCombos = tour.Views.map(view => view.OptionCombinations).reduce(
      (next, curr) => [...curr, ...next],
      new Array<OptionCombination>()
    );

    return [
      ...this.flattenHotspots(tour.Views.map(view => view.Hotspots)),
      ...this.flattenHotspots(tour.MiniMaps.map(view => view.Hotspots)),
      ...this.flattenHotspots(optionCombos.map(optCombo => optCombo.Hotspots))
    ].find(hs => hs.HotspotId === hotspotId);
  }

  private flattenHotspots(hotspots: Array<Array<Hotspot>>): Array<Hotspot> {
    return hotspots.reduce((next, curr) => [...curr, ...next], new Array<Hotspot>());
  }
}
