<div *ngIf="errorMessage" class="error">
    <p>{{errorMessage}}</p>
</div>
<div *ngIf="!errorMessage" class="page main">
    <pano-loading-overlay *ngIf="!hasPanoLoaded" [panoName]="pano.Name" @enterLeaveFadeAnimation></pano-loading-overlay>
    <div class="content" [class.category-menu-open]="isCategoryMenuOpen">
        <div id="pano" appMouseEvents (mouseMove)="onMouseMove($event)" (touchMove)="onMouseMove($event)" appSpinner
             [speed]='pano?.Control?.spinnerspeed' [mouseEvent]="xYMouseEvent" (navigationChange)="onSpinChange($event)"></div>
        <popup-modal #popupModal id="popup" *ngIf="popupModalContent" [content]="popupModalContent" (modalClose)="handlePopupModalClose()">
        </popup-modal>
        <tool-bar *ngIf="view" [pano]="pano" [tour]="tour" [view]="view" (hotspotSelect)="onHotspotSelect($event)"></tool-bar>
        <div id="navguide" *ngIf="pano && showNavGuideImg && !errorMessage" (click)="closeNavGuide()">
            <img [src]="navGuideImgUrl" alt="NavGuideImage" />
        </div>
    </div>
    <category-menu-container *ngIf="pano && tour && pano.UseLeftMenu" [pano]="pano" [tour]="tour" [view]="view" [isOpen]="isCategoryMenuOpen"
                             (toggleOpen)="handleSideMenuToggle($event)" (viewSelect)="goToViewById($event)"></category-menu-container>
    <hotspot-debug-menu [view]="view"></hotspot-debug-menu>
</div>