import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private compactScreenSubject = new BehaviorSubject<boolean>(false);
  windowInnerWidth: number;

  constructor() {
    this.windowInnerWidth = window.innerWidth;
    this.compactScreenSubject.next(this.isCompact());
    window.addEventListener(
      'resize',
      () => {
        this.windowInnerWidth = window.innerWidth;
        this.compactScreenSubject.next(this.isCompact());
      },
      false
    );
  }

  get compactScreen$(): Observable<boolean> {
    return this.compactScreenSubject.asObservable();
  }

  isCompact(): boolean {
    // 1366 is based on current largest iPad width
    // matches value set in SCSS mixin
    return this.windowInnerWidth <= 1366;
  }

  isCompatible(): boolean {
    let result = true;

    if (this.oldEdgeVersion() || this.isIE()) {
      result = false;
    }

    return result;
  }

  isIE(): boolean {
    return navigator.userAgent.indexOf('.NET') > -1;
  }

  oldEdgeVersion(): string {
    let result = '';

    // Old Edge - Edge/####
    // New Edge with Chromium - Edg/####

    const userAgent = navigator.userAgent;
    const match = 'Edge/';

    let index = userAgent.indexOf(match);
    if (index > -1) {
      result = userAgent.substring(index + match.length);
      index = result.indexOf(' ');
      if (index > -1) {
        result = result.substring(0, index);
      }
    }

    return result;
  }
}
