import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { Pano } from '../../entities/pano';
import { Tour } from '../../entities/tour';
import { View } from '../../entities/view';

@Component({
  selector: 'category-menu-container',
  templateUrl: './category-menu-container.component.html',
  styleUrls: ['./category-menu-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryMenuContainerComponent implements OnInit {
  @Input() pano: Pano;
  @Input() tour: Tour;
  @Input() view: View;
  @Input() isOpen = true;
  @Output() toggleOpen = new EventEmitter<boolean>();
  @Output() viewSelect = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}
}
