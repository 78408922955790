import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByOrder'
})
export class SortByOrderPipe implements PipeTransform {

  transform<T extends Array<{ Order: number }>>(array: T): T {
    array.sort(this.sortByOrder);
    return array;
  }

  sortByOrder(a: { Order: number }, b: { Order: number }): number {
    return a.Order - b.Order;
  }
}
