<div id="thumbnail-menu" class="thumbnail-menu-container" [class.open]="isAnyCategoryOpen">
  <div class="thumbnail-categories-container">
    <div class="category" *ngFor="let category of categoriesWithThumbnails" [class.closed]="!category.IsOpen">
      <div *ngIf="!isCompactScreen" class="category-title-container">
        <label>{{category.Name}}</label>
      </div>
      <div *ngIf="isCompactScreen" [class.open]="category.IsOpen" class="mobile-category-title-container"
           (click)="collapseMenuCloseCategory(category)">
        <label *ngIf="category.IsOpen">{{category.Name}}</label>
        <icon *ngIf="category.IsOpen" name="x"></icon>
      </div>
      <div *ngIf="category.Settings.IconSvgContent" class="category-icon" (click)="handleCategorySelect(category)"
           [innerHtml]="category.Settings.IconSvgContent | safeHtml"></div>
      <div *ngIf="!expandCollapseWidth" class="category-options-container" [class.open]="category.IsOpen"
           [@heightExpandCollapse]="category.AnimationState">
        <thumbnail-option-item *ngFor="let option of category.Options" [option]="option" (optionSelect)="selectOption(option, category)">
        </thumbnail-option-item>
      </div>
      <div *ngIf="expandCollapseWidth" class="category-options-container" [class.open]="category.IsOpen"
           [@widthExpandCollapse]="category.AnimationState">
        <thumbnail-option-item *ngFor="let option of category.Options" [option]="option" (optionSelect)="selectOption(option, category)">
        </thumbnail-option-item>
      </div>
    </div>
  </div>
  <div class="share-reset-container">
    <icon name="share" (click)="handleShare()"></icon>
    <icon name="reset_arrow" (click)="handleReset()"></icon>
  </div>
</div>