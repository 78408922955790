import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { NeighborNavigation } from '../entities/option-combination';
import { XYMouseTouch } from './mouse-events.directive';

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective {
  @Input() speed = 50;
  @Input('mouseEvent') set mouseEvent(event: XYMouseTouch) {
    this.update(event);
  }
  @Output() navigationChange = new EventEmitter<NeighborNavigation>();

  xyMouse: XYMouseTouch;
  diffX = 0;
  diffY = 0;
  startX = 0;
  startY = 0;

  stepWidth: number;

  constructor() {
    this.stepWidth = document.body.clientWidth / 10;
  }

  update(event: XYMouseTouch) {
    if (!this.xyMouse) {
      this.xyMouse = event;
      return;
    }

    if (this.toggledMouseButton(this.xyMouse, event)) {
      this.startX = event.x;
      this.startY = event.y;
      this.diffX = 0;
      this.diffY = 0;
    } else if (this.dragging(event)) {
      this.diffX = event.x - this.startX;
      this.diffY = event.y - this.startY;

      if (this.minimalMovementHit(this.diffX, this.diffY)) {
        if (this.verticalOnly(this.diffX, this.diffY)) {
          if (this.movingUp(this.diffY)) {
            this.navigationChange.emit(NeighborNavigation.TOP);
          } else if (this.movingDown(this.diffY)) {
            this.navigationChange.emit(NeighborNavigation.BOTTOM);
          }
        } else if (this.horizontalOnly(this.diffX, this.diffY)) {
          if (this.movingLeft(this.diffX)) {
            this.navigationChange.emit(NeighborNavigation.LEFT);
          } else if (this.movingRight(this.diffX)) {
            this.navigationChange.emit(NeighborNavigation.RIGHT);
          }
        } else {
          // Diagonal
          if (this.movingUp(this.diffY) && this.movingLeft(this.diffX))
            this.navigationChange.emit(NeighborNavigation.TOPLEFT);
          else if (this.movingUp(this.diffY) && this.movingRight(this.diffX))
            this.navigationChange.emit(NeighborNavigation.TOPRIGHT);
          else if (this.movingDown(this.diffY) && this.movingLeft(this.diffX))
            this.navigationChange.emit(NeighborNavigation.BOTTOMLEFT);
          else if (this.movingDown(this.diffY) && this.movingRight(this.diffX))
            this.navigationChange.emit(NeighborNavigation.BOTTOMRIGHT);
        }

        this.startX = event.x;
        this.startY = event.y;
        this.diffX = 0;
        this.diffY = 0;
      }
    }

    this.xyMouse = event;
  }

  toggledMouseButton(existing: XYMouseTouch, current: XYMouseTouch): boolean {
    return existing.mouseDown !== current.mouseDown;
  }

  dragging(event: XYMouseTouch): boolean {
    return event.mouseDown;
  }

  private verticalOnly(x: number, y: number) {
    return Math.abs(x) < 10;
  }

  private horizontalOnly(x: number, y: number) {
    return Math.abs(y) < 10;
  }

  private movingLeft(x: number) {
    return x > 0;
  }

  private movingRight(x: number) {
    return !this.movingLeft(x) && x;
  }

  private movingUp(y: number) {
    return y > 0;
  }

  private movingDown(y: number) {
    return !this.movingUp(y) && y;
  }

  private minimalMovementHit(x: number, y: number): boolean {
    return Math.abs(x) > this.speed || Math.abs(y) > this.speed;
  }
}
