export interface IOption {
  OptionId: number;
  Name: string;
  CategoryId: number;
  Order: number;
  LinkedOptionIds: Array<number>;
}

export class Option implements IOption {
  OptionId: number;
  Name = '';
  CategoryId: number;
  Order: number;
  CreatedOn: string;
  ModifiedOn: string;
  PreviewFileBaseDirectory: string;
  PreviewFilename: string;
  LinkedOptionIds = new Array<number>();

  IsSelected = false;

  constructor(data?: IOption, isSelected = false) {
    if (data) {
      Object.assign(this, data);
    }

    this.IsSelected = isSelected;
  }
}
