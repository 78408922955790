import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { IPano } from '../entities/pano';

import { ISetting, SettingsCategoryTitle } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getPanoById(id: number, isDynamicPano: boolean, dynamicViewId = 0): Promise<IPano> {
    if (environment.isOffline) {
      return this.http.get<IPano>('/panoresources/panodata.json').toPromise();
    } else {
      const dynamicViewPath = dynamicViewId > 0 ? `/${dynamicViewId}` : '';
      const path = `/api/panos/${isDynamicPano ? 'dynamicpano/' : ''}${id}${dynamicViewPath}`;
      return this.http.get<IPano>(path).toPromise();
    }
  }

  getPanoByName(
    name: string,
    isDynamicPano: boolean,
    clientName?: string,
    projectName?: string
  ): Promise<IPano> {
    const qParams = [];
    if (clientName) qParams.push(`clientName=${clientName}`);
    if (projectName) qParams.push(`projectName=${projectName}`);
    const qParam = qParams.length ? `?${qParams.join('&')}` : '';

    return this.http
      .get<IPano>(`/api/panos/name/${isDynamicPano ? 'dynamicpano/' : ''}${name}${qParam}`)
      .toPromise();
  }

  getSettingsByPanoId(panoId: number): Promise<ISetting[][]> {
    if (environment.isOffline) {
      return this.http.get<ISetting[][]>(`/panoresources/settings.json`).toPromise();
    } else {
      const convergeAppId = '42E6BCB5-7E2F-4D0D-B822-1CB9AE1D839C';
      const path = `settings/application/${convergeAppId}/rootcategoryname/${SettingsCategoryTitle.PanoViewerApp}/pano/${panoId}`;
      return this.http.get<ISetting[][]>(`/api/${path}`).toPromise();
    }
  }

  postForm(form: FormData, apiEndpoint?: string, optionalParamString?: string): Observable<string> {
    return this.http.post<string>(`/api/${apiEndpoint}${optionalParamString || ''}`, form);
  }
}
