import { PanoBlend } from './pano-blend';
import { PanoBlendFactory } from './pano-blend-factory';
import { Control, PanoSettings } from './settings/pano-settings';
import { ITour, Tour } from './tour';
import { View } from './view';

import { sortByOrder } from '@ml/common';

export interface IPano {
  PanoId: number;
  ProjectId: number;
  Name: string;
  CommunityName: string;
  CategoryMenuType: string;
  LeftMenuOpen: boolean;
  UseLeftMenu: boolean;
  ShowSingleOptionCategory: boolean;
  KeepHoriztonalVerticalStatesBetweenViews: boolean;
  ClientId: number;
  ClientName: string;
  Control: Control;
  Settings: PanoSettings;

  PanoBlend: Array<PanoBlend>;
  Tours: Array<ITour>;
}

export class Pano implements IPano {
  PanoId: number;
  ProjectId: number;
  IsDynamic = false;
  Name: string;
  CommunityName: string;
  CategoryMenuType: string;
  LeftMenuOpen = true;
  UseLeftMenu = true;
  ShowSingleOptionCategory = true;
  KeepHoriztonalVerticalStatesBetweenViews = true;
  CommunityId: number;
  ClientId: number;
  ClientName: string;
  Control: Control;
  PanoBlend: Array<PanoBlend>;
  MainBlend: PanoBlend;
  Settings: PanoSettings;

  Tours = new Array<Tour>();

  private DefaultTourIndex = 0;

  constructor(iPano?: IPano) {
    if (iPano) {
      Object.assign(this, iPano);

      this.CategoryMenuType = iPano.CategoryMenuType || 'Default';
      this.Tours = iPano.Tours?.map(tour => new Tour(tour)).sort(sortByOrder);
      this.Settings = new PanoSettings(iPano.Settings);
      this.MainBlend = this.hydratePanoBlend();
    }
  }

  private hydratePanoBlend(): PanoBlend {
    const mainBlend = this.PanoBlend.find(x => !x.HotspotId);
    const panoBlendFacory = new PanoBlendFactory();
    return panoBlendFacory.create(mainBlend);
  }

  getDefaultTour(): Tour {
    return this.Tours ? this.Tours[this.DefaultTourIndex] : null;
  }

  getCommunityTour(): Tour {
    return this.Tours.find(tour => tour.IsBaseCommunity);
  }

  getTourByIdOrDefault(tourId: number): Tour {
    return tourId > 0 ? this.Tours.find(t => t.TourId === tourId) : this.getDefaultTour();
  }

  getViewById(id: number): View {
    const views = this.Tours.map(tour => tour.Views.find(v => v.ViewId === id)).filter(x => x);
    return views.length > 0 ? views[0] : null;
  }
}

export enum PanoZoomDirection {
  In = 'In',
  Out = 'Out'
}
