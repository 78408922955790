import { View } from '../../../../entities/view';
import { IViewGroup } from '../../../../entities/view-group';

import { sortBySortOrder } from '@ml/common';

export class MenuAccordionVM {
  Id: number;
  Name: string;
  IsOpen = false;
  ChildItems: MenuAccordionItemVM[] = [];
  SortOrder: number;

  constructor() {}

  static CreateFromViewGroup(vg: IViewGroup, allViews: View[]) {
    const vm = new MenuAccordionVM();
    vm.Id = vg.PanoViewGroupId;
    vm.Name = vg.Name;
    vm.SortOrder = vg.SortOrder;
    vm.ChildItems = vg.ChildViewIds.map(id => {
      const v = allViews.find(x => x.ViewId === id);
      if (v)
        return new MenuAccordionItemVM(
          v.ViewId,
          v.Name,
          vg.CustomData?.ChildViewsSortOrderById?.indexOf(id)
        );
    })
      .filter(Boolean)
      .sort(sortBySortOrder);

    return vm;
  }
}

export class MenuAccordionItemVM {
  constructor(public Id: number, public Name: string, public SortOrder: number) {}
}
