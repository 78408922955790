import { IconLibrary } from '../shared/icon/icon-library';
import { Point } from '../utility/helpers/helpers';
import { HotspotSettings } from './hotspot.settings';
import { Media } from './media';
import { PanoBlend } from './pano-blend';
import { PanoBlendFactory } from './pano-blend-factory';

import {
  PanoHotspotAnimationSettings,
  PanoHotspotModalPosition,
  PanoHotspotModalSize
} from '@ml/common';
import { environment } from '../../environments/environment';

export enum ActionType {
  NextView = 0,
  Url,
  MiniMapDropdown,
  MEDIA,
  VIDEO,
  SwitchToOptionCombination,
  ControllerHotspot
}

export class HotspotRadarConeSettings {
  IsEnabled = false;
  StartingAngle = 0;
  SizeScale = 1;
}

export class Style {
  Top: string;
  Left: string;
  Transform: string;

  constructor() {}
}

export interface IHotspot {
  HotspotId: number;
  ViewId: number;
  Name: string;
  X: number;
  Y: number;
  ImageHeight: number;
  ImageWidth: number;
  GoToView: string;
  GoToViewId: number;
  ActionType: ActionType;
  Url: string;
  IconUrl: string;
  IconId: number;
  IconSvgContent: string;
  ATH: string;
  ATV: string;
  ModalSize: PanoHotspotModalSize;
  ModalPosition: PanoHotspotModalPosition;
  ModalSettings: ModalSettings;
  FixedPosition: boolean;
  Width: number;
  Height: number;
  IsInstanced: boolean;
  OptionCombinationId: number;
  ParentHotspotId: number;
  GoToPanoOptionCombinationId: number;
  PanoBlend: PanoBlend;

  Media: Array<Media>;

  OnLoaded: string; // Legacy CSS hotspot animation -- should now use AnimationSettings
  AnimationSettings: PanoHotspotAnimationSettings;
  RadarConeSettings: HotspotRadarConeSettings;
  Settings: HotspotSettings;
  HotspotReceiverIds: Array<number>;
}

export class Hotspot implements IHotspot {
  HotspotId: number;
  ViewId: number;
  Name: string;
  X = 0;
  Y = 0;
  ImageHeight = 0;
  ImageWidth = 0;
  GoToView = '';
  GoToViewId: number;
  ActionType: ActionType;
  Url: string;
  IconUrl: string;
  IconId: number;
  IconSvgContent: string;
  ModalSize: PanoHotspotModalSize;
  ModalPosition: PanoHotspotModalPosition;
  FixedPosition: boolean;
  Width: number;
  Height: number;
  IsInstanced = false;
  OptionCombinationId: number;
  ParentHotspotId: number;
  GoToPanoOptionCombinationId: number;
  PanoBlend: PanoBlend;
  Settings: HotspotSettings;
  HotspotReceiverIds = new Array<number>();
  HotspotReceiversOn = true;

  Media = new Array<Media>();

  // calculated for the server
  ATH = '0';
  ATV = '0';

  OnLoaded: string; // Legacy CSS hotspot animation -- should now use AnimationSettings
  AnimationSettings = new PanoHotspotAnimationSettings();
  RadarConeSettings = new HotspotRadarConeSettings();
  ModalSettings = new ModalSettings();

  ngStyle = new Style();

  miniMapCenterPoint: Point;

  constructor(iHotspot?: IHotspot, miniMapImageSize?: { Width: number; Height: number }) {
    if (iHotspot) {
      Object.assign(this, iHotspot);

      if (this.IconUrl && this.IconUrl.startsWith('/panoresources/')) {
        this.IconUrl = `https://${environment.apiDomain}/${this.IconUrl}`;
      }

      this.Settings = this.hydrateHotspotSettings(iHotspot.Settings);

      if (!this.Height) this.Height = 'prop' as any;
      if (!this.Width) this.Width = 60;

      this.PanoBlend = this.hydratePanoBlend(iHotspot.PanoBlend);
      this.ModalSettings = this.hydrateModalSettings(iHotspot.ModalSettings);

      if (miniMapImageSize) {
        const x = (this.X / this.ImageWidth) * miniMapImageSize.Width - 15;
        const y = (this.Y / this.ImageHeight) * miniMapImageSize.Height - 15;
        this.miniMapCenterPoint = { X: x, Y: y };

        this.ngStyle.Transform = `translate(${x}px, ${y}px)`;
      }

      if (!this.IconSvgContent) this.IconSvgContent = IconLibrary.default_hotspot;
      if (!this.RadarConeSettings) this.RadarConeSettings = new HotspotRadarConeSettings();

      if (iHotspot.Media) {
        this.Media = iHotspot.Media.map(media => new Media(media));
      }

      this.HotspotReceiverIds = iHotspot.HotspotReceiverIds ? iHotspot.HotspotReceiverIds : [];
      this.HotspotReceiversOn = iHotspot.Settings.ReceiversVisibleOnLoad;
    }
  }

  hydrateHotspotSettings(hotspotSettings: HotspotSettings): HotspotSettings {
    return Object.assign(new HotspotSettings(), hotspotSettings);
  }

  hydrateModalSettings(modalSettings: ModalSettings): ModalSettings {
    return Object.assign(new ModalSettings(), modalSettings);
  }

  private hydratePanoBlend(panoBlend: PanoBlend): PanoBlend {
    const panoBlendFacory = new PanoBlendFactory();
    return panoBlendFacory.create(panoBlend);
  }
}

export class ModalSettings {
  ModalSizeRatio = ''; // Deprecated. Should use Width
  Height = 0;
  Width = 0;
  HideName = false;

  IsVRModal = false;
  VRModalWidth = 0;
  Distorted = false;
  Rx = 0;
  Ry = 0;
  Rz = 0;
  OffSetX = 0;
  OffSetY = 0;
}
