import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'asUrl'
})
export class BackgroundUrlPipe implements PipeTransform {

    constructor(private sanitization: DomSanitizer) { }

    transform(value: string): string {
        return `url(${value ? value.replace(' ', '%20') : ''})`;
    }
}
