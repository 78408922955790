import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appMouseEvents]'
})
export class MouseEventsDirective implements OnInit, OnDestroy {
  @Output() mouseMove = new EventEmitter<XYMouseTouch>();
  @Output() touchMove = new EventEmitter<XYMouseTouch>();

  onMouseMoveEvent = this.onMouseMove.bind(this);
  onMouseUpEvent = this.onMouseUp.bind(this);
  onMouseDownEvent = this.onMouseDown.bind(this);
  onTouchStartEvent = this.onTouchStart.bind(this);
  onTouchMoveEvent = this.onTouchMove.bind(this);
  onTouchEndEvent = this.onTouchEnd.bind(this);
  XYMouseTouch = new XYMouseTouch();

  startX: number;
  startY: number;

  constructor() {}

  ngOnInit() {
    this.addMouseMoveListener();
    this.addMouseUpListener();
    this.addMouseDownListener();

    this.addTouchStartListener();
    this.addTouchMoveListener();
    this.addTouchEndListener();
  }

  addMouseDownListener() {
    document.addEventListener('mousedown', this.onMouseDownEvent);
  }

  removeMouseDownListener() {
    document.removeEventListener('mousedown', this.onMouseDownEvent);
  }

  addMouseUpListener() {
    document.addEventListener('mouseup', this.onMouseUpEvent);
  }

  removeMouseUpListener() {
    document.addEventListener('mouseup', this.onMouseUpEvent);
  }

  addMouseMoveListener() {
    document.addEventListener('mousemove', this.onMouseMoveEvent);
  }

  removeMouseMoveListener() {
    document.removeEventListener('mousemove', this.onMouseMoveEvent);
  }

  addTouchStartListener() {
    document.addEventListener('touchstart', this.onTouchStartEvent);
  }

  removeTouchStartListener() {
    document.removeEventListener('touchstart', this.onTouchStartEvent);
  }

  addTouchMoveListener() {
    document.addEventListener('touchmove', this.onTouchMoveEvent);
  }

  removeTouchMoveListener() {
    document.removeEventListener('touchmove', this.onTouchMoveEvent);
  }

  addTouchEndListener() {
    document.addEventListener('touchend', this.onTouchEndEvent);
  }

  removeTouchEndListener() {
    document.removeEventListener('touchend', this.onTouchEndEvent);
  }

  onMouseDown(event: MouseEvent) {
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.XYMouseTouch.wasMouseClick = true;
    this.XYMouseTouch.mouseDown = true;
  }

  onMouseMove(event: MouseEvent) {
    this.XYMouseTouch.x = event.clientX || 0;
    this.XYMouseTouch.y = event.clientY || 0;
    this.XYMouseTouch.wasMouseClick = true;
    this.mouseMove.emit(this.XYMouseTouch);
  }

  onMouseUp(event: MouseEvent) {
    this.XYMouseTouch.wasMouseClick = true;
    this.XYMouseTouch.mouseDown = false;
  }

  onTouchStart(event: TouchEvent) {
    this.XYMouseTouch.x = event.touches[0]?.clientX || 0;
    this.XYMouseTouch.y = event.touches[0]?.clientY || 0;
    this.XYMouseTouch.mouseDown = true;
    this.XYMouseTouch.wasMouseClick = false;
    this.XYMouseTouch.isTouchDevice = true;
    this.touchMove.emit(this.XYMouseTouch);
  }

  onTouchMove(event: TouchEvent) {
    this.XYMouseTouch.x = event.touches[0]?.clientX || 0;
    this.XYMouseTouch.y = event.touches[0]?.clientY || 0;
    this.XYMouseTouch.wasMouseClick = false;
    this.XYMouseTouch.isTouchDevice = true;
    this.touchMove.emit(this.XYMouseTouch);
  }

  onTouchEnd(event: TouchEvent) {
    this.XYMouseTouch.x = event.touches[0]?.clientX || 0;
    this.XYMouseTouch.y = event.touches[0]?.clientY || 0;
    this.XYMouseTouch.mouseDown = false;
    this.XYMouseTouch.wasMouseClick = false;
    this.XYMouseTouch.isTouchDevice = true;
    this.touchMove.emit(this.XYMouseTouch);
  }

  ngOnDestroy() {
    this.removeMouseMoveListener();
    this.removeMouseUpListener();
    this.removeMouseDownListener();

    this.removeTouchStartListener();
    this.removeTouchMoveListener();
    this.removeTouchEndListener();
  }
}

export class XYMouseTouch {
  x: number;
  y: number;
  mouseDown: boolean;
  wasMouseClick: boolean;
  isTouchDevice: boolean;

  constructor() {
    this.isTouchDevice = false;
  }
}
