import { Injectable } from '@angular/core';

import { ISetting, SettingFieldType, SettingsCategoryTitle } from '@ml/common';

import { Lookup } from '../utility/helpers/lookup';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settings: ISetting[] = [];

  constructor(private lookup: Lookup, private api: ApiService) { }

  /**
   * Provide a pano id to load all app settings based
   * on the client and division that the pano belongs to
   *
   * @param panoId Id of the configurator being loaded in the app
   */
  async loadSettingsByPanoId(panoId?: number): Promise<ISetting[]> {
    let settings = this.settings;
    if (!settings.length && panoId) {
      const result = await this.api.getSettingsByPanoId(panoId);
      settings = result.flatMap(x => x);

      if (!settings.length)
        console.warn(`No settings found for panoId ${panoId}`);
    }
    this.settings = settings;
    return this.settings;
  }

  /**
   * Get the value of the setting by setting name
   *
   * @param settingName Name of the setting
   * @param category The setting category is only needed if the name is repeated across multiple categories
   */
  get(settingName: string, category?: SettingsCategoryTitle): any {
    const setting = this.getSettingByName(settingName, category);
    return setting ? this.getValue(setting) : undefined;
  }

  private getSettingByName(settingName: string, category: SettingsCategoryTitle): ISetting | undefined {
    const settingsToSearch = category
      ? this.settings.filter(s => s.SettingsCategoryName === category)
      : this.settings;
    const setting = settingsToSearch.find(s => s.Name.toLowerCase() === settingName.toLowerCase());
    return setting;
  }

  private getValue(setting: ISetting): any {
    if (!setting || !setting.Value) return null;

    switch (setting.FieldType) {
      case SettingFieldType.FileUpload:
        const relativePath = JSON.parse(setting.Value)[0];
        if (!relativePath) return null;
        return `${this.lookup.ApiProductDataEndpoint}${relativePath}`;
      case SettingFieldType.CheckBox:
        return setting.Value.toLowerCase() === 'true';
      case SettingFieldType.DropDown:
        return setting.Value.toLowerCase() === 'none' ? null : setting.Value;
      case SettingFieldType.NumberBox:
        return Number(setting.Value);
      case SettingFieldType.Icon:
        return setting.UIValue;
      case SettingFieldType.ExpandingSet:
        return JSON.parse(setting.Value);
      default:
        return setting.Value;
    }
  }

  getAllForFonts() {
    return this.settings.filter(x => x.UIName && x.FieldType === SettingFieldType.Font);
  }

  getAllForIcons(): ISetting[] {
    return this.settings.filter(x => x.UIName && x.FieldType === SettingFieldType.Icon);
  }

  getAllForCssVariables(): ISetting[] {
    return this.settings.filter(
      x =>
        x.UIName && x.FieldType !== SettingFieldType.Icon && x.FieldType !== SettingFieldType.Font
    );
  }
}
