import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { Option } from '../../../../entities/option';
import { PanoService } from '../../../../entities/pano.service';

@Component({
  selector: 'option-grid-item',
  templateUrl: './option-grid-item.component.html',
  styleUrls: ['./option-grid-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionGridItemComponent implements OnInit {
  @Input() option: Option;
  @Output() optionSelect = new EventEmitter<Option>();
  thumbnailUrl: string;

  constructor(private panoService: PanoService) {}

  ngOnInit(): void {
    const basePath = environment.panoResourcesBaseUrl;
    const fullPath = this.option.PreviewFilename
      ? `${basePath}/${this.option.PreviewFilename.replace('/panoresources/assets', '').replace(
          '/panoresources',
          ''
        )}`
      : '';
    this.thumbnailUrl = fullPath.replace(/([^:])\/\//g, '$1/');
  }

  handleOptionSelect() {
    this.optionSelect.emit(this.option);
  }
}
