<div *ngIf="showAlert" class="alert-banner" [ngClass]="{'warning': isWarning, 'error': isError, 'info': isInfo}">
    <span class="alert-text">
        <svg *ngIf="isWarning || isError" id="alert_bold" class="alert-svg" data-name="alert bold" xmlns="http://www.w3.org/2000/svg" width="23.94"
             height="24" viewBox="0 0 23.94 24">
            <path d="M12,1a.7.7,0,0,1,.6.42L22.9,21.74a1,1,0,0,1,0,.87.71.71,0,0,1-.6.39H1.77a.7.7,0,0,1-.6-.39,1,1,0,0,1,0-.89L11.43,1.41A.68.68,0,0,1,12,1m0-1a1.7,1.7,0,0,0-1.5,1L.27,21.27a2,2,0,0,0,0,1.82A1.71,1.71,0,0,0,1.77,24H22.29a1.7,1.7,0,0,0,1.47-.91,2,2,0,0,0,0-1.82L13.52,1A1.68,1.68,0,0,0,12,0Z"
                  transform="translate(-0.06)" fill="#fff" />
            <path d="M14,8a.47.47,0,0,0-.13-.32.52.52,0,0,0-.32-.18H10.56a.52.52,0,0,0-.32.18A.51.51,0,0,0,10.1,8l.23,7.59a.42.42,0,0,0,.46.38h2.47a.42.42,0,0,0,.45-.38Z"
                  transform="translate(-0.06)" fill="#fff" />
            <rect x="10.26" y="17.42" width="3.42" height="3.42" rx="0.43" ry="0.43" fill="#fff" />
        </svg>
        <svg *ngIf="isInfo" id="reminder_bell" class="alert-svg" data-name="reminder bell" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             viewBox="0 0 24 24">
            <path d="M21.92,18.25a1.61,1.61,0,0,0-.58-.87l-1.59-1.22a1.19,1.19,0,0,1-.49-1V9.55a7.16,7.16,0,0,0-.57-2.78A7.22,7.22,0,0,0,14.82,3a6.63,6.63,0,0,0-1-.33h0V1.75a1.78,1.78,0,0,0-3.56,0v.87h0a6.63,6.63,0,0,0-1,.33A7.13,7.13,0,0,0,4.74,9.55v5.63a1.19,1.19,0,0,1-.49,1L2.66,17.38a1.61,1.61,0,0,0-.58.87,1.54,1.54,0,0,0,0,.95,1.66,1.66,0,0,0,1.57,1.1H20.32a1.66,1.66,0,0,0,1.57-1.1A1.54,1.54,0,0,0,21.92,18.25ZM11.34,1.75a.66.66,0,0,1,1.32,0v.57H11.34Zm9,17.4H3.68a.48.48,0,0,1-.3-.86L5,17.07a2.35,2.35,0,0,0,.94-1.89V9.55a6.09,6.09,0,0,1,12.18,0v5.63A2.35,2.35,0,0,0,19,17.07l1.59,1.22A.48.48,0,0,1,20.32,19.15Z"
                  fill="#fff" />
            <path d="M9.52,21.5v.05a2.48,2.48,0,0,0,5,0V21.5Z" fill="#fff" />
            <path d="M5.59,2a.63.63,0,0,0-.86-.08A11.35,11.35,0,0,0,.5,9.49a.61.61,0,0,0,.54.66h.07a.6.6,0,0,0,.6-.53,10.11,10.11,0,0,1,3.78-6.8A.6.6,0,0,0,5.59,2Z"
                  fill="#fff" />
            <path d="M22.12,5.23a11.29,11.29,0,0,0-2.85-3.34.63.63,0,0,0-.86.08.6.6,0,0,0,.1.85,10.11,10.11,0,0,1,3.78,6.8.6.6,0,0,0,.6.53H23a.61.61,0,0,0,.54-.66A11.24,11.24,0,0,0,22.12,5.23Z"
                  fill="#fff" />
        </svg>
        <span>
            <ng-content></ng-content>
        </span>
    </span>
    <span *ngIf="allowClose" class="alert-close" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.6" height="14.6" viewBox="0 0 14.6 14.6">
            <g id="e3e420db-5d6d-4e78-80e1-f0b1b8626a40" data-name="Layer 2">
                <g id="b84b5d25-7450-4185-9dfd-881c2d046679" data-name="Layer 1">
                    <path d="M8.23,7.3,14.4,1.13a.64.64,0,0,0,0-.93.64.64,0,0,0-.93,0L7.3,6.37,1.13.2A.64.64,0,0,0,.2.2a.64.64,0,0,0,0,.93L6.37,7.3.2,13.47a.64.64,0,0,0,0,.93.6.6,0,0,0,.46.2.6.6,0,0,0,.46-.2L7.3,8.23l6.17,6.17a.64.64,0,0,0,.93,0,.64.64,0,0,0,0-.93Z"
                          fill="#ffffff" />
                </g>
            </g>
        </svg>
    </span>
</div>