import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Option } from '../../../../entities/option';
import { PanoService } from '../../../../entities/pano.service';

@Component({
  selector: 'thumbnail-option-item',
  templateUrl: './thumbnail-option-item.component.html',
  styleUrls: ['./thumbnail-option-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThumbnailOptionItemComponent implements OnInit {
  @Input() option: Option;
  @Output() optionSelect = new EventEmitter<Option>();
  thumbnailImageUrl: string;

  constructor(private panoService: PanoService) { }

  ngOnInit(): void {
    const basePath = this.panoService.getBasePath();

    if (this.option.PreviewFilename.indexOf('http') > -1) {
      this.thumbnailImageUrl = this.option.PreviewFilename;
    } else {
      this.thumbnailImageUrl = this.option.PreviewFilename
        ? `${basePath}${this.option.PreviewFilename.replace('/panoresources/assets', '').replace(
          '/panoresources',
          ''
        )}`
        : '';
    }

    this.thumbnailImageUrl = this.thumbnailImageUrl.replace(/([^:])\/\//g, '$1/');
  }

  handleOptionSelect() {
    this.optionSelect.emit(this.option);
  }
}
