import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { SettingsService } from '../../services/settings.service';

import { SettingsCategoryTitle } from '@ml/common';

@Component({
  selector: 'pano-loading-overlay',
  templateUrl: './pano-loading-overlay.component.html',
  styleUrls: ['./pano-loading-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoLoadingOverlayComponent implements OnInit {
  @HostBinding('class.blocking-overlay-backdrop') hasBlockingOverlayBackdrop = true;
  @Input() panoName: string;
  title: string;
  logoUrl: string;
  description: string;
  enableLoadingAnimation = false;

  constructor(private settings: SettingsService) {}

  ngOnInit(): void {
    this.enableLoadingAnimation = this.settings.get(
      'EnableLoadingAnimation',
      SettingsCategoryTitle.PanoViewerPrimaryLoader
    );
    this.title = (
      this.settings.get('LoadingAnimationTitle', SettingsCategoryTitle.PanoViewerPrimaryLoader) ??
      ''
    ).replace(/{{panoName}}/g, this.panoName);
    this.logoUrl = this.settings.get(
      'LoadingAnimationImage',
      SettingsCategoryTitle.PanoViewerPrimaryLoader
    );
    this.description = (
      this.settings.get(
        'LoadingAnimationDescription',
        SettingsCategoryTitle.PanoViewerPrimaryLoader
      ) ?? ''
    ).replace(/{{panoName}}/g, this.panoName);
  }
}
