import { Media } from './media';

export enum SlideOutType {
    Iframe,
    Video
}

export class SlideOut {
    Title: string;
    Url: string;
    Type: SlideOutType;
    Media: Array<Media>;
    ShowHeader: boolean;
    ForceFullWindow: boolean;
    ShowHomeMenuIcon: boolean;
    ShowFavorite: boolean;
    ShowClose: boolean;

    Shown = false;
}

