import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { HOST_URL } from './host-url.token';

export interface PageMetadata {
  title: string;
  description: string;
  author: string;
  type: string;
  image: string;
}

const defaultMetadata: PageMetadata = {
  title: 'PanoViewer',
  description: 'A dynamic pano viewer',
  author: 'MediaLab 3D Solutions',
  type: 'website',
  image: ''
};

@Injectable()
export class MetadataService {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    @Inject(HOST_URL) private hostUrl: string,
    private router: Router
  ) {}

  public updateMetadata(metadata: Partial<PageMetadata>, index: boolean = false): void {
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

    this.metaTagService.addTags([
      ...metatags,
      { property: 'og:url', content: `${this.hostUrl}${this.router.url}` },
      { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' }
    ]);

    this.titleService.setTitle(pageMetadata.title);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },

      { name: 'author', content: metadata.author },

      { property: 'og:type', content: metadata.type },

      { property: 'og:image', content: metadata.image }
    ];
  }
}
