import { IconUnderMouseSettings, MouseSettings } from './mouse-settings';

export class PanoSettings {
  MouseSettings: MouseSettings;
  AutoRotateThruAllViews = false;
  EnableDownloadImage = false;
  DisableMouseWheelZoom = false;
  EnableFullScreenOnPano = false;
  EnableAccelerometer = false;
  ZoomButtonsLocation = '';
  IconUnderMouseSettings = new IconUnderMouseSettings();
  HotspotSettings = new HotspotSettings();
  WebVRSettings = new WebVRSettings();
  SoundSettings = new SoundSettings();
  DisclaimerSettings = new DisclaimerSettings();

  constructor(settings?: PanoSettings) {
    if (settings) {
      Object.assign(this, settings);
      this.MouseSettings = new MouseSettings(settings?.MouseSettings) || new MouseSettings();
      this.IconUnderMouseSettings =
        new IconUnderMouseSettings(settings?.IconUnderMouseSettings) ||
        new IconUnderMouseSettings();
      this.HotspotSettings =
        new HotspotSettings(settings?.HotspotSettings) || new HotspotSettings();
      this.WebVRSettings = new WebVRSettings(settings?.WebVRSettings) || new WebVRSettings();
      this.SoundSettings = new SoundSettings(settings?.SoundSettings) || new SoundSettings();
      this.DisclaimerSettings =
        new DisclaimerSettings(settings?.DisclaimerSettings) || new DisclaimerSettings();
    }
  }
}

export class HotspotSettings {
  ActivateClosestHotspot = false;
  ActivateClosestHotspotOnMiniMap = false;
  PulseMiniMapYouAreHereHotspot = false;

  constructor(settings?: HotspotSettings) {
    if (settings) Object.assign(this, settings);
  }
}

export class WebVRSettings {
  Enabled = false;
  Position = '';

  constructor(settings?: WebVRSettings) {
    if (settings) {
      Object.assign(this, settings);
    }
  }
}

export class SoundSettings {
  Enabled = false;
  Url = '';
  Loop = false;

  constructor(settings?: SoundSettings) {
    if (settings) {
      Object.assign(this, settings);
    }
  }
}

export class DisclaimerSettings {
  Enabled = false;
  Text = '';
  Type = '';
  Position = '';

  constructor(settings?: DisclaimerSettings) {
    if (settings) {
      Object.assign(this, settings);
    }
  }
}

export class Control {
  spinnerspeed = 50;

  usercontrol = 'all';
  mode = 'drag';
  dragrelative = true;
  draginertia = 0.1;
  dragfriction = 0.9;
  dragOldmode = false;
  movetorelative = true;
  movetoaccelerate = 1.0;
  movetospeed = 10.0;
  movetofriction = 0.8;
  movetoyfriction = 1.0;
  frictionstop = 0.01;
  invert = false;
  keybaccelerate = 0.5;
  keybspeed = 10.0;
  keybfriction = 0.9;
  keybinvert = false;
  keybfovchange = 0.75;
  mousefovchange = 1.0;
  fovspeed = 3.0;
  fovfriction = 0.9;
  zoomtocursor = false;
  zoomoutcursor = true;
  disablewheel = false;
  touchzoom = true;
  keycodesleft = 37;
  keycodesright = 39;
  keycodesup = 38;
  keycodesdown = 40;
  keycodesin = '';
  keycodesout = '';
  keydownrepeat = true;
  bouncinglimits = false;

  constructor(dto?: Control) {
    if (dto) Object.assign(this, dto);
  }
}
