export class MiniMapSettings {
  Width = 400;
  MobileWidth = 400;
  // Heights not actually used at this time -- TODO: remove them here and on backend
  Height = 400;
  MobileHeight = 400;
  OpenOnLoad = true;
  ShowToggleButton = true;
  Position = 'Bottom Right';
  MiniMapPosition = '';

  constructor(dto?: MiniMapSettings) {
    if (dto) {
      Object.assign(this, dto);

      if (!this.Position) {
        this.Position = 'Bottom Right';
      }
    }
  }
}
