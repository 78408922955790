import { Hotspot } from './hotspot';

export enum NeighborNavigation {
  TOP,
  TOPRIGHT,
  RIGHT,
  BOTTOMRIGHT,
  BOTTOM,
  BOTTOMLEFT,
  LEFT,
  TOPLEFT
}

export interface IOptionCombination {
  OptionCombinationId: number;
  ViewId: number;
  Name: string;
  IsDefault: boolean;
  IsPopular: boolean;
  IsBase: boolean;
  Filename: string;
  OptionCombinationNeighbor: OptionCombinationNeighbor;
  OptionIds: Array<number>;
  Hotspots: Array<Hotspot>;

  TopId: number;
  TopRightId: number;
  RightId: number;
  BottomRightId: number;
  BottomId: number;
  BottomLeftId: number;
  LeftId: number;
  TopLeftId: number;
}

export class OptionCombination implements IOptionCombination {
  OptionCombinationId: number;
  ViewId: number;
  Name: string;
  IsDefault: boolean;
  IsPopular: boolean;
  IsBase: boolean;
  Filename: string;
  OptionIds = new Array<number>();
  Hotspots = new Array<Hotspot>();

  SceneName: string;
  ImagePreviewRelativeUrl: string;
  OptionCombinationNeighbor: OptionCombinationNeighbor;

  TopId: number;
  TopRightId: number;
  RightId: number;
  BottomRightId: number;
  BottomId: number;
  BottomLeftId: number;
  LeftId: number;
  TopLeftId: number;

  isASpinner = false;
  private neighborNav = new Map<NeighborNavigation, number>();

  constructor(iCombo?: IOptionCombination) {
    if (iCombo) {
      Object.assign(this, iCombo);

      this.Filename = iCombo.Filename?.replace('pano/', '');

      const sceneName = this.getSceneName(this.Filename);
      this.SceneName = `scene_${sceneName}`;
      this.ImagePreviewRelativeUrl = `${sceneName}.tiles/thumb.jpg`;
      this.OptionIds = iCombo.OptionIds;

      if (iCombo && iCombo.OptionCombinationNeighbor) {
        this.TopId = iCombo.OptionCombinationNeighbor.TopId;
        this.TopRightId = iCombo.OptionCombinationNeighbor.TopRightId;
        this.RightId = iCombo.OptionCombinationNeighbor.RightId;
        this.BottomRightId = iCombo.OptionCombinationNeighbor.BottomRightId;
        this.BottomId = iCombo.OptionCombinationNeighbor.BottomId;
        this.BottomLeftId = iCombo.OptionCombinationNeighbor.BottomLeftId;
        this.LeftId = iCombo.OptionCombinationNeighbor.LeftId;
        this.TopLeftId = iCombo.OptionCombinationNeighbor.TopLeftId;
      }

      this.isASpinner = this.isComboASpinner();
      this.neighborNav = this.setNeighborNavigation();
      this.Hotspots = iCombo.Hotspots;
    }
  }

  private getSceneName(fileName: string): string {
    if (!fileName?.trim()) return 'NoFileFound';

    return fileName
      ?.replace(/\.jpg|\.png|\.tiff|\.tif|\.btf|\.tf8|\.bigtiff|\.jpeg|\.psd|\.psb|\.kro/i, '')
      .replace(/([^-()\w])/g, '_');
    // Definites known so far are converted into underscore: comma, space, period
    // Going to assume for now that all non-word and dashes will be converted to underscore
  }

  getNeighboringOptionComboIdByNavigation(event: NeighborNavigation) {
    return this.neighborNav.get(event);
  }

  private isComboASpinner(): boolean {
    return !!(
      this.TopId ||
      this.TopRightId ||
      this.RightId ||
      this.BottomRightId ||
      this.BottomId ||
      this.BottomLeftId ||
      this.LeftId ||
      this.TopLeftId
    );
  }

  private setNeighborNavigation(): Map<NeighborNavigation, number> {
    const result = new Map<NeighborNavigation, number>();

    result.set(NeighborNavigation.TOP, this.TopId);
    result.set(NeighborNavigation.TOPRIGHT, this.TopRightId);
    result.set(NeighborNavigation.RIGHT, this.RightId);
    result.set(NeighborNavigation.BOTTOMRIGHT, this.BottomRightId);
    result.set(NeighborNavigation.BOTTOM, this.BottomId);
    result.set(NeighborNavigation.BOTTOMLEFT, this.BottomLeftId);
    result.set(NeighborNavigation.LEFT, this.LeftId);
    result.set(NeighborNavigation.TOPLEFT, this.TopLeftId);

    return result;
  }
}

class OptionCombinationNeighbor {
  TopId: number;
  TopRightId: number;
  RightId: number;
  BottomRightId: number;
  BottomId: number;
  BottomLeftId: number;
  LeftId: number;
  TopLeftId: number;
}
