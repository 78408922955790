import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

import { SlideOut, SlideOutType } from '../entities/slide-out-modal';

@Component({
  selector: 'slide-out-modal',
  templateUrl: './slide-out.component.html',
  styleUrls: ['./slide-out.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideOutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() slideOut = new SlideOut();
  @Input() isFullWindow = false;

  @Output() closing = new EventEmitter();
  @Output() fullWindow = new EventEmitter();
  @Output() favoriteSelected = new EventEmitter();
  @Output() homeMenuActivated = new EventEmitter();

  @ViewChild('videoref') videoref: ElementRef;

  iframeSrc: SafeUrl;
  mediaIndex = 0;
  favorited = false;
  showFavorite = true;
  showCloseButton = true;
  isVideo = false;
  videoScrub = 0;
  destroyListener: any;

  constructor(private sanitizer: DomSanitizer, private renderer2: Renderer2) {}

  ngOnInit() {
    this.mediaIndex = 0;

    if (this.slideOut && this.slideOut.Url) {
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.slideOut.Url);
    } else {
      this.iframeSrc = null;
    }

    if (this.slideOut && this.slideOut.ForceFullWindow) this.setWindowMode(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    const previousUrl =
      changes.slideOut && changes.slideOut.previousValue && changes.slideOut.previousValue.Url
        ? changes.slideOut.previousValue.Url
        : '';
    const currentUrl =
      changes.slideOut && changes.slideOut.currentValue && changes.slideOut.currentValue.Url
        ? changes.slideOut.currentValue.Url
        : '';

    if (previousUrl !== currentUrl) {
      if (changes.slideOut && changes.slideOut.currentValue && changes.slideOut.currentValue.Url) {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
          changes.slideOut.currentValue.Url
        );
      } else {
        this.iframeSrc = null;
      }
    }

    if (this.slideOut && this.slideOut.ForceFullWindow && !this.isFullWindow)
      this.setWindowMode(true);

    this.showFavorite = this.slideOut?.ShowFavorite;
    this.showCloseButton = this.toShowCloseButton();
    this.isVideo = this.slideOut?.Type === SlideOutType.Video;
    this.videoScrub = 0;

    if (this.isVideo) {
      this.slideOut.ShowHeader = false;
      this.showCloseButton = false;
    }
  }

  showHomeMenu() {
    this.close();
    this.homeMenuActivated.emit(true);
  }

  toShowCloseButton(): boolean {
    return this.slideOut?.Type !== SlideOutType.Video;
  }

  toShowFavorite(): boolean {
    return this.slideOut?.Type !== SlideOutType.Video;
  }

  toggleFavorite() {
    this.favoriteSelected.emit(this.slideOut.Url);
  }

  previousMedia() {
    if (this.mediaIndex > 0) {
      --this.mediaIndex;
    }
  }

  nextMedia() {
    if (this.slideOut.Media && this.mediaIndex < this.slideOut.Media.length - 1) {
      ++this.mediaIndex;
    }
  }

  toggleWindowMode() {
    this.isFullWindow = !this.isFullWindow;
    this.fullWindow.emit(this.isFullWindow);
  }

  setWindowMode(state: boolean) {
    this.isFullWindow = state;
    this.fullWindow.emit(this.isFullWindow);
  }

  close() {
    this.mediaIndex = 0;
    this.pauseVideo();
    this.slideOut.Shown = false;
    this.closing.emit();
  }

  playVideo() {
    if (!this.isPlaying()) {
      this.videoref.nativeElement.play();

      if (!this.destroyListener) {
        this.destroyListener = this.renderer2.listen(
          this.videoref.nativeElement,
          'progress',
          event => this.updateScrubProgress(event)
        );
      }
    }
  }

  pauseVideo() {
    if (this.isPlaying()) {
      this.videoref?.nativeElement.pause();
    }
  }

  isPlaying(): boolean {
    return !this.videoref?.nativeElement?.paused;
  }

  startScrubbing(event: any) {
    this.pauseVideo();
    this.videoref.nativeElement.currentTime =
      (this.videoref.nativeElement.duration * event.value) / 100;
  }

  updateScrubProgress(event: Event) {
    this.videoScrub =
      (this.videoref.nativeElement.currentTime / this.videoref.nativeElement.duration) * 100;
  }

  ngOnDestroy() {
    if (this.destroyListener)
      this.destroyListener();
  }
}
