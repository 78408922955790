<div id='favorites' [class.showing]='showing'>
    <div class='content'>
        <div class='banner flex align-center'>
            <div class='your-favorites'>Your Favorites: </div>
            <div>Venue at Monroe</div>
        </div>
        <div class='floorplans'>
            <div *ngFor='let fp of favorite?.FloorPlans' class='floorplan-content'>
                <div class='fp-header flex align-center'>
                    <icon name='floorplans'></icon>
                    <div>{{fp.Name}}</div>
                    <hr />
                </div>
                <div class='flex align-top'>
                    <div>
                        <div class='section-label fp'>Floorplan</div>
                        <div class='flex align-top'>
                            <!-- <iframe [src]="fp.SafeUrl" seamless="seamless" scrolling="no" frameborder="0"></iframe> -->
                            <img class="floor-img" [src]="svgUrl" />
                            <div class='fp-details'>
                                <div class='fp-details-title'>Details</div>
                                <div class='flex align-center fp-detail' *ngIf='cost'>
                                    <icon name='price'></icon>
                                    <div>{{cost}}</div>
                                </div>
                                <div class='flex align-center fp-detail' *ngIf='sqft'>
                                    <icon name='sq_ft'></icon>
                                    <div>{{sqft}}</div>
                                </div>
                                <div class='flex align-centerv fp-detail' *ngIf='bedrooms'>
                                    <icon name='bed'></icon>
                                    <div>{{bedrooms}}</div>
                                </div>
                                <div class='flex align-centerv fp-detail' *ngIf='bathrooms'>
                                    <icon name='full_bath'></icon>
                                    <div>{{bathrooms}}</div>
                                </div>
                                <div class='flex align-center fp-detail' *ngIf='garageCount'>
                                    <icon name='garage'></icon>
                                    <div>{{garageCount}}</div>
                                </div>
                                <div class='flex align-center fp-detail' *ngIf='floorCount'>
                                    <icon name='floor'></icon>
                                    <div>{{floorCount}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='elevation-content' *ngIf='mockFloorplan.elevations && mockFloorplan.elevations.length > 0'>
                        <div class='section-label'>Exteriors</div>
                        <div class="elevations flex align-center justify-center no-wrap">
                            <div *ngFor="let elevation of mockFloorplan.elevations; let i = index">
                                <div class="image-container flex align-center justify-center" *ngIf="i === mockFloorplan.mediaIndex">
                                    <img [src]="elevation.Uri" />
                                </div>
                            </div>
                        </div>
                        <icon name='carrot_arrow' class='carrot_arrow left' *ngIf='mockFloorplan.mediaIndex > 0' (click)='previousElevation(mockFloorplan)'></icon>
                        <icon name='carrot_arrow' class='carrot_arrow' *ngIf='mockFloorplan.mediaIndex < mockFloorplan.elevations.length - 1' (click)='nextElevation(mockFloorplan)'></icon>
                    </div>
                </div>
            </div>
        </div>
        <icon name="home" class="home flex align-center" (click)="showHomeMenu()">
        </icon>
    </div>
</div>