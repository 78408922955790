<div class="slide-out-container" [class.show]="slideOut.Shown" [class.video]='isVideo' [class.noheader]="!slideOut?.ShowHeader">
  <div class="slide-out-background" [class.videobackground]='isVideo'></div>
  <div class="header flex align-center justify-between">
    <div class="flex align-center" *ngIf="slideOut?.ShowHeader">
      <div *ngIf="slideOut?.Type">{{slideOut?.Type}}: </div>
      <div class="title">{{slideOut?.Title}} {{slideOut?.Media && slideOut?.Media.length > 0 ? " - " + slideOut?.Media[mediaIndex].Title : ""}}</div>
    </div>
    <div class="flex align-center push-right tools">
      <icon name="fullscreen_enter" (click)="toggleWindowMode()" *ngIf="!isFullWindow && slideOut?.ShowHeader"></icon>
      <icon name="fullscreen_leave" (click)="toggleWindowMode()" *ngIf="isFullWindow && slideOut?.ShowHeader"></icon>
    </div>
  </div>
  <div class="slide-out-content flex align-center justify-center" *ngIf="iframeSrc && !isVideo">
    <div *ngIf="showFavorite" class="favorite-and-title">
      <icon name="heart_filled" [class.favorited]="favorited" (click)="toggleFavorite()"></icon>
      <h2>{{slideOut?.Title}}</h2>
    </div>
    <iframe [src]="iframeSrc" seamless="seamless" scrolling="no" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
  </div>
  <div class="slide-out-content flex align-center justify-center" *ngIf="iframeSrc && isVideo">
    <video #videoref [src]='iframeSrc' preload="auto" poster='../../assets/grayscreen.png'></video>
    <div class='controls flex align-center'>
      <button mat-fab color="white" (click)="playVideo()">
        <mat-icon>play_arrow</mat-icon>
      </button>
      <button mat-fab color="white" (click)="pauseVideo()">
        <mat-icon>pause</mat-icon>
      </button>
      <mat-slider min="1" max="100" step="1" #ngSlider><input matSliderThumb [value]="videoScrub" (change)="playVideo()" #ngSliderThumb="matSliderThumb" (input)="startScrubbing({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" /></mat-slider>
    </div>
  </div>
  <div class="slide-out-content media flex align-center justify-center no-wrap" [class.noheader]="!slideOut?.ShowHeader">
    <div *ngFor="let media of slideOut?.Media; let i = index" class="image-container flex align-center">
      <img [src]="media.Filename" *ngIf="i === mediaIndex" />
    </div>
  </div>
  <div class="flex align-center justify-center media-controls" *ngIf="!iframeSrc && slideOut?.Media?.length > 0">
    <icon name="carrot_arrow" class="flip flex align-center" (click)="previousMedia()"></icon>
    <div>{{mediaIndex + 1}} </div>
    <div class="divider"> / </div>
    <div>{{slideOut?.Media.length}}</div>
    <icon name="carrot_arrow" class="flex align-center" (click)="nextMedia()"></icon>
  </div>
  <div class="quick-nav">
    <icon *ngIf="slideOut?.ShowClose" name="back_arrow" (click)="close()"></icon>
    <icon name="home" class="home flex align-center" *ngIf="slideOut?.ShowHomeMenuIcon" (click)="showHomeMenu()"></icon>
    <div *ngIf="showFavorite" class="favorite-count">
      <icon class="heart" name="heart_outline"></icon>
      <div class="count" [class.show]="favorited">1</div>
    </div>
  </div>
</div>