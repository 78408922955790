import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { mergeMap } from 'rxjs/operators';

import { KRPanoService } from '../../../../entities/krpano.service';
import { PanoService } from '../../../../entities/pano.service';
import { View } from '../../../../entities/view';
import { SettingsService } from '../../../../services/settings.service';
import { GoogleTagManagerService } from '../../../../utility/helpers/google-tag-manager';
import { UtilityService } from '../../../../utility/helpers/utility.service';
import { ShareDialogComponent } from '../../../share-dialog/share-dialog.component';

import { SettingsCategoryTitle } from '@ml/common';

@Component({
  selector: 'hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HamburgerMenuComponent implements OnInit {
  @Input() view: View;
  @Input() isOpen = false;
  @Output() toggleOpen = new EventEmitter<boolean>();
  showTitle = true;

  hasGyroscope = false;
  hasWebVREnabled = false;
  downloadImageEnabled = false;

  constructor(
    private panoService: PanoService,
    private matDialog: MatDialog,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private krpanoService: KRPanoService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    let parameters: ParamMap;

    const sub = this.route.queryParamMap
      .pipe(
        mergeMap(params => {
          parameters = params;
          return this.utilityService.hasGyroscope;
        })
      )
      .subscribe(gyroEnabled => {
        if (gyroEnabled && !this.accelerationDisabledByQueryParam(parameters)) {
          this.panoService.setGyroscope(true);
          this.hasGyroscope = gyroEnabled;
          sub.unsubscribe();
        }
      });

    this.showTitle = this.settingsService.get('ShowTitle', SettingsCategoryTitle.PanoViewerTheme);

    this.hasWebVREnabled =
      this.panoService.pano.Settings.WebVRSettings.Enabled && this.krpanoService.isWebVRAvailable();

    this.downloadImageEnabled = this.panoService.pano.Settings.EnableDownloadImage;

    this.krpanoService.webVR$.subscribe(() => {
      this.hasWebVREnabled =
        this.panoService.pano.Settings.WebVRSettings.Enabled &&
        this.krpanoService.isWebVRAvailable();
    });
  }

  private accelerationDisabledByQueryParam(parameters: ParamMap): boolean {
    return parameters?.get('accelerometer')?.toLocaleLowerCase() === 'false';
  }

  toggleHamburgerMenu() {
    this.isOpen = !this.isOpen;

    this.toggleOpen.emit(this.isOpen);
  }

  async handleSavePDF() {
    this.gtmService.addEvent('PdfDownload', 'PdfDownload', 'PdfDownload', this.view.ViewId);
    await this.panoService.savePDF(this.view);
  }

  handleSaveImage() {
    this.gtmService.addEvent('ImageDownload', 'ImageDownload', 'ImageDownload', this.view.ViewId);
    this.panoService.saveImage(this.view);
  }

  handleShare() {
    this.matDialog.open(ShareDialogComponent);
  }

  toggleGyro() {
    const enabled = this.panoService.toggleGyroscope();
    this.gtmService.addEvent(
      `Accelerometer`,
      'Accelerometer',
      enabled ? 'On' : 'Off',
      this.view.ViewId
    );
  }

  toggleWebVR() {
    const enabled = this.krpanoService.toggleWebVR();
    this.gtmService.addEvent(
      `WebVR ${enabled ? 'On' : 'Off'}`,
      'WebVR',
      enabled ? 'On' : 'Off',
      this.view.ViewId
    );
  }
}
