import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalEventService } from '../../../entities/global-event.service';
import { Hotspot, HotspotRadarConeSettings } from '../../../entities/hotspot';
import { KRPanoService } from '../../../entities/krpano.service';
import { PanoService } from '../../../entities/pano.service';
import { GoogleTagManagerService } from '../../../utility/helpers/google-tag-manager';

import { roundToPlace } from '@ml/common';

@Component({
  selector: 'minimap-hotspot',
  templateUrl: './minimap-hotspot.component.html',
  styleUrls: ['./minimap-hotspot.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinimapHotspotComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() hotspot: Hotspot;
  @Input() currentViewId: number;
  @Output() spotSelect = new EventEmitter<Hotspot>();
  @ViewChild('hotspotElement') hotspotElement: ElementRef;
  eventSub: Subscription;
  radarIsEnabled = false;
  radarStyleTransform: string;
  isSelected = false;
  pulseMiniMapYouAreHereHotspot = false;

  constructor(
    private globalEvents: GlobalEventService,
    private krpanoService: KRPanoService,
    private cdr: ChangeDetectorRef,
    private panoService: PanoService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    const rdcs = this.hotspot.RadarConeSettings;
    this.updateRadarTransform(rdcs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentViewId && this.currentViewId === this.hotspot.GoToViewId) {
      this.eventSub = this.globalEvents.listen$('onviewchange').subscribe(this.handleViewChange);
    } else if (this.eventSub) {
      this.eventSub.unsubscribe();
    }

    if (changes.currentViewId.previousValue !== changes.currentViewId.currentValue) {
      this.updateSelectedHotspotAnimation();
    }
  }

  private updateSelectedHotspotAnimation() {
    if (!this.hotspotElement) return;

    if (this.currentViewId === this.hotspot.GoToViewId && !this.isSelected) {
      this.panoService.setHotspotAnimation(this.hotspot, this.hotspotElement.nativeElement);
      this.isSelected = true;
    } else if (this.isSelected) {
      this.panoService.removeHotspotAnimation(this.hotspot, this.hotspotElement.nativeElement);
      this.isSelected = false;
      this.updateRadarTransform(this.hotspot.RadarConeSettings);
    }
  }

  ngAfterViewInit() {
    this.updateSelectedHotspotAnimation();
    this.pulseMiniMapYouAreHereHotspot =
      this.panoService.pano.Settings.HotspotSettings.PulseMiniMapYouAreHereHotspot;
  }

  ngOnDestroy() {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  handleViewChange = () => {
    const viewPos = this.krpanoService.getCurrentViewPosition();
    this.updateRadarTransform(this.hotspot.RadarConeSettings, roundToPlace(viewPos.Horizontal, 2));
    this.cdr.markForCheck();
  };

  handleHotspotClick() {
    this.spotSelect.emit(this.hotspot);

    this.gtmService.addEvent(
      'OnMinimapHotspotClick',
      'Click',
      this.hotspot.HotspotId.toString(),
      this.currentViewId
    );
  }

  private updateRadarTransform(rdcs: HotspotRadarConeSettings, currentAngle?: number) {
    this.radarIsEnabled = rdcs.IsEnabled && this.currentViewId === this.hotspot.GoToViewId;
    const angle =
      typeof currentAngle === 'number' ? rdcs.StartingAngle + currentAngle : rdcs.StartingAngle;
    this.radarStyleTransform = `rotate(${angle}deg) scale(${rdcs.SizeScale})`;
  }
}
