export const distance = (p1: Point, p2: Point) =>
  Math.sqrt(Math.pow(p1.X - p2.X, 2) + Math.pow(p1.Y - p2.Y, 2));

export class Point {
  X: number;
  Y: number;

  constructor(x?: number, y?: number) {
    this.X = x;
    this.Y = y;
  }
}
